import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCNkRp6X7Vh9ypqp7qwaJj3Q8VanlC76Kk",
    authDomain: "shemedia-fb24c.firebaseapp.com",
    projectId: "shemedia-fb24c",
    storageBucket: "shemedia-fb24c.appspot.com",
    messagingSenderId: "141493994104",
    appId: "1:141493994104:web:db6f0fc82edb3199c0e38d",
    measurementId: "G-Z0LBEKPV2J",
}; //this is where your firebase app values you copied will go

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storageRef = firebase.storage().ref();
export default firebase;
