import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import {
  BooleanField,
  CreateButton,
  EditButton,
  FilterDropdown,
  NumberField,
  TextField,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useList,
  useTranslate,
} from "@refinedev/core";
import {
  Avatar,
  Dropdown,
  Form,
  Grid,
  Input,
  List,
  MenuProps,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { ProductStatus } from "components/status";
import { IBrand, ICategory, IProduct, IStore } from "interfaces";
import React from "react";

const { Text } = Typography;

export const StoreList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, filters } = useTable<
    IStore,
    HttpError,
    { name: string }
  >({
    pagination: {
      mode: "off",
    },
    onSearch: ({ name }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "name",
        operator: "contains",
        value: name ? name : undefined,
      });

      return filters;
    },
  });

  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List>
          <Form
            {...searchFormProps}
            onValuesChange={() => {
              searchFormProps.form?.submit();
            }}
            initialValues={{
              name: getDefaultFilter("name", filters, "contains"),
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "8px",
                marginBottom: "16px",
              }}
            >
              <Text style={{ fontSize: "24px" }} strong>
                {t("stores.stores")}
              </Text>
              <Form.Item name="name" noStyle>
                <Input
                  style={{
                    width: "400px",
                  }}
                  placeholder={t("stores.actions.searchFast")}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
              <Space>
                <CreateButton resource="stores">
                  {t("buttons.create")}
                </CreateButton>
              </Space>
            </div>
          </Form>

          <Table
            {...tableProps}
            rowKey="_id"
            expandable={{
              expandedRowRender: !breakpoint.xs ? expandedRowRender : undefined,
            }}
          >
            <Table.Column
              key="name"
              dataIndex="name"
              title={t("stores.fields.name")}
            />
            <Table.Column
              dataIndex="address"
              title={t("stores.fields.address")}
            />
            <Table.Column
              dataIndex="description"
              title={t("stores.fields.description")}
            />
            <Table.Column
              key="isActive"
              dataIndex="isActive"
              title={t("stores.fields.isActive")}
              render={(value) => <BooleanField value={value} />}
            />
            <Table.Column
              key="createdAt"
              dataIndex="createdAt"
              title={t("stores.fields.createdAt")}
              sorter
            />
            <Table.Column<IProduct>
              dataIndex="products_actions"
              title={t("table.actions")}
              render={(_, record) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: (
                      <EditButton
                        size="small"
                        resource="stores"
                        recordItemId={record._id}
                      />
                    ),
                  },
                  // {
                  //   key: "2",
                  //   label: (
                  //     <ShowButton size="small" recordItemId={record._id} />
                  //   ),
                  // },
                ];

                return (
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <MoreOutlined
                      style={{
                        fontSize: 24,
                      }}
                    />
                  </Dropdown>
                );
              }}
            />
          </Table>
        </List>
      </Space>
    </>
  );
};

const StoreProductTable: React.FC<{ storeEntity: IStore }> = ({
  storeEntity,
}) => {
  const t = useTranslate();

  const { tableProps: postTableProps } = useTable<IProduct>({
    resource: "products",
    pagination: {
      mode: "off",
    },
    permanentFilter: [
      {
        field: "stocks.store",
        operator: "eq",
        value: storeEntity._id,
      },
    ],
    syncWithLocation: false,
  });
  const { data: brandData } = useList<IBrand, HttpError>({
    resource: "brands",
  });

  const brandList = brandData?.data ?? [];

  const { data: categoryData } = useList<ICategory, HttpError>({
    resource: "categories",
  });

  const categoryList = categoryData?.data ?? [];

  return (
    <>
      <Table {...postTableProps} rowKey="_id">
        <Table.Column<IProduct>
          align="left"
          title={t("products.fields.name")}
          render={(_, record) => {
            return (
              <Space>
                <Avatar size={90} src={record.cover[0].url} />
                <TextField value={record.name}></TextField>
              </Space>
            );
          }}
        />
        <Table.Column
          align="center"
          dataIndex="brand"
          title={t("products.fields.brand")}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
            >
              <Select
                showSearch
                options={brandList?.map((x) => {
                  return { label: x.name, value: x._id };
                })}
                style={{ minWidth: 250 }}
                mode="multiple"
              />
            </FilterDropdown>
          )}
          render={(value) => {
            if (brandList?.find((x) => x._id === value)) {
              return brandList?.find((x) => x._id === value)?.name || "-";
            } else {
              return "...";
            }
          }}
        />
        <Table.Column
          align="center"
          dataIndex="category"
          title={t("products.fields.category")}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
            >
              <Select
                showSearch
                options={categoryList?.map((x) => {
                  return { label: x.name, value: x._id };
                })}
                style={{ minWidth: 250 }}
                mode="multiple"
              />
            </FilterDropdown>
          )}
          render={(value) => {
            if (categoryList?.find((x) => x._id === value)) {
              return categoryList?.find((x) => x._id === value)?.name || "-";
            } else {
              return "...";
            }
          }}
        />
        <Table.Column
          align="center"
          dataIndex="productStatus"
          title="Trạng Thái"
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
            >
              <Select
                options={[
                  {
                    value: "Normal",
                    label: t("enum.productStatuses.Normal"),
                  },
                  {
                    value: "AlertStock",
                    label: t("enum.productStatuses.AlertStock"),
                  },
                  {
                    value: "OutStock",
                    label: t("enum.productStatuses.OutStock"),
                  },
                ]}
                style={{ minWidth: 250 }}
                mode="multiple"
              />
            </FilterDropdown>
          )}
          render={(value) => {
            return <ProductStatus status={value} />;
          }}
          // sorter
          width={125}
        />
        <Table.Column
          align="center"
          key="priceSell"
          dataIndex="priceSell"
          title={t("products.fields.priceSell")}
          render={(value) => {
            return (
              <NumberField
                options={{
                  currency: "VND",
                  style: "currency",
                  notation: "compact",
                }}
                value={value}
              />
            );
          }}
          sorter
        />
        <Table.Column<IProduct>
          key="stocks"
          align="center"
          dataIndex="stocks"
          title="Thống Kê SL"
          render={(_, record) => (
            <Space direction="vertical">
              <TextField
                value={`SL Trên Đơn: ${record.qtyOnOrder}`}
              ></TextField>
              <TextField
                value={`SL Đang Về: ${record.qtyOnTransport}`}
              ></TextField>
              <TextField
                value={`SL Trong Kho: ${
                  record.stocks.find((x) => x.store === storeEntity._id)?.stock
                }`}
              ></TextField>
            </Space>
          )}
        />
        <Table.Column<IProduct>
          fixed="right"
          dataIndex="products_actions"
          // title={t("table.actions")}
          render={(_, record) => {
            return (
              <EditButton
                hideText
                size="small"
                resource="products"
                recordItemId={record._id}
              />
            );
          }}
        />
      </Table>
    </>
  );
};

const expandedRowRender = (record: IStore) => {
  return <StoreProductTable storeEntity={record} />;
};
