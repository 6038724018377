import { useTranslate } from "@refinedev/core";

import { Tag } from "antd";

type ImportStatusProps = {
    status: "OnTransport" | "OnArrived";
};

export const ImportStatus: React.FC<ImportStatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "OnTransport":
            color = "orange";
            break;
        case "OnArrived":
            color = "green";
            break;
    }

    return <Tag color={color}>{t(`enum.importStatuses.${status}`)}</Tag>;
};

type PaymentStatusProps = {
    status: "Done" | "Debt" | "Refund";
};

export const PaymentStatus: React.FC<PaymentStatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "Done":
            color = "green";
            break;
        case "Debt":
            color = "red";
            break;
        case "Refund":
            color = "blue";
            break;
    }

    return <Tag color={color}>{t(`enum.paymentStatuses.${status}`)}</Tag>;
};
type ProductStatusProps = {
    status: "Normal" | "AlertStock" | "OutStock";
};
export const ProductStatus: React.FC<ProductStatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "Normal":
            color = "green";
            break;
        case "AlertStock":
            color = "orange";
            break;
        case "OutStock":
            color = "red";
            break;
    }

    return <Tag color={color}>{t(`enum.productStatuses.${status}`)}</Tag>;
};

type OrderStatusProps = {
    status: "Pending" | "Ready" | "OnTheWay" | "Delivered" | "Done" | "Cancelled";
};

export const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "Pending":
            color = "orange";
            break;
        case "Ready":
            color = "cyan";
            break;
        case "OnTheWay":
            color = "green";
            break;
        case "Delivered":
            color = "blue";
            break;
        case "Done":
            color = "blue";
            break;
        case "Cancelled":
            color = "red";
            break;
    }

    return <Tag color={color}>{t(`enum.orderStatuses.${status}`)}</Tag>;
};
