import {
  HttpError,
  IResourceComponentsProps,
  useCan,
  useList,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import {
  CreateButton,
  DateField,
  Edit,
  ListButton,
  RefreshButton,
  useDrawerForm,
  useForm,
} from "@refinedev/antd";

import {
  Alert,
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { StoreCreateDrawer } from "components/store";
import {
  IDamagedProductItem,
  IExportDamagedProduct,
  IProduct,
  IStore,
} from "interfaces";
import React, { useState } from "react";
const { Option } = Select;

export const ExportDamagedProductEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const t = useTranslate();
  const [isFailedOnMutation, setIsFailedOnMutation] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const { mutate } = useUpdate();
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();

  const { formProps, saveButtonProps, queryResult } =
    useForm<IExportDamagedProduct>({
      liveMode: "manual",
      onLiveEvent: (event) => {
        if (event.type === "deleted" || event.type === "updated") {
          setDeprecated(event.type);
        }
      },
      onMutationSuccess: (data, variables, context) => {
        const importProduct = data.data;
        importProduct.productsToUpdate.forEach((element) => {
          mutate({
            resource: "products",
            id: element.product,
            values: {
              priceImport: element.priceImport,
              priceSell: element.priceSell,
              productStatus: element.productStatus,
              qtyOnOrder: element.qtyOnOrder,
              qtyOnTransport: element.qtyOnTransport,
              qtyInStocks: element.qtyInStocks,
              stocks: element.stocks,
            },
          });
        });
      },
      onMutationError: (data, variables, context) => {
        console.log({ data, variables, context });
        // "{\"message\":\"SL SP không đủ để giao !\",\"name\":\"Error\"}
        // {"message":"SL SP không đủ để giao !","name":"Error"}
        // {"message":"{\"errors\":[{\"product\":\"Nước Hoa Chanel N5\",\"store\":\"646d18fd60a13a464809f02a\",\"message\":\"Tồn kho âm !\"}]}","name":"Error"}
        const error = data.response?.data?.error;
        console.log(error);
        const errorJson = JSON.parse(error);
        const errorDetail = JSON.parse(errorJson.message);
        setIsFailedOnMutation(true);
        setErrorMessages(errorDetail.errors);
        console.log(errorDetail);
      },
    });
    const handleRefresh = () => {
      queryResult?.refetch();
      setDeprecated(undefined);
    };
  const { data, isLoading: productDataIsLoading } = useList<
    IProduct,
    HttpError
  >({
    resource: "products",
    liveMode: "auto",
    pagination: {
      mode: "off",
    },
  });

  const productList = data?.data ?? [];

  const { data: storeData, isLoading: storeDataIsLoading } = useList<
    IStore,
    HttpError
  >({
    resource: "stores",
    pagination: {
      mode: "off",
    },
  });
  const storeList = storeData?.data ?? [];

  const handleOnFinish = async (values: any) => {
    var actualIdProducts: string[] = values.products.map(
      (x: IDamagedProductItem) => x.product
    );
    var beforeIdProducts: string[] =
      formProps.initialValues?.products.map(
        (x: IDamagedProductItem) => x.product
      ) ?? [];
    var mergedProductsToUpdate: string[] =
      actualIdProducts.concat(beforeIdProducts);
    let productsToUpdate: string[] = [];
    mergedProductsToUpdate.forEach((element: string) => {
      if (productsToUpdate.indexOf(element) === -1) {
        productsToUpdate.push(element);
      }
    });

    var totalCost = 0;
    values.products.forEach((damagedProduct: IDamagedProductItem) => {
      totalCost += damagedProduct.unitCost * damagedProduct.qtyDamaged;
    });

    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        totalCost: totalCost,
        productsToUpdate: productsToUpdate,
      })
    );
  };

  const handleChange = (x: any) => {
    const total = formProps.form
      ?.getFieldValue("products")
      ?.reduce((accumulator: number, currentValue: IDamagedProductItem) => {
        if (
          currentValue !== undefined &&
          currentValue.unitCost !== undefined &&
          currentValue.qtyDamaged !== undefined
        ) {
          return accumulator + currentValue.unitCost * currentValue.qtyDamaged;
        } else {
          return accumulator;
        }
      }, 0);

    formProps.form?.setFieldsValue({
      totalCost: total,
    });
  };

  const {
    drawerProps: createStoreDrawerProps,
    formProps: createStoreFormProps,
    saveButtonProps: createStoreSaveButtonProps,
    show: createStoreShow,
  } = useDrawerForm<IStore>({
    action: "create",
    resource: "stores",
    redirect: false,
  });

  const { data: canAccessPrice } = useCan({
    resource: "exportDamagedProducts",
    action: "field",
    params: { field: "totalCost" },
  });

  return (
    <>
      <Edit
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
      >
         {deprecated === "deleted" && (
          <Alert
            message="Đơn Xuất Hàng Hỏng này đã bị xóa !"
            type="warning"
            style={{ marginBottom: 20 }}
            action={<ListButton size="small" />}
          />
        )}
        {deprecated === "updated" && (
          <Alert
            message="Đơn Xuất Hàng Hỏng này vừa được cập nhập ! Vui lòng tải lại !"
            type="warning"
            style={{ marginBottom: 20 }}
            action={<RefreshButton size="small" onClick={handleRefresh} />}
          />
        )}
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            ...formProps.initialValues,
          }}
          onFinish={handleOnFinish}
          onValuesChange={handleChange}
        >
          <Form.Item
            label={t("exportDamagedProducts.fields.exportDate")}
            name="exportDate"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DateField
              value={formProps.initialValues?.orderDate}
              format="DD/MM/YYYY LT"
            />
          </Form.Item>
          <Form.Item label={t("exportDamagedProducts.fields.store")}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="store"
                  rules={[
                    {
                      required: true,
                      message: "Chưa chọn Kho Hàng",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn Kho Hàng ..."
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {!storeDataIsLoading &&
                      storeList.map((store) => {
                        return (
                          <Option
                            key={store._id}
                            value={store._id}
                            label={store.name}
                          >
                            <Space key={uuidv4()}>
                              <Descriptions size="small">
                                <Descriptions.Item
                                  label={t("stores.fields.name")}
                                  span={3}
                                >
                                  {store?.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("stores.fields.address")}
                                  span={3}
                                >
                                  {store?.address}
                                </Descriptions.Item>
                              </Descriptions>
                            </Space>
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <CreateButton onClick={() => createStoreShow()}>
                  Thêm Kho Hàng Mới
                </CreateButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={t("importProducts.fields.description")}
            name="description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Divider orientation="left">Danh Sách Sản Phẩm Hỏng</Divider>
          <Form.List name="products">
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map((field) => {
                    var selectedProduct: IProduct | undefined;
                    var list = formProps.form?.getFieldValue("products");
                    if (list.length > 0 && list[field.key] !== undefined) {
                      selectedProduct = productList.find(
                        (x) => x._id === list[field.key].product
                      );
                      // list[field.key].unitCost = selectedProduct?.priceImport;
                      // formProps.form?.setFieldValue("products", list);
                    }
                    // console.log(formProps.form?.getFieldsValue());
                    return (
                      <Space key={field.key} align="baseline">
                        <Form.Item
                          style={{
                            width: "100%",

                            marginBottom: -10,
                          }}
                          shouldUpdate
                        >
                          {() => (
                            <Form.Item
                              {...field}
                              label={t(
                                "importProducts.fields.products.product"
                              )}
                              name={[field.name, "product"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Chưa chọn sản phẩm !",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      getFieldValue("products").filter(
                                        (x: { product: any }) =>
                                          x.product === value
                                      ).length > 1
                                    ) {
                                      return Promise.reject(
                                        new Error("Đã tồn tại sản phẩm !")
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Select
                                showSearch
                                style={{
                                  width: "400px",
                                }}
                                allowClear
                                placeholder="Chọn Sản Phẩm Bị Hỏng ..."
                                optionLabelProp="label"
                                filterOption={(input, option) =>
                                  (option?.label?.toString() ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {!productDataIsLoading &&
                                  productList.map((product) => {
                                    return (
                                      <Option
                                        key={product._id}
                                        value={product._id}
                                        label={
                                          canAccessPrice?.can
                                            ? `${
                                                product.name
                                              } (Giá Gốc: ${new Intl.NumberFormat().format(
                                                product?.priceImport
                                              )}đ)`
                                            : product.name
                                        }
                                      >
                                        <Space key={uuidv4()}>
                                          <Avatar
                                            size={100}
                                            src={product?.cover?.[0].url}
                                          ></Avatar>
                                          <Descriptions size="small">
                                            <Descriptions.Item
                                              label={t("products.fields.name")}
                                              span={3}
                                            >
                                              {product?.name}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                              label={t(
                                                "products.fields.priceSell"
                                              )}
                                              span={3}
                                            >
                                              {product?.priceSell}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                              label={t(
                                                "products.fields.qtyOnOrder"
                                              )}
                                              span={3}
                                            >
                                              {product?.qtyOnOrder}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                              label={t(
                                                "products.fields.qtyOnTransport"
                                              )}
                                              span={3}
                                            >
                                              {product?.qtyOnTransport}
                                            </Descriptions.Item>
                                          </Descriptions>
                                        </Space>
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item>
                        <Form.Item
                          key={uuidv4()}
                          style={{ width: "100px" }}
                          label={t(
                            "exportDamagedProducts.fields.products.qtyDamaged"
                          )}
                          name={[field.name, "qtyDamaged"]}
                          rules={[
                            {
                              required: true,
                            },
                            () => ({
                              validator(_, value) {
                                if (value < 0) {
                                  return Promise.reject(
                                    new Error("Phải lớn hơn 0 !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                          initialValue={0}
                        >
                          <InputNumber
                            style={{
                              width: "100px",
                            }}
                            min={0}
                          />
                        </Form.Item>

                        <Form.Item
                          key={uuidv4()}
                          style={{ width: "150px" }}
                          label={
                            canAccessPrice?.can &&
                            t("exportDamagedProducts.fields.products.unitCost")
                          }
                          name={[field.name, "unitCost"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing price",
                            },
                            () => ({
                              validator(_, value) {
                                if (value < 0) {
                                  return Promise.reject(
                                    new Error("Phải lớn hơn 0 !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                          initialValue={0}
                        >
                          {canAccessPrice?.can && (
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              style={{
                                width: "150px",
                              }}
                              min={0}
                            />
                          )}
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    );
                  })}
                  <Form.Item style={{ width: "300px" }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Thêm Sản Phẩm
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          {/* {canAccessPrice?.can && (
            <Divider orientation="left">
              {t("exportDamagedProducts.fields.totalCost")}
            </Divider>
          )}

          <Form.Item
            label={
              canAccessPrice?.can && t("exportDamagedProducts.fields.totalCost")
            }
            name="totalCost"
            initialValue={0}
          >
            {canAccessPrice?.can && (
              <InputNumber
                readOnly
                style={{ width: "300px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                addonAfter="VNĐ"
              />
            )}
          </Form.Item> */}
        </Form>
        <StoreCreateDrawer
          drawerProps={createStoreDrawerProps}
          formProps={createStoreFormProps}
          saveButtonProps={createStoreSaveButtonProps}
        />
        {isFailedOnMutation === true &&
          errorMessages.map((x: any) => {
            var message = `${x.message} (${
              storeList.find((store) => store._id === x.store)?.name ?? x.store
            })!`;
            return (
              <Alert
                key={uuidv4()}
                message={message}
                type="warning"
                style={{ marginBottom: 20 }}
              />
            );
          })}
      </Edit>
    </>
  );
};
