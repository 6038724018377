import { SearchOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DateField,
  EditButton,
  FilterDropdown,
  NumberField,
  TextField,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useCan,
  useList,
  useSelect,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Image,
  Input,
  List,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { ProductStatus } from "components/status";
import dayjs from "dayjs";
import {
  IBrand,
  ICategory,
  IProduct,
  IProductFilterVariables,
  IProductStore,
  IStore,
} from "interfaces";
import React, { useMemo } from "react";

const { Text } = Typography;

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IProduct,
    HttpError,
    IProductFilterVariables
  >({
    pagination: {
      mode: "off",
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { brand, category, createdAt, name } = params;
      filters.push({
        field: "brand",
        operator: "in",
        value: brand,
      });

      filters.push({
        field: "category",
        operator: "in",
        value: category,
      });

      filters.push({
        field: "name",
        operator: "contains",
        value: name,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );
      return filters;
    },
  });

  const t = useTranslate();

  // const { data: brandData, isLoading: brandIsLoading } = useMany<
  //   IBrand,
  //   HttpError
  // >({
  //   resource: "brands",
  //   // Creates the array of ids. This will filter and fetch the category data for the relevant posts.
  //   ids: products.map((item) => item?.brand),
  //   queryOptions: {
  //     // Set to true only if the posts array is not empty.
  //     enabled: !!products.length,
  //   },
  // });

  const { data: storeData } = useList<IStore, HttpError>({
    resource: "stores",
    pagination: {
      mode: "off",
    },
  });

  const storeList = storeData?.data ?? [];

  const { data: brandData } = useList<IBrand, HttpError>({
    resource: "brands",
    pagination: {
      mode: "off",
    },
  });

  const brandList = brandData?.data ?? [];

  const { data: categoryData } = useList<ICategory, HttpError>({
    resource: "categories",
    pagination: {
      mode: "off",
    },
  });

  const categoryList = categoryData?.data ?? [];

  const { data: canAccess } = useCan({
    resource: "products",
    action: "field",
    params: { field: "priceImport" },
  });

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* <Card title={t("products.filter.title")} style={{ width: "100%" }}>
          <Filter formProps={searchFormProps} filters={filters || []} />
        </Card>
        <Divider></Divider> */}
        <List>
          <FilterSearch formProps={searchFormProps} filters={filters || []} />

          <Table {...tableProps} rowKey="_id">
            <Table.Column<IProduct>
              align="left"
              title={t("products.fields.name")}
              render={(_, record) => {
                return (
                  <Space>
                    <Image width={70} height={90} src={record.cover[0].url} />
                    <TextField value={record.name}></TextField>
                  </Space>
                );
              }}
            />
            <Table.Column
              align="center"
              dataIndex="brand"
              title={t("products.fields.brand")}
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                >
                  <Select
                    showSearch
                    options={brandList?.map((x) => {
                      return { label: x.name, value: x._id };
                    })}
                    style={{ minWidth: 250 }}
                    mode="multiple"
                  />
                </FilterDropdown>
              )}
              render={(value) => {
                if (brandList?.find((x) => x._id === value)) {
                  return brandList?.find((x) => x._id === value)?.name || "-";
                } else {
                  return "...";
                }
              }}
            />
            <Table.Column
              align="center"
              dataIndex="category"
              title={t("products.fields.category")}
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                >
                  <Select
                    showSearch
                    options={categoryList?.map((x) => {
                      return { label: x.name, value: x._id };
                    })}
                    style={{ minWidth: 250 }}
                    mode="multiple"
                  />
                </FilterDropdown>
              )}
              render={(value) => {
                if (categoryList?.find((x) => x._id === value)) {
                  return (
                    categoryList?.find((x) => x._id === value)?.name || "-"
                  );
                } else {
                  return "...";
                }
              }}
            />
            <Table.Column
              align="center"
              dataIndex="productStatus"
              title="Trạng Thái"
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                >
                  <Select
                    options={[
                      {
                        value: "Normal",
                        label: t("enum.productStatuses.Normal"),
                      },
                      {
                        value: "AlertStock",
                        label: t("enum.productStatuses.AlertStock"),
                      },
                      {
                        value: "OutStock",
                        label: t("enum.productStatuses.OutStock"),
                      },
                    ]}
                    style={{ minWidth: 250 }}
                    mode="multiple"
                  />
                </FilterDropdown>
              )}
              render={(value) => {
                return <ProductStatus status={value} />;
              }}
              // sorter
              width={125}
            />
            {canAccess?.can && (
              <Table.Column
                align="center"
                key="priceImport"
                dataIndex="priceImport"
                title={t("products.fields.priceImport")}
                render={(value) => {
                  return (
                    <NumberField
                      options={{
                        currency: "VND",
                        style: "currency",
                        notation: "compact",
                      }}
                      value={value}
                    />
                  );
                }}
                sorter
              />
            )}
            <Table.Column
              align="center"
              key="priceSell"
              dataIndex="priceSell"
              title={t("products.fields.priceSell")}
              render={(value) => {
                return (
                  <NumberField
                    options={{
                      currency: "VND",
                      style: "currency",
                      notation: "compact",
                    }}
                    value={value}
                  />
                );
              }}
              sorter
            />{" "}
            {/* <Table.Column
              align="center"
              key="qtyOnOrder"
              dataIndex="qtyOnOrder"
              title={t("products.fields.qtyOnOrder")}
              render={(value) => {
                return <NumberField value={value} />;
              }}
              sorter
            />
            <Table.Column
              align="center"
              key="qtyOnTransport"
              dataIndex="qtyOnTransport"
              title={t("products.fields.qtyOnTransport")}
              render={(value) => {
                return <NumberField value={value} />;
              }}
              sorter
            /> */}
            <Table.Column<IProduct>
              key="stocks"
              align="center"
              dataIndex="stocks"
              title="Thống Kê SL"
              render={(_, record) => (
                <Popover
                  content={
                    <>
                      <TextField strong value="Tồn Kho" />
                      <Table
                        dataSource={record.stocks ?? []}
                        style={{ width: "300px" }}
                      >
                        <Table.Column
                          // key="orderDate"
                          dataIndex="store"
                          title="Kho Hàng"
                          render={(value) => (
                            <TextField
                              value={
                                storeList.find((x) => x._id === value)?.name
                              }
                            />
                          )}
                        />

                        <Table.Column
                          align="center"
                          dataIndex="stock"
                          title="Số Lượng"
                          render={(value) => {
                            return <NumberField value={value} />;
                          }}
                        />
                      </Table>
                      {/* <Space direction="vertical">
                        {record.stocks.map((stockStore: IProductStore) => {
                          var detailStock = storeList.find(
                            (x) => x._id === stockStore.store
                          );

                          if (detailStock !== undefined) {
                            return (
                              <Space key={stockStore.store}>
                                <TextField strong value={detailStock?.name} />
                                :
                                <TextField strong value={stockStore?.stock} />
                              </Space>
                            );
                          }
                          return <></>;
                        })}
                      </Space> */}
                    </>
                  }
                  // title="SL Trong Kho"
                  trigger="hover"
                >
                  <Space direction="vertical">
                    <TextField
                      value={`SL Trên Đơn: ${record.qtyOnOrder}`}
                    ></TextField>
                    <TextField
                      value={`SL Đang Về: ${record.qtyOnTransport}`}
                    ></TextField>
                    <TextField
                      value={`SL Trong Kho: ${record.stocks.reduce(
                        (accumulator: number, currentValue: IProductStore) => {
                          return accumulator + currentValue.stock;
                        },
                        0
                      )}`}
                    ></TextField>
                  </Space>
                </Popover>
              )}
            />
            {/* <Table.Column
              key="isActive"
              align="center"
              dataIndex="isActive"
              title="Hoạt Động ?"
              render={(value) => <BooleanField value={value} />}
            /> */}
            <Table.Column
              key="createdAt"
              align="center"
              dataIndex="createdAt"
              title={t("products.fields.createdAt")}
              render={(value) => (
                <DateField value={value} format="DD/MM/YYYY LT" />
              )}
              sorter
            />
            <Table.Column<IProduct>
              fixed="right"
              dataIndex="products_actions"
              // title={t("table.actions")}
              render={(_, record) => {
                return (
                  <EditButton
                    hideText
                    size="small"
                    resource="products"
                    recordItemId={record._id}
                  />
                );
              }}
            />
          </Table>
        </List>
      </Space>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();

  const { formProps, filters } = props;

  const { options: brandSelectPropos } = useSelect<IBrand>({
    resource: "brands",
    optionLabel: "name",
    optionValue: "_id",
  });
  const { options: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "_id",
  });

  const { RangePicker } = DatePicker;

  const createdAt = useMemo(() => {
    const start = getDefaultFilter("createdAt", filters, "gte");
    const end = getDefaultFilter("createdAt", filters, "lte");

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        brand: getDefaultFilter("brand", filters, "in"),
        category: getDefaultFilter("category", filters, "in"),
        createdAt,
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label="Nhãn hàng" name="brand">
            <Select
              options={brandSelectPropos}
              defaultValue={getDefaultFilter("brand", filters, "in")}
              allowClear
              mode="multiple"
              placeholder={t("brands.brands")}
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label="Loại hàng" name="category">
            <Select
              options={categorySelectProps}
              defaultValue={getDefaultFilter("category", filters, "in")}
              allowClear
              placeholder={t("categories.categories")}
            />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item label={t("products.fields.createdAt")} name="createdAt">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xl={24} md={8} sm={12} xs={24}>
          <Form.Item>
            <Button htmlType="submit" type="primary" size="large" block>
              {t("products.filter.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const FilterSearch: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();

  const { formProps, filters } = props;

  return (
    <Form
      {...formProps}
      onValuesChange={() => {
        formProps.form?.submit();
      }}
      initialValues={{
        name: getDefaultFilter("name", filters, "contains"),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "8px",
          marginBottom: "16px",
        }}
      >
        <Text style={{ fontSize: "24px" }} strong>
          {t("products.products")}
        </Text>
        <Form.Item name="name" noStyle>
          <Input
            style={{
              width: "400px",
            }}
            placeholder="Tìm kiếm nhanh theo tên sản phẩm ..."
            suffix={<SearchOutlined />}
          />
        </Form.Item>
        <Space>
          <CreateButton resource="products">{t("buttons.create")}</CreateButton>
        </Space>
      </div>
    </Form>
  );
};
