import { useTranslate } from "@refinedev/core";

import { EditButton, ShowButton } from "@refinedev/antd";

import { MoreOutlined } from "@ant-design/icons";
import { Card, Divider, Dropdown, Image, MenuProps, Typography } from "antd";
import { ICategory } from "interfaces";

const { Text, Paragraph } = Typography;

type CategoryItemProps = {
    item: ICategory;
};

export const CategoryItem: React.FC<CategoryItemProps> = ({ item }) => {
    const t = useTranslate();

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: <EditButton size="small" recordItemId={item._id} />,
        },
        {
            key: "2",
            label: <ShowButton size="small" recordItemId={item._id} />,
        },
    ];
    return (
        <Card
            style={{
                margin: "8px",
                opacity: item.isActive ? 1 : 0.5,
            }}
            bodyStyle={{ height: "350px" }}
        >
            <div style={{ position: "absolute", top: "10px", right: "5px" }}>
                <Dropdown menu={{ items }} trigger={["click"]}>
                    <MoreOutlined
                        style={{
                            fontSize: 24,
                        }}
                    />
                </Dropdown>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <Image
                        width={128}
                        height={128}
                        src={item.cover[0].url}
                        alt={item.name}
                    />
                </div>
                <Divider />
                <Paragraph
                    ellipsis={{ rows: 2, tooltip: true }}
                    style={{
                        fontSize: "20px",
                        fontWeight: 800,
                        marginBottom: "5px",
                    }}
                >
                    {item.name}
                </Paragraph>

                <Paragraph
                    ellipsis={{ rows: 3, tooltip: true }}
                    style={{ marginBottom: "8px" }}
                >
                    {item.isActive ? (
                        <Text>Đang hoạt động</Text>
                    ) : (
                        <Text>Ngừng hoạt động</Text>
                    )}
                </Paragraph>
                <Paragraph
                    ellipsis={{ rows: 3, tooltip: true }}
                    style={{ marginBottom: "8px" }}
                >
                    {t("brands.fields.createdAt")} {item.createdAt}
                </Paragraph>
            </div>
        </Card>
    );
};
