// import { IResourceComponentsProps } from "@refinedev/core";
// import { AntdEditInferencer } from "@refinedev/inferencer/antd";

// export const BrandEdit: React.FC<IResourceComponentsProps> = () => {
//     return <AntdEditInferencer />;
// };

import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import {
  Edit,
  ListButton,
  RefreshButton,
  getValueFromEvent,
  useForm,
} from "@refinedev/antd";

import {
  Alert,
  Button,
  Form,
  Input,
  Popconfirm,
  Radio,
  Space,
  Typography,
  Upload,
  UploadFile,
} from "antd";

import { storageRef } from "../../utility/firebaseClient";

import { DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { ICategory } from "interfaces";
import { useState } from "react";
const { Text } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const CategoryEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<ICategory>({
    liveMode: "manual",
    onLiveEvent: (event) => {
      if (event.type === "deleted" || event.type === "updated") {
        setDeprecated(event.type);
      }
    },
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [deprecated, setDeprecated] = useState<
    "deleted" | "updated" | undefined
  >();
  const [isByClipboard, setIsByClipboard] = useState(true);

  const images: {
    name: string;
    status: string;
    type: string | undefined;
    uid: string;
    url: string | undefined;
    thumbUrl: string | undefined;
  }[] = [];

  const handleRefresh = () => {
    queryResult?.refetch();
    setDeprecated(undefined);
  };
  const handleClickPaste = async () => {
    try {
      const read = await navigator.clipboard.read();
      const foundImageMimeType = read[0].types.find((val) =>
        val.startsWith("image/png")
      );
      if (foundImageMimeType) {
        const blob = await read[0].getType(foundImageMimeType);
        var fileName = formProps.form?.getFieldValue("name") + ".png";
        var file = new File([blob], fileName, {
          type: foundImageMimeType,
        });
        var preview = await getBase64(file as RcFile);

        formProps.form?.setFieldsValue({
          cover: [
            {
              name: file.name,
              status: "done",
              type: file.type,
              originFileObj: file,
              url: preview,
              thumbUrl: preview,
            },
          ],
        });
        console.log(formProps.form?.getFieldsValue());
      }
    } catch (e) {
      console.log(e);
    }
    await navigator.clipboard.writeText("");
    setIsByClipboard(true);
  };

  const confirm = (e?: React.MouseEvent<HTMLElement>) => {
    setIsByClipboard(true);
  };

  const cancel = (e?: React.MouseEvent<HTMLElement>) => {
    setIsByClipboard(false);
  };

  const handleOnFinish = async (values: any) => {
    const { cover, name } = values;
    await Promise.all(
      cover.map(async (file: UploadFile) => {
        const fileName = `media/category/${Date.now()}-${name}-${file.name}`;
        const fileRef = storageRef.child(fileName);
        try {
          if (file.originFileObj) {
            const designFile = await fileRef.put(file.originFileObj);
            const downloadUrl = await designFile.ref.getDownloadURL();

            images.push({
              name: file.name,
              status: "done",
              type: file.type,
              uid: file.uid,
              url: downloadUrl,
              thumbUrl: downloadUrl,
            });
          } else {
            if (file.status === "done") {
              images.push({
                name: file.name,
                status: "done",
                type: file.type,
                uid: file.uid,
                url: file.url,
                thumbUrl: file.thumbUrl,
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
      })
    );
    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...formProps.initialValues,
        ...values,
        cover: images,
      })
    );
  };

  return (
    <Edit isLoading={queryResult?.isFetching} saveButtonProps={saveButtonProps}>
      {deprecated === "deleted" && (
        <Alert
          message="Loai hàng này vừa bị xóa !"
          type="warning"
          style={{ marginBottom: 20 }}
          action={<ListButton size="small" />}
        />
      )}
      {deprecated === "updated" && (
        <Alert
          message="Loai hàng này vừa được cập nhập ! Vui lòng tải lại !"
          type="warning"
          style={{ marginBottom: 20 }}
          action={<RefreshButton size="small" onClick={handleRefresh} />}
        />
      )}
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
        }}
        onFinish={handleOnFinish}
      >
        <Form.Item
          label={t("categories.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Space direction="vertical">
          <Space>
            <Text>Logo</Text>
            <Popconfirm
              title="Phương thức chèn ảnh"
              description="Chèn bằng copy/paste ?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Có"
              cancelText="Không"
            >
              <Button
                shape="circle"
                size="small"
                icon={<QuestionCircleOutlined />}
              ></Button>
            </Popconfirm>
          </Space>

          {isByClipboard ? (
            <Space>
              <Form.Item
                name="cover"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Chưa chọn logo !",
                  },
                ]}
              >
                <Upload
                  name="file"
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => false}
                ></Upload>
              </Form.Item>
              <Button
                type="dashed"
                style={{
                  width: "200px",
                  height: "100px",
                  marginBottom: "10px",
                }}
                onClick={handleClickPaste}
                size="large"
              >
                <Space direction="vertical">
                  <DownloadOutlined />
                  <p className="ant-upload-text">Copy/Past ảnh mới</p>
                </Space>
              </Button>
            </Space>
          ) : (
            <Form.Item
              name="cover"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Chưa chọn logo !",
                },
              ]}
            >
              <Upload
                name="file"
                listType="picture-card"
                maxCount={1}
                fileList={fileList}
                beforeUpload={() => false}
              >
                <Space direction="vertical">
                  <DownloadOutlined />
                  <p className="ant-upload-text">Chọn ảnh mới</p>
                </Space>
              </Upload>
            </Form.Item>
          )}
        </Space>

        <Form.Item label={t("categories.fields.isActive")} name="isActive">
          <Radio.Group>
            <Radio value={true}>{t("status.enable")}</Radio>
            <Radio value={false}>{t("status.disable")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={t("categories.fields.createdAt")} name="createdAt">
          <Input disabled />
        </Form.Item>
      </Form>
    </Edit>
  );
};
