import { SearchOutlined } from "@ant-design/icons";
import { CreateButton, useSimpleList } from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useTranslate,
} from "@refinedev/core";
import { List as AntdList, Form, Input, Space, Typography } from "antd";
import { BrandItem } from "components/brand";
import { IBrand } from "interfaces";
import React from "react";
const { Text } = Typography;

export const BrandList: React.FC<IResourceComponentsProps> = () => {
  const { listProps, searchFormProps, filters } = useSimpleList<
    IBrand,
    HttpError,
    { name: string }
  >({
    pagination: {
      mode: "off",
    },
    onSearch: ({ name }) => {
      const brandFilters: CrudFilters = [];

      brandFilters.push({
        field: "name",
        operator: "contains",
        value: name ? name : undefined,
      });

      return brandFilters;
    },
  });
  const t = useTranslate();

  //   const {
  //     drawerProps: createDrawerProps,
  //     formProps: createFormProps,
  //     saveButtonProps: createSaveButtonProps,
  //     show: createShow,
  //   } = useDrawerForm<IBrand>({
  //     action: "create",
  //     resource: "brands",
  //     redirect: false,
  //   });

  //   const {
  //     drawerProps: editDrawerProps,
  //     formProps: editFormProps,
  //     saveButtonProps: editSaveButtonProps,
  //     show: editShow,
  //     id: editId,
  //   } = useDrawerForm<IBrand>({
  //     action: "edit",
  //     resource: "brands",
  //     redirect: false,
  //   });

  return (
    <div>
      <Form
        {...searchFormProps}
        onValuesChange={() => {
          searchFormProps.form?.submit();
        }}
        initialValues={{
          name: getDefaultFilter("name", filters, "contains"),
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "8px",
            marginBottom: "16px",
          }}
        >
          <Text style={{ fontSize: "24px" }} strong>
            {t("brands.brands")}
          </Text>
          <Form.Item name="name" noStyle>
            <Input
              style={{
                width: "400px",
              }}
              placeholder={t("brands.actions.search")}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
          <Space>
            <CreateButton resource="brands">{t("buttons.create")}</CreateButton>
          </Space>
        </div>
        <AntdList
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          style={{
            height: "100%",
            overflow: "auto",
            paddingRight: "4px",
          }}
          {...listProps}
          renderItem={(item: IBrand) => <BrandItem item={item} />}
        />
      </Form>
      {/* <CreateBrand
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      /> */}
    </div>
  );
};

// import React from "react";
// import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
// import {
//     useTable,
//     List,
//     EditButton,
//     ShowButton,
//     BooleanField,
//     MarkdownField,
//     DateField,
// } from "@refinedev/antd";
// import { Table, Space } from "antd";

// export const BrandList: React.FC<IResourceComponentsProps> = () => {
//     const { tableProps } = useTable({
//         syncWithLocation: true,
//     });

//     return (
//         <List>
//             <Table {...tableProps} rowKey="id">
//                 <Table.Column dataIndex="name" title="Name" />
//                 <Table.Column
//                     dataIndex={["isActive"]}
//                     title="Is Active"
//                     render={(value: any) => <BooleanField value={value} />}
//                 />
//                 <Table.Column
//                     dataIndex="cover"
//                     title="Cover"
//                     render={(value: any[]) => (
//                         <>
//                             {value?.map((item, index) => (
//                                 <MarkdownField
//                                     value={item?.url?.slice(0, 80) + "..."}
//                                     key={index}
//                                 />
//                             ))}
//                         </>
//                     )}
//                 />
//                 <Table.Column
//                     dataIndex={["createdAt"]}
//                     title="Created At"
//                     render={(value: any) => <DateField value={value} />}
//                 />
//                 <Table.Column
//                     title="Actions"
//                     dataIndex="actions"
//                     render={(_, record: BaseRecord) => (
//                         <Space>
//                             <EditButton
//                                 hideText
//                                 size="small"
//                                 recordItemId={record._id}
//                             />
//                             <ShowButton
//                                 hideText
//                                 size="small"
//                                 recordItemId={record._id}
//                             />
//                         </Space>
//                     )}
//                 />
//             </Table>
//         </List>
//     );
// };
