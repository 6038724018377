import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  AppstoreOutlined,
  DashboardOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  ErrorComponent,
  ThemedLayoutV2,
  notificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";

import { adapter, model } from "accessControl";
import { authProvider } from "authProvider";
import { newEnforcer } from "casbin";
import { Title } from "components/title";
import { liveProvider } from "liveProvider";
import { AuditLogList } from "pages/auditLog";
import { AuthPage } from "pages/auth";
import { BrandCreate, BrandList } from "pages/brands";
import { BrandEdit } from "pages/brands/edit";
import { BrandShow } from "pages/brands/show";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "pages/categories";
import { ClientCreate, ClientEdit, ClientList } from "pages/client";
import { DashboardPage } from "pages/dashboard";
import {
  ExportDamagedProductCreate,
  ExportDamagedProductList,
} from "pages/exportDamagedProduct";
import { ExportDamagedProductEdit } from "pages/exportDamagedProduct/edit";
import {
  ImportProductCreate,
  ImportProductEdit,
  ImportProductList,
} from "pages/importProduct";
import {
  OrderProductCreate,
  OrderProductEdit,
  OrderProductList,
} from "pages/orderProduct";
import { ProductCreate, ProductEdit, ProductList } from "pages/product";
import { StoreCreate, StoreEdit, StoreList } from "pages/store";
import { SupplierCreate, SupplierEdit, SupplierList } from "pages/supplier";
import { UserCreate, UserEdit, UserList } from "pages/user";
import { ablyClient } from "utility/ablyClient";
import { dataProvider } from "./rest-data-provider";

function App() {
  // const API_URL = "https://api.finefoods.refine.dev";
  const API_URL_DEV =
    "https://us-west-2.aws.data.mongodb-api.com/app/bkms-dev-wkctj/endpoint";
  const API_URL_PROD =
    "https://data.mongodb-api.com/app/bkms-she-prod-qvida/endpoint";
  const API_URL = API_URL_PROD;

  // const dataProvider = jsonServerDataProvider(API_URL);
  const { t, i18n } = useTranslation();
  const role = localStorage.getItem("role") ?? "undefined";
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  // const { data: role } = usePermissions();
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            auditLogProvider={{
              get: async ({ resource, meta }) => {
                console.log({ resource, meta });
                const { data } = await dataProvider(API_URL).getList({
                  resource: "auditLogs",
                  filters: [
                    {
                      field: "resource",
                      operator: "eq",
                      value: resource,
                    },
                    {
                      field: "meta.id",
                      operator: "eq",
                      value: meta?.id,
                    },
                  ],
                });

                return data;
              },
              create: (params) => {
                return dataProvider(API_URL).create({
                  resource: "auditLogs",
                  variables: {
                    ...params,
                    createdAt: new Date().toJSON(),
                  },
                });
              },
              update: async ({ id, name }) => {
                const { data } = await dataProvider(API_URL).update({
                  resource: "auditLogs",
                  id,
                  variables: { name },
                });
                return data;
              },
            }}
            dataProvider={dataProvider(API_URL)}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            i18nProvider={i18nProvider}
            authProvider={authProvider}
            liveProvider={liveProvider(ablyClient)}
            accessControlProvider={{
              can: async ({ action, params, resource }) => {
                // console.log({ resource, action, params });

                const enforcer = await newEnforcer(model, adapter);
                if (
                  action === "delete" ||
                  action === "edit" ||
                  action === "show"
                ) {
                  return Promise.resolve({
                    can: await enforcer.enforce(
                      role,
                      `${resource}/${params?.id}`,
                      action
                    ),
                  });
                }
                if (action === "field") {
                  return Promise.resolve({
                    can: await enforcer.enforce(
                      role,
                      `${resource}/${params?.field}`,
                      action
                    ),
                  });
                }
                return {
                  can: await enforcer.enforce(role, resource, action),
                };
              },
              // options: {
              //   buttons: {
              //     enableAccessControl: false,
              //     // hide action buttons if not authorized.
              //     hideIfUnauthorized: false,
              //   },
              // },
            }}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "Thống Kê",
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: "StoreManagement",
                meta: {
                  label: "Quản Lí Kho",
                  icon: <ShopOutlined />,
                },
              },
              {
                name: "ProductManagement",
                meta: {
                  label: "Quản Lí SP",
                  icon: <AppstoreOutlined />,
                },
              },
              {
                name: "CustomerManagement",
                meta: {
                  label: "Quản Lí KH",
                  icon: <TeamOutlined />,
                },
              },
              {
                name: "StoreAction",
                meta: {
                  label: "Hoạt Động",
                  icon: <ShoppingCartOutlined />,
                },
              },

              {
                name: "Administration",
                meta: {
                  label: "Quản Trị",
                  icon: <TeamOutlined />,
                },
              },
              {
                name: "products",
                list: "/ProductManagement/products",
                create: "/ProductManagement/products/create",
                edit: "/ProductManagement/products/edit/:id",
                show: "/ProductManagement/products/show/:id",
                meta: {
                  parent: "ProductManagement",
                },
              },
              {
                name: "brands",
                list: "/ProductManagement/brands",
                create: "/ProductManagement/brands/create",
                edit: "/ProductManagement/brands/edit/:id",
                show: "/ProductManagement/brands/show/:id",
                meta: {
                  parent: "ProductManagement",
                },
              },
              {
                name: "categories",
                list: "/ProductManagement/categories",
                create: "/ProductManagement/categories/create",
                edit: "/ProductManagement/categories/edit/:id",
                show: "/ProductManagement/categories/show/:id",
                meta: {
                  parent: "ProductManagement",
                },
              },

              {
                name: "stores",
                list: "/StoreManagement/stores",
                create: "/StoreManagement/stores/create",
                edit: "/StoreManagement/stores/edit/:id",
                show: "/StoreManagement/stores/show/:id",
                meta: {
                  parent: "StoreManagement",
                },
              },
              {
                name: "suppliers",
                list: "/StoreManagement/suppliers",
                create: "/StoreManagement/suppliers/create",
                edit: "/StoreManagement/suppliers/edit/:id",
                show: "/StoreManagement/suppliers/show/:id",
                meta: {
                  parent: "StoreManagement",
                },
              },
              {
                name: "clients",
                list: "/CustomerManagement/clients",
                create: "/CustomerManagement/clients/create",
                edit: "/CustomerManagement/clients/edit/:id",
                show: "/CustomerManagement/clients/show/:id",
                meta: {
                  parent: "CustomerManagement",
                },
              },
              {
                name: "users",
                list: "/Administration/users",
                create: "/Administration/users/create",
                edit: "/Administration/users/edit/:id",
                show: "/Administration/users/show/:id",
                meta: {
                  parent: "Administration",
                },
              },
              {
                name: "auditLogs",
                list: "/Administration/auditLogs",
                // create: "/Administration/auditLogs/create",
                // edit: "/Administration/auditLogs/edit/:id",
                // show: "/Administration/auditLogs/show/:id",
                meta: {
                  parent: "Administration",
                },
              },
              {
                name: "importProducts",
                list: "/StoreAction/importProducts",
                create: "/StoreAction/importProducts/create",
                edit: "/StoreAction/importProducts/edit/:id",
                show: "/StoreAction/importProducts/show/:id",
                meta: {
                  parent: "StoreAction",
                },
              },
              {
                name: "orderProducts",
                list: "/StoreAction/orderProducts",
                create: "/StoreAction/orderProducts/create",
                edit: "/StoreAction/orderProducts/edit/:id",
                show: "/StoreAction/orderProducts/show/:id",
                meta: {
                  parent: "StoreAction",
                  audit: ["create", "delete", "update"],
                },
              },
              {
                name: "exportDamagedProducts",
                list: "/StoreAction/exportDamagedProducts",
                create: "/StoreAction/exportDamagedProducts/create",
                edit: "/StoreAction/exportDamagedProducts/edit/:id",
                show: "/StoreAction/exportDamagedProducts/show/:id",
                meta: {
                  parent: "StoreAction",
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              liveMode: "auto",
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2 Header={Header} Title={Title}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />
                <Route path="StoreManagement">
                  <Route path="stores">
                    <Route index element={<StoreList />} />
                    <Route path="create" element={<StoreCreate />} />
                    <Route path="edit/:id" element={<StoreEdit />} />
                  </Route>
                  <Route path="suppliers">
                    <Route index element={<SupplierList />} />
                    <Route path="create" element={<SupplierCreate />} />
                    <Route path="edit/:id" element={<SupplierEdit />} />
                  </Route>
                </Route>
                <Route path="ProductManagement">
                  <Route path="products">
                    <Route index element={<ProductList />} />
                    <Route path="create" element={<ProductCreate />} />
                    <Route path="edit/:id" element={<ProductEdit />} />
                  </Route>
                  <Route path="brands">
                    <Route index element={<BrandList />} />
                    <Route path="edit/:id" element={<BrandEdit />} />
                    <Route path="create" element={<BrandCreate />} />
                    <Route path="show/:id" element={<BrandShow />} />
                  </Route>
                  <Route path="categories">
                    <Route index element={<CategoryList />} />
                    <Route path="edit/:id" element={<CategoryEdit />} />
                    <Route path="create" element={<CategoryCreate />} />
                    <Route path="show/:id" element={<CategoryShow />} />
                  </Route>
                </Route>
                <Route path="CustomerManagement">
                  <Route path="clients">
                    <Route index element={<ClientList />} />
                    <Route path="create" element={<ClientCreate />} />
                    <Route path="edit/:id" element={<ClientEdit />} />
                  </Route>
                </Route>
                <Route path="Administration">
                  <Route path="users">
                    <Route index element={<UserList />} />
                    <Route path="create" element={<UserCreate />} />
                    <Route path="edit/:id" element={<UserEdit />} />
                  </Route>
                  <Route path="auditLogs">
                    <Route index element={<AuditLogList />} />
                  </Route>
                </Route>
                <Route path="StoreAction">
                  <Route path="importProducts">
                    <Route index element={<ImportProductList />} />
                    <Route path="create" element={<ImportProductCreate />} />
                    <Route path="edit/:id" element={<ImportProductEdit />} />
                  </Route>
                  <Route path="orderProducts">
                    <Route index element={<OrderProductList />} />
                    <Route path="create" element={<OrderProductCreate />} />
                    <Route path="edit/:id" element={<OrderProductEdit />} />
                  </Route>
                  <Route path="exportDamagedProducts">
                    <Route index element={<ExportDamagedProductList />} />
                    <Route
                      path="create"
                      element={<ExportDamagedProductCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<ExportDamagedProductEdit />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<AuthPage type="login" />} />
              </Route>

              <Route
                element={
                  <Authenticated>
                    <ThemedLayoutV2
                      Header={Header}
                      Title={Title}
                      // OffLayoutArea={OffLayoutArea}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
