import { SearchOutlined } from "@ant-design/icons";
import { CreateButton, useSimpleList } from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useTranslate,
} from "@refinedev/core";
import { List as AntdList, Form, Input, Space, Typography } from "antd";
import { CategoryItem } from "components/category";
import { ICategory } from "interfaces";
import React from "react";
const { Text } = Typography;

export const CategoryList: React.FC<IResourceComponentsProps> = () => {
  const { listProps, searchFormProps, filters } = useSimpleList<
    ICategory,
    HttpError,
    { name: string }
  >({
    pagination: {
      mode: "off",
    },
    onSearch: ({ name }) => {
      const brandFilters: CrudFilters = [];

      brandFilters.push({
        field: "name",
        operator: "contains",
        value: name ? name : undefined,
      });

      return brandFilters;
    },
  });
  const t = useTranslate();

  return (
    <div>
      <Form
        {...searchFormProps}
        onValuesChange={() => {
          searchFormProps.form?.submit();
        }}
        initialValues={{
          name: getDefaultFilter("name", filters, "contains"),
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "8px",
            marginBottom: "16px",
          }}
        >
          <Text style={{ fontSize: "24px" }} strong>
            {t("categories.categories")}
          </Text>
          <Form.Item name="name" noStyle>
            <Input
              style={{
                width: "400px",
              }}
              placeholder={t("categories.actions.search")}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
          <Space>
            <CreateButton resource="categories">
              {t("buttons.create")}
            </CreateButton>
          </Space>
        </div>
        <AntdList
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          style={{
            height: "100%",
            overflow: "auto",
            paddingRight: "4px",
          }}
          {...listProps}
          renderItem={(item: ICategory) => <CategoryItem item={item} />}
        />
      </Form>
    </div>
  );
};

// const CategoryProductsTable: React.FC<{ record: ICategory }> = ({ record }) => {
//   const t = useTranslate();

//   const { tableProps: postTableProps, tableQueryResult } = useTable<IProduct>({
//       resource: "products",
//       permanentFilter: [
//           {
//               field: "category",
//               operator: "eq",
//               value: record._id,
//           },
//       ],
//       syncWithLocation: false,
//   });

//   const products = tableQueryResult?.data?.data ?? [];

//   const { data: categoryData, isLoading: categoryIsLoading } = useMany<
//     ICategory,
//     HttpError
//   >({
//     resource: "categories",
//     // Creates the array of ids. This will filter and fetch the category data for the relevant posts.
//     ids: products.map((item) => item?.category),
//     queryOptions: {
//       // Set to true only if the posts array is not empty.
//       enabled: !!products.length,
//     },
//   });

//   const { data: brandData, isLoading: brandIsLoading } = useMany<
//     IBrand,
//     HttpError
//   >({
//     resource: "brands",
//     // Creates the array of ids. This will filter and fetch the category data for the relevant posts.
//     ids: products.map((item) => item?.brand),
//     queryOptions: {
//       // Set to true only if the posts array is not empty.
//       enabled: !!products.length,
//     },
//   });
//   return (
//       <List header={t("products.products")} >
//           <Table {...postTableProps} rowKey="_id">
//             <Table.Column
//               dataIndex="cover"
//               render={(value) => <Avatar size={74} src={value[0].url} />}
//               width={105}
//             />
//             <Table.Column
//               key="name"
//               dataIndex="name"
//               title={t("products.fields.name")}
//             />
//             <Table.Column
//               dataIndex="brand"
//               title={t("products.fields.brand")}
//               render={(value) => {
//                 if (brandIsLoading) {
//                   return "...";
//                 } else {
//                   return (
//                     brandData?.data.find((x) => x._id === value)?.name || "-"
//                   );
//                 }
//               }}
//               width={105}
//             />
//             <Table.Column
//               dataIndex="category"
//               title={t("products.fields.category")}
//               render={(value) => {
//                 if (categoryIsLoading) {
//                   return "...";
//                 } else {
//                   return (
//                     categoryData?.data.find((x) => x._id === value)?.name || "-"
//                   );
//                 }
//               }}
//               width={105}
//             />
//             <Table.Column
//               align="right"
//               key="priceImport"
//               dataIndex="priceImport"
//               title={t("products.fields.priceImport")}
//               render={(value) => {
//                 return (
//                   <NumberField
//                     options={{
//                       currency: "VND",
//                       style: "currency",
//                       notation: "compact",
//                     }}
//                     value={value}
//                   />
//                 );
//               }}
//               sorter
//             />
//             <Table.Column
//               align="right"
//               key="priceSell"
//               dataIndex="priceSell"
//               title={t("products.fields.priceSell")}
//               render={(value) => {
//                 return (
//                   <NumberField
//                     options={{
//                       currency: "VND",
//                       style: "currency",
//                       notation: "compact",
//                     }}
//                     value={value}
//                   />
//                 );
//               }}
//               sorter
//             />{" "}
//             <Table.Column
//               align="right"
//               key="qtyOnOrder"
//               dataIndex="qtyOnOrder"
//               title={t("products.fields.qtyOnOrder")}
//               render={(value) => {
//                 return <NumberField value={value} />;
//               }}
//               sorter
//             />
//             <Table.Column
//               align="right"
//               key="qtyOnTransport"
//               dataIndex="qtyOnTransport"
//               title={t("products.fields.qtyOnTransport")}
//               render={(value) => {
//                 return <NumberField value={value} />;
//               }}
//               sorter
//             />
//             <Table.Column<IProduct>
//               key="stocks"
//               dataIndex="stocks"
//               title={t("products.fields.stocks")}
//               render={(_, record) => (
//                 <Popover
//                   content={
//                     <ul>
//                       <li>Hello</li>
//                       {record.stocks.map((store: IProductStore) => (
//                         <li key={store.store}>{store.stock}</li>
//                       ))}
//                     </ul>
//                   }
//                   title="Products"
//                   trigger="hover"
//                 >

//                   Store
//                 </Popover>
//               )}
//             />
//             <Table.Column
//               key="isActive"
//               dataIndex="isActive"
//               title={t("products.fields.isActive")}
//               render={(value) => <BooleanField value={value} />}
//             />
//             <Table.Column
//               key="createdAt"
//               dataIndex="createdAt"
//               title={t("products.fields.createdAt")}
//               // render={(value) => <DateField value={value} locales="vi-VN" format="LLL" />}
//               sorter
//             />
//             <Table.Column<IProduct>
//               dataIndex="products_actions"
//               title={t("table.actions")}
//               render={(_, record) => {
//                 const items: MenuProps["items"] = [
//                   {
//                     key: "1",
//                     label: (
//                       <EditButton size="small" resource="products" recordItemId={record._id} />
//                     ),
//                   },
//                   // {
//                   //   key: "2",
//                   //   label: (
//                   //     <ShowButton size="small" recordItemId={record._id} />
//                   //   ),
//                   // },
//                 ];
//                 // <Dropdown
//                 //     overlay={moreMenu(record)}
//                 //     trigger={["click"]}
//                 // >
//                 //     <MoreOutlined
//                 //         onClick={(e) => e.stopPropagation()}
//                 //         style={{
//                 //             fontSize: 24,
//                 //         }}
//                 //     />
//                 // </Dropdown>
//                 return (
//                   <Dropdown menu={{ items }} trigger={["click"]}>
//                     <MoreOutlined
//                       style={{
//                         fontSize: 24,
//                       }}
//                     />
//                   </Dropdown>
//                 );
//               }}
//             />
//           </Table>
//       </List>
//   );
// };

// const expandedRowRender = (record: ICategory) => {
//   return <CategoryProductsTable record={record} />;
// };
