// import { IResourceComponentsProps } from "@refinedev/core";
// import { AntdEditInferencer } from "@refinedev/inferencer/antd";

// export const BrandEdit: React.FC<IResourceComponentsProps> = () => {
//     return <AntdEditInferencer />;
// };
import dayjs from "dayjs";

import {
  HttpError,
  IResourceComponentsProps,
  useCan,
  useList,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import {
  Create,
  CreateButton,
  DateField,
  NumberField,
  SaveButton,
  TextField,
  useDrawerForm,
  useStepsForm,
} from "@refinedev/antd";

import {
  Alert,
  Avatar,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Steps,
  Table,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ProductCreateDrawer } from "components/product";
import { StoreCreateDrawer } from "components/store";
import { SupplierCreateDrawer } from "components/supplier/create";
import {
  IClient,
  IImpProd,
  IImportProduct,
  IPaymentOperation,
  IProduct,
  IStore,
  ISupplier,
} from "interfaces";
import React, { useEffect, useRef, useState } from "react";
const { Option } = Select;
const { Step } = Steps;

export const ImportProductCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [isFailedOnMutation, setIsFailedOnMutation] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const { mutate } = useUpdate();

  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    onFinish,
    queryResult,
  } = useStepsForm<IImportProduct>({
    submit: (values) => {
      const formValues = formProps.form?.getFieldsValue(true);
      var paymentInfo = formValues.payment;
      paymentInfo.debt =
        paymentInfo.total + paymentInfo.refund - paymentInfo.paid;
      if (paymentInfo.debt < 0) {
        paymentInfo.status = "Refund";
      } else if (paymentInfo.debt > 0) {
        paymentInfo.status = "Debt";
      } else {
        if (paymentInfo.total === paymentInfo.paid && paymentInfo.total !== 0) {
          paymentInfo.status = "Done";
        }
      }

      var impStatus = "OnArrived";
      const checkQty = formValues.products.find(
        (x: IImpProd) => x.qtyImport !== x.qtyArrived
      );

      if (checkQty !== undefined) {
        impStatus = "OnTransport";
      }

      if (paymentInfo.paymentOperations === undefined) {
        paymentInfo.paymentOperations = [];
      }

      return (
        formProps.onFinish &&
        formProps.onFinish({
          ...formValues,
          importStatus: impStatus,
          payment: paymentInfo,
        })
      );
    },
    onMutationSuccess: (data, variables, context) => {
      const orderProductItems = data.data;
      orderProductItems.productsToUpdate.forEach((element) => {
        mutate({
          resource: "products",
          id: element.product,
          values: {
            priceImport: element.priceImport,
            priceSell: element.priceSell,
            productStatus: element.productStatus,
            qtyOnOrder: element.qtyOnOrder,
            qtyOnTransport: element.qtyOnTransport,
            qtyInStocks: element.qtyInStocks,
            stocks: element.stocks,
          },
        });
      });
    },
    onMutationError: (data, variables, context) => {
      const error = data.response?.data?.error;
      const errorJson = JSON.parse(error);
      const errorDetail = JSON.parse(errorJson.message);
      setIsFailedOnMutation(true);
      setErrorMessages(errorDetail.errors);
    },
  });

  const { data: supplierData, isLoading: supplierDataIsLoading } = useList<
    IClient,
    HttpError
  >({
    resource: "suppliers",
    pagination: {
      mode: "off",
    },
  });
  const supplierList = supplierData?.data ?? [];

  const { data: storeData, isLoading: storeDataIsLoading } = useList<
    IStore,
    HttpError
  >({
    resource: "stores",
    pagination: {
      mode: "off",
    },
  });
  const storeList = storeData?.data ?? [];

  const {
    drawerProps: createProductDrawerProps,
    formProps: createProductFormProps,
    saveButtonProps: createProductSaveButtonProps,
    show: createProductShow,
  } = useDrawerForm<IProduct>({
    action: "create",
    resource: "products",
    redirect: false,
  });
  const {
    drawerProps: createSupplierDrawerProps,
    formProps: createSupplierFormProps,
    saveButtonProps: createSupplierSaveButtonProps,
    show: createSupplierShow,
  } = useDrawerForm<ISupplier>({
    action: "create",
    resource: "suppliers",
    redirect: false,
  });

  const { data: canAccessPrice } = useCan({
    resource: "importProducts",
    action: "field",
    params: { field: "payment" },
  });

  const {
    drawerProps: createStoreDrawerProps,
    formProps: createStoreFormProps,
    saveButtonProps: createStoreSaveButtonProps,
    show: createStoreShow,
  } = useDrawerForm<IStore>({
    action: "create",
    resource: "stores",
    redirect: false,
  });

  const useResetFormOnCloseModal = ({
    form,
    open,
  }: {
    form: FormInstance;
    open: boolean;
  }) => {
    const prevOpenRef = useRef<boolean>();
    useEffect(() => {
      prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {
      if (!open && prevOpen) {
        form.resetFields();
      }
    }, [form, prevOpen, open]);
  };
  const ModalForm: React.FC<{
    open: boolean;
    onCancel: () => void;
  }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();

    useResetFormOnCloseModal({
      form,
      open,
    });

    const onOk = () => {
      form.submit();
    };

    return (
      <Modal
        title="Thanh Toán Đơn Hàng"
        open={open}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form
          form={form}
          layout="vertical"
          name="paymentForm"
          initialValues={{
            paymentDate: dayjs(new Date()),
          }}
        >
          <Form.Item
            label="Ngày Thanh Toán"
            name="paymentDate"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập trạng thái!",
              },
            ]}
          >
            <DatePicker showTime placeholder="Chọn Ngày ..." />
          </Form.Item>
          <Form.Item
            name="paymentType"
            label="Loại Thanh Toán"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              // onChange={handleChangeOrderStatus}
              style={{ width: "70%" }}
              placeholder="Loại Thanh Toán..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                {
                  value: "PaymentForSupplier",
                  label: "Thanh Toán Cho NCC",
                },
                {
                  value: "RefundBySupplier",
                  label: "NCC Hoàn Lại",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="paymentMethod"
            label="Phương Thức Thanh Toán"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              // onChange={handleChangeOrderStatus}
              style={{ width: "70%" }}
              placeholder="Phương Thức Thanh Toán..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                {
                  value: "TransferViaBank",
                  label: "Chuyển Khoản",
                },
              ]}
            />
          </Form.Item>

          <Form.Item name="amount" label="Số Tiền" rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonAfter="VNĐ"
              style={{ width: "300px" }}
              min={0}
            />
          </Form.Item>
          <Form.Item name="note" label="Ghi Chú" rules={[{ required: false }]}>
            <Input style={{ width: "300px" }} />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const [open, setOpen] = useState(false);

  const showUserModal = () => {
    setOpen(true);
  };

  const hideUserModal = () => {
    setOpen(false);
  };

  const { data, isLoading } = useList<IProduct, HttpError>({
    resource: "products",
    liveMode: "auto",
    pagination: {
      mode: "off",
    },
  });

  const productList = data?.data ?? [];

  const CreateFormList = [
    <>
      <Form.Item
        label={t("importProducts.fields.importDate")}
        name="importDate"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <DatePicker showTime placeholder="Chọn Ngày ..." />
      </Form.Item>
      <Form.Item
        label={t("importProducts.fields.store")}
        // extra="We must make sure that your are a human."
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="store"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Chọn Kho Hàng ..."
                optionLabelProp="label"
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {!storeDataIsLoading &&
                  storeList.map((store) => {
                    return (
                      <Option
                        key={store._id}
                        value={store._id}
                        label={store.name}
                      >
                        <Space key={uuidv4()}>
                          <Descriptions size="small">
                            <Descriptions.Item
                              label={t("stores.fields.name")}
                              span={3}
                            >
                              {store?.name}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t("stores.fields.address")}
                              span={3}
                            >
                              {store?.address}
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <CreateButton onClick={() => createStoreShow()}>
              Thêm Kho Mới
            </CreateButton>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label={t("importProducts.fields.supplier")}
        // extra="We must make sure that your are a human."
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="supplier"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={supplierList?.map((x) => {
                  return { label: x.name, value: x._id };
                })}
                allowClear
                placeholder={t("suppliers.suppliers")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <CreateButton onClick={() => createSupplierShow()}>
              Thêm NCC Mới
            </CreateButton>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label={t("importProducts.fields.description")}
        name="description"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
    </>,
    <>
      <Form.List
        name="products"
        rules={[
          {
            validator: async (_, products) => {
              if (!products || products.length < 1) {
                return Promise.reject(new Error("Chưa có SP nào !"));
              }
              if (products !== undefined && products.length > 0) {
                var invalidProduct = products.find(
                  (x: IImpProd) => x !== undefined && x.qtyArrived > x.qtyImport
                );
                if (invalidProduct !== undefined) {
                  var name =
                    productList.find((x) => x._id === invalidProduct.product)
                      ?.name ?? invalidProduct.product;
                  return Promise.reject(
                    new Error(`${name} có SL Đã Về lớn hơn SL Nhập !`)
                  );
                }
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field) => {
                // console.log(formProps.form?.getFieldsValue());
                return (
                  <Space key={field.key} align="baseline">
                    <Form.Item style={{ width: "100%" }} shouldUpdate>
                      {() => (
                        <Form.Item
                          {...field}
                          label={t("importProducts.fields.products.product")}
                          name={[field.name, "product"]}
                          // rules={[
                          //   { required: true, message: "Missing sight" },
                          // ]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  getFieldValue("products").filter(
                                    (x: { product: any }) => x.product === value
                                  ).length > 1
                                ) {
                                  return Promise.reject(
                                    new Error("Đã tồn tại sản phẩm !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Select
                            showSearch
                            style={{
                              width: "300px",
                            }}
                            allowClear
                            placeholder="Chọn Sản Phẩm Cần Nhập ..."
                            optionLabelProp="label"
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {!isLoading &&
                              productList.map((product) => {
                                return (
                                  <Option
                                    key={product._id}
                                    value={product._id}
                                    label={product.name}
                                  >
                                    <Space key={uuidv4()}>
                                      <Avatar
                                        size={100}
                                        src={product?.cover?.[0].url}
                                      ></Avatar>
                                      <Descriptions size="small">
                                        <Descriptions.Item
                                          label={t("products.fields.name")}
                                          span={3}
                                        >
                                          {product?.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={t("products.fields.priceSell")}
                                          span={3}
                                        >
                                          {product?.priceSell}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={t(
                                            "products.fields.qtyOnOrder"
                                          )}
                                          span={3}
                                        >
                                          {product?.qtyOnOrder}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={t(
                                            "products.fields.qtyOnTransport"
                                          )}
                                          span={3}
                                        >
                                          {product?.qtyOnTransport}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Space>
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      key={uuidv4()}
                      style={{ width: "100px" }}
                      label={t("importProducts.fields.products.qtyImport")}
                      name={[field.name, "qtyImport"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing price",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Phải lớn hơn 0 !")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        style={{
                          width: "100px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      key={uuidv4()}
                      style={{ width: "100px" }}
                      label={t("importProducts.fields.products.qtyArrived")}
                      name={[field.name, "qtyArrived"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing price",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Phải lớn hơn 0 !")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        style={{
                          width: "100px",
                        }}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      key={uuidv4()}
                      style={{ width: "150px" }}
                      label={
                        canAccessPrice?.can &&
                        t("importProducts.fields.products.importPrice")
                      }
                      name={[field.name, "importPrice"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing price",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Phải lớn hơn 0 !")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={0}
                    >
                      {canAccessPrice?.can && (
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          style={{
                            width: "150px",
                          }}
                          min={0}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      key={uuidv4()}
                      style={{ width: "150px" }}
                      label={
                        canAccessPrice?.can &&
                        t("importProducts.fields.products.transportFee")
                      }
                      name={[field.name, "transportFee"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing price",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Phải lớn hơn 0 !")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={0}
                    >
                      {canAccessPrice?.can && (
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          style={{
                            width: "150px",
                          }}
                        />
                      )}
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                );
              })}
              <Form.Item style={{ width: "300px" }}>
                <Space direction="horizontal">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Thêm SP vào giỏ
                  </Button>
                  <CreateButton onClick={() => createProductShow()}>
                    Sản Phẩm Mới ?
                  </CreateButton>
                </Space>
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </>
          );
        }}
      </Form.List>
    </>,
  ];

  const paymentElementForm = (
    <>
      {canAccessPrice?.can && (
        <Divider orientation="left">
          {t("importProducts.fields.payment.label")}
        </Divider>
      )}
      <Form.Item
        label={canAccessPrice?.can && t("importProducts.fields.payment.total")}
        name={["payment", "total"]}
        initialValue={0}
      >
        {canAccessPrice?.can && (
          <InputNumber
            readOnly
            style={{ width: "300px" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            addonAfter="VNĐ"
          />
        )}
      </Form.Item>
      <Form.Item
        label={canAccessPrice?.can && t("importProducts.fields.payment.paid")}
        name={["payment", "paid"]}
        rules={[
          () => ({
            validator(_, value) {
              if (value < 0) {
                return Promise.reject(new Error("Phải lớn hơn 0 !"));
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
        initialValue={0}
      >
        {canAccessPrice?.can && (
          <InputNumber
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            min={0}
            addonAfter="VNĐ"
            style={{ width: "300px" }}
          />
        )}
      </Form.Item>
      <Form.Item
        label={canAccessPrice?.can && t("importProducts.fields.payment.debt")}
        name={["payment", "debt"]}
        initialValue={0}
      >
        {canAccessPrice?.can && (
          <InputNumber
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            min={0}
            addonAfter="VNĐ"
            readOnly
            style={{ width: "300px" }}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="button"
          style={{ margin: "0 8px" }}
          onClick={showUserModal}
          icon={<PlusOutlined />}
        >
          Thanh Toán ?
        </Button>
      </Form.Item>
      <Divider orientation="left">Lịch Sử Thanh Toán</Divider>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.payment !== curValues.payment
        }
      >
        {({ getFieldValue }) => {
          const paymentOperations: IPaymentOperation[] =
            getFieldValue("payment").paymentOperations || [];
          return (
            <Table dataSource={paymentOperations}>
              <Table.Column
                // key="orderDate"
                dataIndex="paymentDate"
                title="Ngày TT"
                render={(value) => (
                  <DateField value={value} format="DD/MM/YYYY LT" />
                )}
                width={120}
              />
              <Table.Column
                align="center"
                // key="orderStatus"
                dataIndex="paymentType"
                title="Loại"
                render={(value) => {
                  return <TextField value={t(`enum.paymentType.${value}`)} />;
                }}
                width={120}
              />
              <Table.Column
                align="center"
                // key="orderStatus"
                dataIndex="paymentMethod"
                title="Phương Thức"
                render={(value) => {
                  return <TextField value={t(`enum.paymentMethod.${value}`)} />;
                }}
                width={120}
              />
              <Table.Column<IPaymentOperation>
                align="center"
                dataIndex="amount"
                title={t("orderProducts.fields.payment.paid")}
                render={(value) => {
                  return (
                    <NumberField
                      options={{
                        currency: "VND",
                        style: "currency",
                        notation: "compact",
                      }}
                      value={value}
                    />
                  );
                }}
                sorter
                width={125}
              />
              <Table.Column
                align="center"
                // key="orderStatus"
                dataIndex="note"
                title="Ghi Chú"
                render={(value) => {
                  return <TextField value={value} />;
                }}
                width={120}
              />
            </Table>
          );
        }}
      </Form.Item>
    </>
  );

  const lengthForm = canAccessPrice?.can ? 3 : 2;
  return (
    <>
      <Create
        isLoading={queryResult?.isFetching}
        footerButtons={
          <>
            {current > 0 && (
              <Button
                onClick={() => {
                  console.log(current);
                  gotoStep(current - 1);
                }}
              >
                Quay lại
              </Button>
            )}
            {current < lengthForm - 1 && (
              <Button
                onClick={() => {
                  if (current === 1) {
                    const total =
                      formProps.form
                        ?.getFieldValue("products")
                        ?.reduce(
                          (accumulator: number, currentValue: IImpProd) => {
                            if (
                              currentValue !== undefined &&
                              currentValue.importPrice !== undefined &&
                              currentValue.transportFee !== undefined &&
                              currentValue.qtyImport !== undefined
                            ) {
                              return (
                                accumulator +
                                (currentValue.importPrice +
                                  currentValue.transportFee) *
                                  currentValue.qtyImport
                              );
                            } else {
                              return accumulator;
                            }
                          },
                          0
                        ) ?? 0;

                    formProps.form?.setFieldsValue({
                      payment: {
                        total: total,
                      },
                    });
                  }
                  gotoStep(current + 1);
                }}
              >
                Tiếp Theo
              </Button>
            )}
            {current === lengthForm - 1 && <SaveButton {...saveButtonProps} />}
          </>
        }
      >
        {/* <Steps
          {...stepsProps}
          items={[
            {
              title: "Thông Tin Chung",
            },
            {
              title: "Sản Phẩm",
            },
            {
              title: "Thanh Toán Đơn Hàng",
            },
          ]}
        /> */}
        {canAccessPrice?.can ? (
          <Steps
            {...stepsProps}
            items={[
              {
                title: "Thông Tin Chung",
              },
              {
                title: "Sản Phẩm",
              },
              {
                title: "Thanh Toán Đơn Hàng",
              },
            ]}
          />
        ) : (
          <Steps
            {...stepsProps}
            items={[
              {
                title: "Thông Tin Chung",
              },
              {
                title: "Sản Phẩm",
              },
            ]}
          />
        )}

        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "paymentForm") {
              const paymentOperations =
                formProps.form?.getFieldValue("payment").paymentOperations ||
                [];
              const newPaymentOperations = [...paymentOperations, values];

              const customerPayments =
                newPaymentOperations.reduce(
                  (accumulator: number, currentValue: IPaymentOperation) => {
                    if (
                      currentValue !== undefined &&
                      currentValue.paymentType == "PaymentForSupplier"
                    ) {
                      return accumulator + currentValue.amount;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                ) ?? 0;
              const refundForCustomer =
                newPaymentOperations.reduce(
                  (accumulator: number, currentValue: IPaymentOperation) => {
                    if (
                      currentValue !== undefined &&
                      currentValue.paymentType == "RefundBySupplier"
                    ) {
                      return accumulator + currentValue.amount;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                ) ?? 0;
              formProps.form?.setFieldsValue({
                payment: {
                  paid: customerPayments,
                  refund: refundForCustomer,
                  paymentOperations: newPaymentOperations,
                },
              });
              setOpen(false);
            }
          }}
        >
          <Form
            {...formProps}
            name="formOrder"
            layout="vertical"
            initialValues={{
              importDate: dayjs(new Date()),
              payment: {
                total: 0,
                paid: 0,
                debt: 0,
                refund: 0,
                paymentOperations: [],
                status: "Pending",
              },
            }}
            style={{ marginTop: 30 }}
            scrollToFirstError
          >
            {canAccessPrice?.can
              ? [...CreateFormList, paymentElementForm][current]
              : CreateFormList[current]}
          </Form>
          <ModalForm open={open} onCancel={hideUserModal} />
        </Form.Provider>

        <ProductCreateDrawer
          drawerProps={createProductDrawerProps}
          formProps={createProductFormProps}
          saveButtonProps={createProductSaveButtonProps}
        />
        <SupplierCreateDrawer
          drawerProps={createSupplierDrawerProps}
          formProps={createSupplierFormProps}
          saveButtonProps={createSupplierSaveButtonProps}
        />
        <StoreCreateDrawer
          drawerProps={createStoreDrawerProps}
          formProps={createStoreFormProps}
          saveButtonProps={createStoreSaveButtonProps}
        />
        {isFailedOnMutation === true &&
          errorMessages.map((x: any) => {
            var message = `${x.message} (${
              storeList.find((store) => store._id === x.store)?.name ?? x.store
            })!`;
            return (
              <Alert
                key={uuidv4()}
                message={message}
                type="warning"
                style={{ marginBottom: 20 }}
              />
            );
          })}
      </Create>
    </>
  );
};
