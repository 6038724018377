import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import {
  BooleanField,
  CreateButton,
  DateField,
  EditButton,
  NumberField,
  TagField,
  TextField,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useList,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import {
  Avatar,
  Descriptions,
  Divider,
  Dropdown,
  Form,
  Grid,
  Input,
  List,
  MenuProps,
  Popover,
  Space,
  Table,
  Typography,
} from "antd";
import { OrderStatus, PaymentStatus } from "components/status";
import {
  IClient,
  IOrderProduct,
  IOrderProductItem,
  IProduct,
  IStore,
} from "interfaces";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const { Text } = Typography;

export const ClientList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, filters } = useTable<
    IClient,
    HttpError,
    { name: string }
  >({
    pagination: {
      mode: "off",
    },
    onSearch: ({ name }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "name",
        operator: "contains",
        value: name ? name : undefined,
      });

      return filters;
    },
  });

  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List>
          <Form
            {...searchFormProps}
            onValuesChange={() => {
              searchFormProps.form?.submit();
            }}
            initialValues={{
              name: getDefaultFilter("name", filters, "contains"),
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "8px",
                marginBottom: "16px",
              }}
            >
              <Text style={{ fontSize: "24px" }} strong>
                {t("clients.clients")}
              </Text>
              <Form.Item name="name" noStyle>
                <Input
                  style={{
                    width: "400px",
                  }}
                  placeholder={t("clients.actions.searchFast")}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
              <Space>
                <CreateButton resource="clients">
                  {t("buttons.create")}
                </CreateButton>
              </Space>
            </div>
          </Form>

          <Table
            {...tableProps}
            rowKey="_id"
            expandable={{
              expandedRowRender: !breakpoint.xs ? expandedRowRender : undefined,
            }}
          >
            <Table.Column
              key="name"
              dataIndex="name"
              title={t("clients.fields.name")}
            />
            <Table.Column
              dataIndex="address"
              title={t("clients.fields.address")}
            />
            <Table.Column
              dataIndex="networkContact"
              title={t("clients.fields.networkContact")}
            />
            <Table.Column dataIndex="phone" title={t("clients.fields.phone")} />
            <Table.Column
              key="isActive"
              dataIndex="isActive"
              title={t("stores.fields.isActive")}
              render={(value) => <BooleanField value={value} />}
            />
            <Table.Column
              key="createdAt"
              dataIndex="createdAt"
              title={t("stores.fields.createdAt")}
              sorter
            />
            <Table.Column<IProduct>
              dataIndex="products_actions"
              title={t("table.actions")}
              render={(_, record) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: (
                      <EditButton
                        size="small"
                        resource="clients"
                        recordItemId={record._id}
                      />
                    ),
                  },
                  // {
                  //   key: "2",
                  //   label: (
                  //     <ShowButton size="small" recordItemId={record._id} />
                  //   ),
                  // },
                ];

                return (
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <MoreOutlined
                      style={{
                        fontSize: 24,
                      }}
                    />
                  </Dropdown>
                );
              }}
            />
          </Table>
        </List>
      </Space>
    </>
  );
};

const ClientOrdersTable: React.FC<{ record: IClient }> = ({ record }) => {
  const t = useTranslate();

  const { tableProps: postTableProps } = useTable<IOrderProduct>({
    resource: "orderProducts",
    pagination: {
      mode: "off",
    },
    permanentFilter: [
      {
        field: "client",
        operator: "eq",
        value: record._id,
      },
    ],
    syncWithLocation: false,
  });
  const { data: storeData, isLoading: storeIsLoading } = useList<
    IStore,
    HttpError
  >({
    resource: "stores",
    pagination: {
      mode: "off",
    },
  });

  const storeList = storeData?.data ?? [];
  const { data: productData } = useList<IProduct, HttpError>({
    resource: "products",
    pagination: {
      mode: "off",
    },
  });

  const productList = productData?.data ?? [];

  const { edit } = useNavigation();

  return (
    <>
      <Divider orientation="left">Thống Kê</Divider>
      <Descriptions
        key={uuidv4()}
        size="small"
        style={{
          width: "400px",
        }}
        column={2}
      >
        <Descriptions.Item label="Số Lượng Đơn" span={1}>
          <TextField value={postTableProps.dataSource?.length ?? 0}></TextField>
        </Descriptions.Item>
        <Descriptions.Item label="Tổng Mua" span={1}>
          <NumberField
            options={{
              currency: "VND",
              style: "currency",
              notation: "compact",
            }}
            value={
              postTableProps.dataSource?.reduce(
                (accumulator: number, currentValue: IOrderProduct) => {
                  return accumulator + currentValue.payment.total;
                },
                0
              ) ?? 0
            }
          />
        </Descriptions.Item>
        <Descriptions.Item label="Đã Thanh Toán" span={1}>
          <NumberField
            options={{
              currency: "VND",
              style: "currency",
              notation: "compact",
            }}
            value={
              postTableProps.dataSource?.reduce(
                (accumulator: number, currentValue: IOrderProduct) => {
                  return accumulator + currentValue.payment.paid;
                },
                0
              ) ?? 0
            }
          />
        </Descriptions.Item>

        <Descriptions.Item label="Còn Nợ Lại" span={1}>
          <NumberField
            options={{
              currency: "VND",
              style: "currency",
              notation: "compact",
            }}
            value={
              postTableProps.dataSource?.reduce(
                (accumulator: number, currentValue: IOrderProduct) => {
                  return accumulator + currentValue.payment.debt;
                },
                0
              ) ?? 0
            }
          />
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">Danh Sách Đơn Đã Đặt</Divider>
      <Table {...postTableProps} rowKey="_id">
        <Table.Column
          // key="orderDate"
          dataIndex="orderDate"
          title={t("orderProducts.fields.orderDate")}
          render={(value) => <DateField value={value} format="DD/MM/YYYY LT" />}
          width={120}
        />
        <Table.Column<IOrderProduct>
          dataIndex="orderNumber"
          title={t("orderProducts.fields.orderNumber")}
          render={(_, record) => {
            return (
              <TagField
                onClick={() => edit("orderProducts", record._id)}
                value={`#${record.orderNumber.toString().padStart(4, "0")}`}
              />
            );
          }}
          width={120}
        />
        <Table.Column
          align="center"
          // key="orderStatus"
          dataIndex="orderStatus"
          title={t("orderProducts.fields.orderStatus")}
          render={(value) => {
            return <OrderStatus status={value} />;
          }}
          width={120}
        />
        <Table.Column<IOrderProduct>
          align="center"
          dataIndex="store"
          title={t("orderProducts.fields.store")}
          render={(_, record) => {
            if (storeList?.find((x) => x._id === record.store)) {
              return (
                storeList?.find((x) => x._id === record.store)?.name || "-"
              );
            } else {
              return "...";
            }
          }}
          width={125}
        />

        <Table.Column
          align="center"
          dataIndex={["payment", "status"]}
          title={t("orderProducts.fields.payment.status")}
          render={(value) => {
            return <PaymentStatus status={value} />;
          }}
          // sorter
          width={125}
        />
        <Table.Column
          align="center"
          dataIndex={["payment", "paid"]}
          title={t("orderProducts.fields.payment.paid")}
          render={(value) => {
            return (
              <NumberField
                options={{
                  currency: "VND",
                  style: "currency",
                  notation: "compact",
                }}
                value={value}
              />
            );
          }}
          sorter
          width={125}
        />
        <Table.Column
          align="center"
          dataIndex={["payment", "debt"]}
          title={t("orderProducts.fields.payment.debt")}
          render={(value) => {
            return (
              <NumberField
                options={{
                  currency: "VND",
                  style: "currency",
                  notation: "compact",
                }}
                value={value}
              />
            );
          }}
          sorter
          width={125}
        />
        <Table.Column<IOrderProduct>
          align="center"
          // key="products"
          dataIndex="products"
          title={"Sản Phẩm"}
          render={(_, record) => (
            <Popover
              content={
                <Space direction="vertical">
                  {record.products.map((ordProd: IOrderProductItem) => {
                    var detailProduct = productList.find(
                      (x) => x._id === ordProd.product
                    );

                    if (detailProduct !== undefined) {
                      return (
                        <Space key={uuidv4()}>
                          <Avatar
                            key={uuidv4()}
                            size={70}
                            src={detailProduct?.cover?.[0].url}
                          ></Avatar>
                          <Descriptions
                            key={uuidv4()}
                            size="small"
                            style={{
                              width: "400px",
                            }}
                            column={2}
                          >
                            <Descriptions.Item
                              label={t("orderProducts.fields.products.product")}
                              span={1}
                            >
                              <TextField strong value={detailProduct?.name} />
                            </Descriptions.Item>
                            <Descriptions.Item label="SL Đặt Mua" span={1}>
                              <TextField value={ordProd.qtyOrder}></TextField>
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t(
                                "orderProducts.fields.products.unitPrice"
                              )}
                              span={1}
                            >
                              <NumberField
                                options={{
                                  currency: "VND",
                                  style: "currency",
                                  notation: "compact",
                                }}
                                value={ordProd.unitPrice}
                              />
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t(
                                "orderProducts.fields.products.transportFee"
                              )}
                              span={1}
                            >
                              <NumberField
                                options={{
                                  currency: "VND",
                                  style: "currency",
                                  notation: "compact",
                                }}
                                value={ordProd.transportFee}
                              />
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>
                      );
                    }
                    return <></>;
                  })}
                </Space>
              }
              title={t("orderProducts.fields.products.label")}
              trigger="hover"
            >
              <Space direction="vertical">
                <TextField
                  value={`${record.products.length} Sản Phẩm`}
                ></TextField>
              </Space>
            </Popover>
          )}
        />
        <Table.Column<IOrderProduct>
          align="center"
          // key="products"
          dataIndex="products"
          title="Kho Có Sẵn Hàng"
          render={(_, record) => {
            var dispoStores: string[] = [];
            storeList.forEach((store: IStore) => {
              var isReady = true;
              record.products.map((ordProd: IOrderProductItem) => {
                var detailProduct = productList.find(
                  (x) => x._id === ordProd.product && x.stocks.length > 0
                );
                if (detailProduct !== undefined) {
                  var stock = detailProduct.stocks.find(
                    (x) => x.store === store._id && x.stock > ordProd.qtyOrder
                  );
                  if (stock !== undefined) {
                    isReady = isReady && true;
                  } else {
                    isReady = isReady && false;
                  }
                } else {
                  isReady = isReady && false;
                }
              });
              if (isReady) {
                dispoStores.push(store.name);
              }
            });
            if (dispoStores.length > 0 && record.orderStatus === "Pending") {
              return (
                <Space direction="vertical">
                  {dispoStores.map((x) => {
                    return <TextField value={x}></TextField>;
                  })}
                </Space>
              );
            } else {
              if (record.orderStatus === "Pending") {
                return <TextField value={`Không có`}></TextField>;
              } else {
                return <></>;
              }
            }
          }}
        />
        <Table.Column<IProduct>
          dataIndex="products_actions"
          render={(_, record) => {
            const items: MenuProps["items"] = [
              {
                key: "1",
                label: (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      resource="orderProducts"
                      recordItemId={record._id}
                    />
                    Cập nhập
                  </Space>
                ),
              },
            ];

            return (
              <Dropdown menu={{ items }} trigger={["click"]}>
                <MoreOutlined
                  style={{
                    fontSize: 24,
                  }}
                />
              </Dropdown>
            );
          }}
        />
      </Table>
    </>
  );
};

const expandedRowRender = (record: IClient) => {
  return <ClientOrdersTable record={record} />;
};
