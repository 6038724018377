import { useTranslate } from "@refinedev/core";

import { Create, SaveButton, getValueFromEvent } from "@refinedev/antd";

import { DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  ButtonProps,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Grid,
  Input,
  Popconfirm,
  Radio,
  Space,
  Typography,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { useState } from "react";
import { storageRef } from "../../utility/firebaseClient";

const { Text } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type CreateBrandProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const BrandCreateDrawer: React.FC<CreateBrandProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [isByClipboard, setIsByClipboard] = useState(true);

  const images: {
    name: string;
    status: string;
    type: string | undefined;
    uid: string;
    url: string;
    thumbUrl: string;
  }[] = [];
  const handleClickPaste = async () => {
    try {
      const read = await navigator.clipboard.read();
      const foundImageMimeType = read[0].types.find((val) =>
        val.startsWith("image/png")
      );
      if (foundImageMimeType) {
        const blob = await read[0].getType(foundImageMimeType);
        var fileName = formProps.form?.getFieldValue("name") + ".png";
        var file = new File([blob], fileName, {
          type: foundImageMimeType,
        });
        var preview = await getBase64(file as RcFile);

        formProps.form?.setFieldsValue({
          cover: [
            {
              name: file.name,
              status: "done",
              type: file.type,
              originFileObj: file,
              url: preview,
              thumbUrl: preview,
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
    await navigator.clipboard.writeText("");
    setIsByClipboard(true);
  };

  const confirm = (e?: React.MouseEvent<HTMLElement>) => {
    setIsByClipboard(true);
  };

  const cancel = (e?: React.MouseEvent<HTMLElement>) => {
    setIsByClipboard(false);
  };

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      zIndex={1001}
      footer={[
        <SaveButton
          {...saveButtonProps}
          onClick={async () => {
            const values = formProps.form?.getFieldsValue(true);
            const { cover, name } = values;
            if (cover !== undefined) {
              await Promise.all(
                cover.map(async (file: UploadFile) => {
                  const fileName = `media/brand/${Date.now()}-${name}-${
                    file.name
                  }`;
                  const fileRef = storageRef.child(fileName);
                  try {
                    if (file.originFileObj) {
                      const designFile = await fileRef.put(file.originFileObj);
                      const downloadUrl = await designFile.ref.getDownloadURL();

                      images.push({
                        name: file.name,
                        status: "done",
                        type: file.type,
                        uid: file.uid,
                        url: downloadUrl,
                        thumbUrl: downloadUrl,
                      });
                    }
                  } catch (e) {
                    console.log(e);
                  }
                })
              );
            } else {
              images.push({
                name: "defaut.png",
                status: "done",
                type: "image/png",
                uid: "__AUTO__1690301674242_0__",
                url: "https://firebasestorage.googleapis.com/v0/b/shemedia-fb24c.appspot.com/o/media%2Fbrand%2F1690301684197-Chanel-Dior.png?alt=media&token=f6fe80dd-60d2-48e7-9fe3-897c7419badd",
                thumbUrl:
                  "https://firebasestorage.googleapis.com/v0/b/shemedia-fb24c.appspot.com/o/media%2Fbrand%2F1690301684197-Chanel-Dior.png?alt=media&token=f6fe80dd-60d2-48e7-9fe3-897c7419badd",
              });
            }

            return (
              formProps.onFinish &&
              formProps.onFinish({
                ...values,
                cover: images,
              })
            );
          }}
        />,
      ]}
    >
      <Create
        resource="brands"
        saveButtonProps={saveButtonProps}
        goBack={false}
        footerButtons={({ defaultButtons }) => <></>}
        contentProps={{
          style: {
            boxShadow: "none",
          },
          bodyStyle: {
            padding: 0,
          },
        }}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item
            label={t("brands.fields.name")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Space direction="vertical">
            <Space>
              <Text>Logo</Text>
              <Popconfirm
                title="Phương thức chèn ảnh"
                description="Chèn bằng copy/paste ?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Có"
                cancelText="Không"
              >
                <Button
                  shape="circle"
                  size="small"
                  icon={<QuestionCircleOutlined />}
                ></Button>
              </Popconfirm>
            </Space>

            {isByClipboard ? (
              <Space>
                <Form.Item
                  name="cover"
                  valuePropName="fileList"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                >
                  <Upload
                    name="file"
                    listType="picture-card"
                    maxCount={1}
                    beforeUpload={() => false}
                  ></Upload>
                </Form.Item>
                <Button
                  type="dashed"
                  style={{
                    width: "200px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                  onClick={handleClickPaste}
                  size="large"
                >
                  <Space direction="vertical">
                    <DownloadOutlined />
                    <p className="ant-upload-text">Copy/Past ảnh mới</p>
                  </Space>
                </Button>
              </Space>
            ) : (
              <Form.Item
                name="cover"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                noStyle
              >
                <Upload
                  name="file"
                  listType="picture-card"
                  maxCount={1}
                  fileList={fileList}
                  beforeUpload={() => false}
                >
                  <Space direction="vertical">
                    <DownloadOutlined />
                    <p className="ant-upload-text">Chọn ảnh mới</p>
                  </Space>
                </Upload>
              </Form.Item>
            )}
          </Space>

          <Form.Item label={t("brands.fields.isActive")} name="isActive">
            <Radio.Group>
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
