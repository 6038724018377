import { LiveEvent, LiveProvider } from "@refinedev/core";
import { Types } from "ably";
import Ably from "ably/promises";
interface MessageType extends Types.Message {
    data: LiveEvent;
}

const liveProvider = (client: Ably.Realtime): LiveProvider => {
    return {
        subscribe: ({ channel, types, params, callback }) => {
            const channelInstance = client.channels.get(channel);

            const listener = function (message: MessageType) {
                if (types.includes("*") || types.includes(message.data.type)) {
                    if (
                        message.data.type !== "created" &&
                        params?.ids !== undefined &&
                        message.data?.payload?.ids !== undefined
                    ) {
                        if (
                            params.ids
                                .map(String)
                                .filter((value) =>
                                    message.data.payload.ids
                                        ?.map(String)
                                        .includes(value)
                                ).length > 0
                        ) {
                            callback(message.data as LiveEvent);
                        }
                    } else {
                        callback(message.data);
                    }
                }
            };
            channelInstance.subscribe(listener);

            return { channelInstance, listener };
        },

        unsubscribe: (payload: {
            channelInstance: Types.RealtimeChannelPromise;
            listener: () => void;
        }) => {
            const { channelInstance, listener } = payload;
            channelInstance.unsubscribe(listener);
        },

        publish: (event: LiveEvent) => {
            var newEvent = {
                ...event,
                payload: {
                    ids: event.payload.ids,
                    message: GetMessage(event),
                },
            };

            const channelInstance = client.channels.get(event.channel);
            //   const historic = await channelInstance.history();
            //   console.log(historic);
            channelInstance.publish(event.type, newEvent);
        },
    };
};

const GetMessage = (event: LiveEvent) => {
    var source = event.channel;
    var message = event.type;
    if (event.channel.includes("brands")) {
        source = "Nhãn hàng";
    }
    if (event.type.includes("created")) {
        message = "vừa được tạo thêm";
    } else if (event.type.includes("deleted")) {
        message = "vừa xóa bớt đi";
    } else if (event.type.includes("updated")) {
        message = "vừa được cập nhập";
    }
    return `${source} ${message}`;
};
export { liveProvider, Ably };
