import { BaseKey, useTranslate } from "@refinedev/core";

import { Edit, SaveButton } from "@refinedev/antd";

import {
  ButtonProps,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Grid,
  Input,
} from "antd";
import bcrypt from "bcryptjs";

type EditProductProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
  editId?: BaseKey;
};
function hashPassword(password: string): string {
  var salt = bcrypt.genSaltSync(10);
  const hashedPassword = bcrypt.hashSync(password, salt);
  return hashedPassword;
}
export const UserInformationAndEdit: React.FC<EditProductProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
  editId,
}) => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      zIndex={1001}
      footer={[
        <SaveButton
          {...saveButtonProps}
          onClick={() => {
            const values = formProps.form?.getFieldsValue(true);

            var isChangedPassword =
              values.password !== formProps.initialValues?.password;
            return (
              formProps.onFinish &&
              formProps.onFinish({
                ...values,
                permissions: formProps.initialValues?.permissions,
                password: isChangedPassword
                  ? hashPassword(values.password)
                  : values.password,
                avatar:
                  "https://firebasestorage.googleapis.com/v0/b/shemedia-fb24c.appspot.com/o/media%2Fcommon%2Fuser-avatar.jpg?alt=media&token=b7bdf75e-5ac0-4553-8904-2ed2ad3fea09",
              })
            );
          }}
        />,
      ]}
    >
      <Edit
        // saveButtonProps={saveButtonProps}
        resource="users"
        recordItemId={editId}
        contentProps={{
          style: {
            boxShadow: "none",
          },
          bodyStyle: {
            padding: 0,
          },
        }}
        breadcrumb={null}
        footerButtons={({ defaultButtons }) => <></>}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            ...formProps.initialValues,
          }}
        >
          <Form.Item
            label={t("users.fields.name")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("users.fields.username")}
            name="username"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={`${t("users.fields.password")} (Đã mã hóa)`}
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label={t("users.fields.description")} name="description">
            <Input />
          </Form.Item>
        </Form>
      </Edit>
    </Drawer>
  );
};
