// import { IResourceComponentsProps } from "@refinedev/core";
// import { AntdEditInferencer } from "@refinedev/inferencer/antd";

// export const BrandEdit: React.FC<IResourceComponentsProps> = () => {
//     return <AntdEditInferencer />;
// };
import dayjs from "dayjs";

import {
  HttpError,
  IResourceComponentsProps,
  useList,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import {
  Create,
  CreateButton,
  TextField,
  useDrawerForm,
  useForm,
} from "@refinedev/antd";

import {
  Alert,
  Avatar,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Row,
  Select,
  Space,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ClientCreateDrawer } from "components/client";
import { ProductCreateDrawer } from "components/product";
import { StoreCreateDrawer } from "components/store";
import {
  IClient,
  IOrderProduct,
  IOrderProductItem,
  IProduct,
  IProductStore,
  IStore,
} from "interfaces";
import React, { useState } from "react";
const { Option } = Select;

export const OrderProductCreateV2: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [isFailedOnMutation, setIsFailedOnMutation] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const { mutate } = useUpdate();
  const { formProps, saveButtonProps, queryResult } = useForm<IOrderProduct>({
    onMutationSuccess: (data, variables, context) => {
      console.log({ data, variables, context });
      const orderProductItems = data.data;
      orderProductItems.productsToUpdate.forEach((element) => {
        mutate({
          resource: "products",
          id: element.product,
          values: {
            priceImport: element.priceImport,
            priceSell: element.priceSell,
            productStatus: element.productStatus,
            qtyOnOrder: element.qtyOnOrder,
            qtyOnTransport: element.qtyOnTransport,
            qtyInStocks: element.qtyInStocks,
            stocks: element.stocks,
          },
        });
      });
    },
    onMutationError: (data, variables, context) => {
      console.log({ data, variables, context });
      // "{\"message\":\"SL SP không đủ để giao !\",\"name\":\"Error\"}
      // {"message":"SL SP không đủ để giao !","name":"Error"}
      // {"message":"{\"errors\":[{\"product\":\"Nước Hoa Chanel N5\",\"store\":\"646d18fd60a13a464809f02a\",\"message\":\"Tồn kho âm !\"}]}","name":"Error"}
      const error = data.response?.data?.error;
      console.log(error);
      const errorJson = JSON.parse(error);
      const errorDetail = JSON.parse(errorJson.message);
      setIsFailedOnMutation(true);
      setErrorMessages(errorDetail.errors);
      console.log(errorDetail);
    },
  });

  const { data: ProductData, isLoading: productDataIsLoading } = useList<
    IProduct,
    HttpError
  >({
    resource: "products",
    liveMode: "auto",
  });
  const productList = ProductData?.data ?? [];

  // const { options: storeSelectProps } = useSelect<IStore>({
  //   resource: "stores",
  //   optionLabel: "name",
  //   optionValue: "_id",
  // });
  const { data: clientData, isLoading: clientDataIsLoading } = useList<
    IClient,
    HttpError
  >({
    resource: "clients",
  });
  const clientList = clientData?.data ?? [];

  const { data: storeData, isLoading: storeDataIsLoading } = useList<
    IStore,
    HttpError
  >({
    resource: "stores",
  });
  const storeList = storeData?.data ?? [];

  const [selectedCustomer, setSelectedCustomer] = useState();

  const onChangeCustomer = (id: string) => {
    setSelectedCustomer(formProps.form?.getFieldValue("client"));

    formProps.form?.setFieldsValue({
      shipping: {
        address: clientList.find((x) => x._id === id)?.address,
      },
    });
  };

  const handleOnFinish = async (values: any) => {
    var orderPayment = values.payment;
    if (orderPayment.debt < 0) {
      orderPayment.status = "Refund";
    } else if (orderPayment.debt > 0) {
      orderPayment.status = "Debt";
    } else {
      orderPayment.status = "Done";
    }

    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        events: [
          {
            status: values.orderStatus,
            dateTime: new Date().toJSON(),
          },
        ],
        payment: orderPayment,
      })
    );
  };
  const handleChange = (x: any) => {
    const total =
      formProps.form
        ?.getFieldValue("products")
        ?.reduce((accumulator: number, currentValue: IOrderProductItem) => {
          if (
            currentValue !== undefined &&
            currentValue.unitPrice !== undefined &&
            currentValue.transportFee !== undefined &&
            currentValue.qtyOrder !== undefined
          ) {
            return (
              accumulator +
              (currentValue.unitPrice + currentValue.transportFee) *
                currentValue.qtyOrder
            );
          } else {
            return accumulator;
          }
        }, 0) ?? 0;

    const payment = formProps.form?.getFieldValue("payment");
    formProps.form?.setFieldsValue({
      payment: {
        total: total,
        debt: total - payment.paid,
      },
    });
  };

  const {
    drawerProps: createProductDrawerProps,
    formProps: createProductFormProps,
    saveButtonProps: createProductSaveButtonProps,
    show: createProductShow,
  } = useDrawerForm<IProduct>({
    action: "create",
    resource: "products",
    redirect: false,
  });
  const {
    drawerProps: createClientDrawerProps,
    formProps: createClientFormProps,
    saveButtonProps: createClientSaveButtonProps,
    show: createClientShow,
  } = useDrawerForm<IClient>({
    action: "create",
    resource: "clients",
    redirect: false,
  });

  const {
    drawerProps: createStoreDrawerProps,
    formProps: createStoreFormProps,
    saveButtonProps: createStoreSaveButtonProps,
    show: createStoreShow,
  } = useDrawerForm<IStore>({
    action: "create",
    resource: "stores",
    redirect: false,
  });
  return (
    <>
      <Create
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            orderDate: dayjs(new Date()),
            events: [],
          }}
          onFinish={handleOnFinish}
          // onValuesChange={handleChange}
          scrollToFirstError
        >
          <Form.Item
            label={t("orderProducts.fields.orderDate")}
            name="orderDate"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập trạng thái!",
              },
            ]}
          >
            <DatePicker showTime placeholder="Chọn Ngày ..." />
          </Form.Item>

          <Form.Item
            label={t("orderProducts.fields.client")}
            // extra="We must make sure that your are a human."
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="client"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn Khách Hàng!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    // style={{ width: "300px" }}
                    allowClear
                    placeholder="Chọn Khách Hàng ..."
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={onChangeCustomer}
                  >
                    {!clientDataIsLoading &&
                      clientList.map((client) => {
                        return (
                          <Option
                            key={client._id}
                            value={client._id}
                            label={client.name}
                          >
                            <Space key={uuidv4()}>
                              <Descriptions size="small">
                                <Descriptions.Item
                                  label={t("clients.fields.name")}
                                  span={3}
                                >
                                  {client?.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("clients.fields.networkContact")}
                                  span={3}
                                >
                                  {client?.networkContact}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("clients.fields.phone")}
                                  span={3}
                                >
                                  {client?.phone}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("clients.fields.address")}
                                  span={3}
                                >
                                  {client?.address}
                                </Descriptions.Item>
                              </Descriptions>
                            </Space>
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <CreateButton onClick={() => createClientShow()}>
                  Thêm Khách Hàng Mới
                </CreateButton>
              </Col>
            </Row>
          </Form.Item>

          {selectedCustomer !== undefined && (
            <>
              <Descriptions column={1}>
                <Descriptions.Item label="Khách hàng">
                  {clientList.find((x) => x._id === selectedCustomer)?.name}
                </Descriptions.Item>
                <Descriptions.Item label="SĐT">
                  {clientList.find((x) => x._id === selectedCustomer)?.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Link FB/Zalo">
                  {
                    clientList.find((x) => x._id === selectedCustomer)
                      ?.networkContact
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Địa Chỉ">
                  {clientList.find((x) => x._id === selectedCustomer)?.address}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          <Form.Item
            name="orderType"
            label="Loại đơn"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập loại đơn!",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "70%" }}
              placeholder="Chọn loại đơn..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                {
                  value: "WholeSale",
                  label: "Đơn sỉ",
                },
                {
                  value: "RetailSale",
                  label: "Đơn lẻ",
                },
                {
                  value: "ECSale",
                  label: "Đơn TMĐT",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label={t("orderProducts.fields.description")}
            name="description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input placeholder="Ghi Chú..." />
          </Form.Item>
          <Form.Item
            label={t("orderProducts.fields.store")}
            // extra="We must make sure that your are a human."
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="store"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          (getFieldValue("orderStatus") === "Ready" ||
                            getFieldValue("orderStatus") === "OnTheWay" ||
                            getFieldValue("orderStatus") === "Delivered" ||
                            getFieldValue("orderStatus") === "Cancalled") &&
                          value === undefined
                        ) {
                          return Promise.reject(
                            new Error("Chưa chọn Kho Hàng !")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn Kho Hàng ..."
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {!storeDataIsLoading &&
                      storeList.map((store) => {
                        return (
                          <Option
                            key={store._id}
                            value={store._id}
                            label={store.name}
                          >
                            <Space key={uuidv4()}>
                              <Descriptions size="small">
                                <Descriptions.Item
                                  label={t("stores.fields.name")}
                                  span={3}
                                >
                                  {store?.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("stores.fields.address")}
                                  span={3}
                                >
                                  {store?.address}
                                </Descriptions.Item>
                              </Descriptions>
                            </Space>
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <CreateButton onClick={() => createStoreShow()}>
                  Thêm Kho Hàng Mới
                </CreateButton>
              </Col>
            </Row>
          </Form.Item>

          <Divider orientation="left">Danh Sách Đơn Hàng</Divider>
          <Form.List
            name="products"
            rules={[
              {
                validator: async (_, products) => {
                  // console.log(products);
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map((field) => {
                    var selectedProduct: IProduct | undefined;
                    if (
                      formProps.form?.getFieldValue("products").length > 0 &&
                      formProps.form?.getFieldValue("products")[field.key] !==
                        undefined
                    ) {
                      selectedProduct = productList.find(
                        (x) =>
                          x._id ===
                          formProps.form?.getFieldValue("products")[field.key]
                            .product
                      );
                    }

                    return (
                      <Space key={field.key} align="baseline">
                        <Popover
                          key={uuidv4()}
                          style={{ width: "200px" }}
                          content={
                            selectedProduct !== undefined && (
                              <Space key={uuidv4()}>
                                <Avatar
                                  size={100}
                                  src={selectedProduct?.cover?.[0].url}
                                ></Avatar>
                                <Descriptions
                                  size="small"
                                  style={{
                                    width: "300px",
                                  }}
                                >
                                  <Descriptions.Item
                                    label={t("products.fields.name")}
                                    span={3}
                                  >
                                    {selectedProduct?.name}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={t("products.fields.priceSell")}
                                    span={3}
                                  >
                                    {selectedProduct?.priceSell}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={t("products.fields.qtyOnOrder")}
                                    span={3}
                                  >
                                    {selectedProduct?.qtyOnOrder}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={t("products.fields.qtyOnTransport")}
                                    span={3}
                                  >
                                    {selectedProduct?.qtyOnTransport}
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label={t("products.fields.stocks")}
                                    span={3}
                                  >
                                    <Space direction="vertical">
                                      {selectedProduct !== undefined &&
                                        selectedProduct.stocks.map(
                                          (stockStore: IProductStore) => {
                                            var detailStock = storeList.find(
                                              (x) => x._id === stockStore.store
                                            );

                                            if (detailStock !== undefined) {
                                              return (
                                                <Space key={stockStore.store}>
                                                  <TextField
                                                    strong
                                                    value={detailStock?.name}
                                                  />
                                                  :
                                                  <TextField
                                                    strong
                                                    value={stockStore?.stock}
                                                  />
                                                </Space>
                                              );
                                            }
                                            return <></>;
                                          }
                                        )}
                                    </Space>
                                  </Descriptions.Item>
                                </Descriptions>
                              </Space>
                            )
                          }
                          // title={t("orderProducts.fields.products.label")}
                          trigger="hover"
                        >
                          <Form.Item
                            style={{
                              width: "100%",

                              marginBottom: -10,
                            }}
                            shouldUpdate
                          >
                            {() => (
                              <Form.Item
                                {...field}
                                label={t(
                                  "importProducts.fields.products.product"
                                )}
                                name={[field.name, "product"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Chưa chọn sản phẩm !",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        getFieldValue("products").filter(
                                          (x: { product: any }) =>
                                            x.product === value
                                        ).length > 1
                                      ) {
                                        return Promise.reject(
                                          new Error("Đã tồn tại sản phẩm !")
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <Select
                                  showSearch
                                  style={{
                                    width: "300px",
                                  }}
                                  allowClear
                                  placeholder="Chọn Sản Phẩm Cần Nhập ..."
                                  optionLabelProp="label"
                                  filterOption={(input, option) =>
                                    (option?.label?.toString() ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  {!productDataIsLoading &&
                                    productList.map((product) => {
                                      return (
                                        <Option
                                          key={product._id}
                                          value={product._id}
                                          label={product.name}
                                        >
                                          <Space key={uuidv4()}>
                                            <Avatar
                                              size={100}
                                              src={product?.cover?.[0].url}
                                            ></Avatar>
                                            <Descriptions size="small">
                                              <Descriptions.Item
                                                label={t(
                                                  "products.fields.name"
                                                )}
                                                span={3}
                                              >
                                                {product?.name}
                                              </Descriptions.Item>
                                              <Descriptions.Item
                                                label={t(
                                                  "products.fields.priceSell"
                                                )}
                                                span={3}
                                              >
                                                {product?.priceSell}
                                              </Descriptions.Item>
                                              <Descriptions.Item
                                                label={t(
                                                  "products.fields.qtyOnOrder"
                                                )}
                                                span={3}
                                              >
                                                {product?.qtyOnOrder}
                                              </Descriptions.Item>
                                              <Descriptions.Item
                                                label={t(
                                                  "products.fields.qtyOnTransport"
                                                )}
                                                span={3}
                                              >
                                                {product?.qtyOnTransport}
                                              </Descriptions.Item>
                                            </Descriptions>
                                          </Space>
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Popover>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.products !== currentValues.products
                          }
                        >
                          {() =>
                            selectedProduct !== undefined ? (
                              <Form.Item
                                key={uuidv4()}
                                style={{
                                  width: "100px",
                                  marginBottom: -8,
                                }}
                                label={
                                  // field.key === 0 &&
                                  t("orderProducts.fields.products.qtyOrder")
                                }
                                name={[field.name, "qtyOrder"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Chưa nhập số lượng mua !",
                                  },
                                  () => ({
                                    validator(_, value) {
                                      if (value < 0) {
                                        return Promise.reject(
                                          new Error("Phải lớn hơn 0 !")
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                                initialValue={0}
                              >
                                <InputNumber
                                  style={{
                                    width: "100px",
                                  }}
                                  min={0}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>

                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.products !== currentValues.products
                          }
                        >
                          {() =>
                            selectedProduct !== undefined ? (
                              <Form.Item
                                key={uuidv4()}
                                style={{ width: "150px" }}
                                label={
                                  // field.key === 0 &&
                                  t("orderProducts.fields.products.unitPrice")
                                }
                                name={[field.name, "unitPrice"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Chưa nhập giá bán !",
                                  },
                                  () => ({
                                    validator(_, value) {
                                      if (value < 0) {
                                        return Promise.reject(
                                          new Error("Phải lớn hơn 0 !")
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                                initialValue={selectedProduct.priceSell}
                              >
                                <InputNumber
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  style={{
                                    width: "150px",
                                  }}
                                  min={0}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.products !== currentValues.products
                          }
                        >
                          {() =>
                            selectedProduct !== undefined ? (
                              <Form.Item
                                key={uuidv4()}
                                style={{ width: "150px" }}
                                label={
                                  // field.key === 0 &&
                                  t(
                                    "orderProducts.fields.products.transportFee"
                                  )
                                }
                                name={[field.name, "transportFee"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Chưa nhập giá vận chuyển",
                                  },
                                  () => ({
                                    validator(_, value) {
                                      if (value < 0) {
                                        return Promise.reject(
                                          new Error("Phải lớn hơn 0 !")
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                                initialValue={0}
                              >
                                <InputNumber
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  style={{
                                    width: "150px",
                                  }}
                                  min={0}
                                />
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    );
                  })}
                  <Form.Item style={{ width: "300px" }}>
                    <Space direction="horizontal">
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Thêm SP vào giỏ
                      </Button>
                      <CreateButton onClick={() => createProductShow()}>
                        Sản Phẩm Mới ?
                      </CreateButton>
                    </Space>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          <Divider orientation="left">
            {t("orderProducts.fields.payment.label")}
          </Divider>
          <Form.Item
            label={t("orderProducts.fields.payment.total")}
            name={["payment", "total"]}
            initialValue={0}
          >
            <InputNumber
              readOnly
              style={{ width: "300px" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonAfter="VNĐ"
            />
          </Form.Item>
          <Form.Item
            label={t("orderProducts.fields.payment.paid")}
            name={["payment", "paid"]}
            initialValue={0}
            rules={[
              { required: true, message: "Missing price" },
              () => ({
                validator(_, value) {
                  if (value < 0) {
                    return Promise.reject(new Error("Phải lớn hơn 0 !"));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonAfter="VNĐ"
              style={{ width: "300px" }}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={t("orderProducts.fields.payment.debt")}
            name={["payment", "debt"]}
            initialValue={0}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonAfter="VNĐ"
              readOnly
              style={{ width: "300px" }}
            />
          </Form.Item>

          <Divider orientation="left">Thông Tin Vận Chuyển</Divider>
          <Form.Item
            label={t("orderProducts.fields.shipping.address")}
            name={["shipping", "address"]}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("orderProducts.fields.shipping.note")}
            name={["shipping", "note"]}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Divider orientation="left">Trạng thái đơn hàng</Divider>
          <Form.Item
            name="orderStatus"
            label="Trạng thái"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập trạng thái!",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  var storeOfOrder = getFieldValue("store");
                  // Check giỏ hàng
                  var orderItems = getFieldValue("products");
                  if (orderItems === undefined) {
                    return Promise.reject(new Error("Chưa có sản phẩm nào !"));
                  }
                  if (
                    value === "Ready" ||
                    value === "OnTheWay" ||
                    value === "Delivered" ||
                    value === "Done" ||
                    value === "Cancalled"
                  ) {
                    if (storeOfOrder === undefined) {
                      return Promise.reject(new Error("Chưa chọn Kho Hàng !"));
                    } else {
                      if (value === "Done") {
                        if (getFieldValue("payment").debt !== 0) {
                          return Promise.reject(
                            new Error(
                              `Không thể chuyển sang trạng thái "Hoàn Tất" vì chưa thanh toán xong !`
                            )
                          );
                        }
                      }
                      return Promise.resolve();
                      // console.log(getFieldValue("products"));
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Select
              showSearch
              // onChange={handleChangeOrderStatus}
              style={{ width: "70%" }}
              placeholder="Chọn trạng thái..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                {
                  value: "Pending",
                  label: t("enum.orderStatuses.Pending"),
                },
                {
                  value: "Ready",
                  label: t("enum.orderStatuses.Ready"),
                },
                {
                  value: "OnTheWay",
                  label: t("enum.orderStatuses.OnTheWay"),
                },
                {
                  value: "Delivered",
                  label: t("enum.orderStatuses.Delivered"),
                },
                {
                  value: "Done",
                  label: t("enum.orderStatuses.Done"),
                },
                {
                  value: "Cancelled",
                  label: t("enum.orderStatuses.Cancelled"),
                },
              ]}
            />
          </Form.Item>
          {/* <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.orderStatus !== currentValues.orderStatus
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("orderStatus") !== "Pending" &&
              getFieldValue("orderStatus") !== undefined ? (
                <Form.Item
                  label={t("orderProducts.fields.store")}
                  name="store"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          (getFieldValue("orderStatus") === "Ready" ||
                            getFieldValue("orderStatus") === "OnTheWay" ||
                            getFieldValue("orderStatus") === "Delivered" ||
                            getFieldValue("orderStatus") === "Cancalled") &&
                          value === undefined
                        ) {
                          return Promise.reject(
                            new Error("Chưa chọn Kho Hàng !")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "300px" }}
                    allowClear
                    placeholder="Chọn Kho Hàng ..."
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {!storeDataIsLoading &&
                      storeList.map((store) => {
                        return (
                          <Option
                            key={store._id}
                            value={store._id}
                            label={store.name}
                          >
                            <Space key={uuidv4()}>
                              <Descriptions size="small">
                                <Descriptions.Item
                                  label={t("stores.fields.name")}
                                  span={3}
                                >
                                  {store?.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("stores.fields.address")}
                                  span={3}
                                >
                                  {store?.address}
                                </Descriptions.Item>
                              </Descriptions>
                            </Space>
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              ) : null
            }
          </Form.Item> */}
        </Form>
        <ProductCreateDrawer
          drawerProps={createProductDrawerProps}
          formProps={createProductFormProps}
          saveButtonProps={createProductSaveButtonProps}
        />
        <ClientCreateDrawer
          drawerProps={createClientDrawerProps}
          formProps={createClientFormProps}
          saveButtonProps={createClientSaveButtonProps}
        />
        <StoreCreateDrawer
          drawerProps={createStoreDrawerProps}
          formProps={createStoreFormProps}
          saveButtonProps={createStoreSaveButtonProps}
        />
        {isFailedOnMutation === true &&
          errorMessages.map((x: any) => {
            var message = `${x.message} (${
              storeList.find((store) => store._id === x.store)?.name ?? x.store
            })!`;
            return (
              <Alert
                key={uuidv4()}
                message={message}
                type="warning"
                style={{ marginBottom: 20 }}
              />
            );
          })}
      </Create>
    </>
  );
};
