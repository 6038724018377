import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, Admin, productmanagement, (list)|(create)|(edit)|(show)|(delete)
p, Admin, storemanagement, (list)|(create)|(edit)|(show)|(delete)
p, Admin, dashboard, (list)|(create)|(edit)|(show)|(delete)
p, Admin, customermanagement, (list)|(create)|(edit)|(show)|(delete)
p, Admin, storeaction, (list)|(create)|(edit)|(show)|(delete)
p, Admin, administration, (list)|(create)|(edit)|(show)|(delete)
p, Admin, products, (list)|(create)|(edit)|(show)|(delete)
p, Admin, products, (list)|(create)
p, Admin, products/*, (edit)|(show)
p, Admin, products/*, field
p, Admin, brands, (list)|(create)|(edit)|(show)|(delete)
p, Admin, brands, (list)|(create)
p, Admin, brands/*, (edit)|(show)|(delete)
p, Admin, categories, (list)|(create)|(edit)|(show)|(delete)
p, Admin, categories, (list)|(create)
p, Admin, categories/*, (edit)|(show)|(delete)
p, Admin, suppliers, (list)|(create)|(edit)|(show)|(delete)
p, Admin, suppliers, (list)|(create)
p, Admin, suppliers/*, (edit)|(show)|(delete)
p, Admin, clients, (list)|(create)|(edit)|(show)|(delete)
p, Admin, clients, (list)|(create)
p, Admin, clients/*, (edit)|(show)|(delete)
p, Admin, users, (list)|(create)|(edit)|(show)|(delete)
p, Admin, users, (list)|(create)
p, Admin, users/*, (edit)|(show)|(delete)
p, Admin, importproducts, (list)|(create)|(edit)|(show)|(delete)
p, Admin, importProducts, (list)|(create)
p, Admin, importProducts/*, (edit)|(show)|(delete)
p, Admin, importProducts/*, field
p, Admin, orderproducts, (list)|(create)|(edit)|(show)|(delete)
p, Admin, orderProducts, (list)|(create)
p, Admin, orderProducts/*, (edit)|(show)|(delete)
p, Admin, orderProducts/*, field
p, Admin, exportdamagedproducts, (list)|(create)|(edit)|(show)|(delete)
p, Admin, exportDamagedProducts, (list)|(create)
p, Admin, exportDamagedProducts/*, (edit)|(show)|(delete)
p, Admin, exportDamagedProducts/*, field
p, Admin, stores, (list)|(create)|(edit)|(show)|(delete)
p, Admin, stores, (list)|(create)
p, Admin, stores/*, (edit)|(show)|(delete)
p, Admin, auditlogs, (list)|(create)|(edit)|(show)|(delete)
p, Admin, products/*, field

p, Employee, productmanagement, (list)|(create)|(edit)|(show)|(delete)
p, Employee, storemanagement, (list)|(create)|(edit)|(show)|(delete)
p, Employee, dashboard, (list)|(create)|(edit)|(show)|(delete)
p, Employee, customermanagement, (list)|(create)|(edit)|(show)|(delete)
p, Employee, storeaction, (list)|(create)|(edit)|(show)|(delete)
p, Employee, products, (list)|(create)
p, Employee, products/*, (edit)|(show)
p, Employee, products/priceImport, field, deny
p, Employee, brands, (list)|(create)|(edit)|(show)|(delete)
p, Employee, brands, (list)|(create)
p, Employee, brands/*, (edit)|(show)|(delete)
p, Employee, categories, (list)|(create)|(edit)|(show)|(delete)
p, Employee, categories, (list)|(create)
p, Employee, categories/*, (edit)|(show)|(delete)
p, Employee, suppliers, (list)|(create)|(edit)|(show)|(delete)
p, Employee, suppliers, (list)|(create)
p, Employee, suppliers/*, (edit)|(show)|(delete)
p, Employee, clients, (list)|(create)|(edit)|(show)|(delete)
p, Employee, clients, (list)|(create)
p, Employee, clients/*, (edit)|(show)|(delete)
p, Employee, importproducts, (list)|(create)|(edit)|(show)|(delete)
p, Employee, importProducts, (list)|(create)
p, Employee, importProducts/*, (edit)|(show)
p, Employee, importProducts/payment, field, deny
p, Employee, orderproducts, (list)|(create)|(edit)|(show)|(delete)
p, Employee, orderProducts, (list)|(create)
p, Employee, orderProducts/*, (edit)|(show)
p, Employee, orderProducts/*, field
p, Employee, exportdamagedproducts, (list)|(create)|(edit)|(show)|(delete)
p, Employee, exportDamagedProducts, (list)|(create)
p, Employee, exportDamagedProducts/*, (edit)|(show)
p, Employee, exportDamagedProducts/totalCost, field, deny
p, Employee, stores, (list)|(create)|(edit)|(show)|(delete)
p, Employee, stores, (list)|(create)
p, Employee, stores/*, (edit)|(show)|(delete)
p, Employee, auditlogs, (create)

p, undefined, dashboard, (list)

`);
