import { useSelect, useTranslate } from "@refinedev/core";

import {
  Create,
  CreateButton,
  SaveButton,
  getValueFromEvent,
  useDrawerForm,
} from "@refinedev/antd";

import { DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  ButtonProps,
  Col,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Grid,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { BrandCreateDrawer } from "components/brand";
import { CategoryCreateDrawer } from "components/category/create";
import { IBrand, ICategory } from "interfaces";
import { useState } from "react";
import { storageRef } from "../../utility/firebaseClient";

const { Text } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type CreateProductProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const ProductCreateDrawer: React.FC<CreateProductProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();

  const { options: brandSelectProps } = useSelect<IBrand>({
    resource: "brands",
    optionLabel: "name",
    optionValue: "_id",
  });
  const { options: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",
    optionValue: "_id",
  });

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [isByClipboard, setIsByClipboard] = useState(true);

  const images: {
    name: string;
    status: string;
    type: string | undefined;
    uid: string;
    url: string;
    thumbUrl: string;
  }[] = [];
  const handleClickPaste = async () => {
    try {
      const read = await navigator.clipboard.read();
      const foundImageMimeType = read[0].types.find((val) =>
        val.startsWith("image/png")
      );
      if (foundImageMimeType) {
        const blob = await read[0].getType(foundImageMimeType);
        var fileName = formProps.form?.getFieldValue("name") + ".png";
        var file = new File([blob], fileName, {
          type: foundImageMimeType,
        });
        var preview = await getBase64(file as RcFile);

        formProps.form?.setFieldsValue({
          cover: [
            {
              name: file.name,
              status: "done",
              type: file.type,
              originFileObj: file,
              url: preview,
              thumbUrl: preview,
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
    await navigator.clipboard.writeText("");
    setIsByClipboard(true);
  };

  const confirm = (e?: React.MouseEvent<HTMLElement>) => {
    setIsByClipboard(true);
  };

  const cancel = (e?: React.MouseEvent<HTMLElement>) => {
    setIsByClipboard(false);
  };

  const {
    drawerProps: createBrandDrawerProps,
    formProps: createBrandFormProps,
    saveButtonProps: createBrandSaveButtonProps,
    show: createBrandShow,
  } = useDrawerForm<IBrand>({
    action: "create",
    resource: "brands",
    redirect: false,
  });

  const {
    drawerProps: createCategoryDrawerProps,
    formProps: createCategoryFormProps,
    saveButtonProps: createCategorySaveButtonProps,
    show: createCategoryShow,
  } = useDrawerForm<ICategory>({
    action: "create",
    resource: "categories",
    redirect: false,
  });

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      zIndex={1001}
      footer={[
        <SaveButton
          {...saveButtonProps}
          onClick={async () => {
            const values = formProps.form?.getFieldsValue(true);
            const { cover, name } = values;
            await Promise.all(
              cover.map(async (file: UploadFile) => {
                const fileName = `media/product/${Date.now()}-${name}-${
                  file.name
                }`;
                const fileRef = storageRef.child(fileName);
                try {
                  if (file.originFileObj) {
                    const designFile = await fileRef.put(file.originFileObj);
                    const downloadUrl = await designFile.ref.getDownloadURL();

                    images.push({
                      name: file.name,
                      status: "done",
                      type: file.type,
                      uid: file.uid,
                      url: downloadUrl,
                      thumbUrl: downloadUrl,
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
              })
            );

            return (
              formProps.onFinish &&
              formProps.onFinish({
                ...values,
                cover: images,
              })
            );
          }}
        />,
      ]}
    >
      <Create
        resource="products"
        saveButtonProps={saveButtonProps}
        goBack={false}
        footerButtons={({ defaultButtons }) => <></>}
        contentProps={{
          style: {
            boxShadow: "none",
          },
          bodyStyle: {
            padding: 0,
          },
        }}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item
            label={t("products.fields.name")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Space direction="vertical">
            <Space>
              <Text>Logo</Text>
              <Popconfirm
                title="Phương thức chèn ảnh"
                description="Chèn bằng copy/paste ?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Có"
                cancelText="Không"
              >
                <Button
                  shape="circle"
                  size="small"
                  icon={<QuestionCircleOutlined />}
                ></Button>
              </Popconfirm>
            </Space>

            {isByClipboard ? (
              <Space>
                <Form.Item
                  name="cover"
                  valuePropName="fileList"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Upload
                    name="file"
                    listType="picture-card"
                    maxCount={1}
                    beforeUpload={() => false}
                  ></Upload>
                </Form.Item>
                <Button
                  type="dashed"
                  style={{
                    width: "200px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                  onClick={handleClickPaste}
                  size="large"
                >
                  <Space direction="vertical">
                    <DownloadOutlined />
                    <p className="ant-upload-text">Copy/Past ảnh mới</p>
                  </Space>
                </Button>
              </Space>
            ) : (
              <Form.Item
                name="cover"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                noStyle
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Upload
                  name="file"
                  listType="picture-card"
                  maxCount={1}
                  fileList={fileList}
                  beforeUpload={() => false}
                >
                  <Space direction="vertical">
                    <DownloadOutlined />
                    <p className="ant-upload-text">Chọn ảnh mới</p>
                  </Space>
                </Upload>
              </Form.Item>
            )}
          </Space>
          <Form.Item
            label={t("products.fields.brand")}
            // extra="We must make sure that your are a human."
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  // label={t("products.fields.brand")}
                  name="brand"
                  rules={[
                    {
                      required: true,
                      message: "Chưa nhập Nhãn Hàng !",
                    },
                  ]}
                >
                  <Select
                    options={brandSelectProps}
                    allowClear
                    placeholder={t("brands.brands")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CreateButton onClick={() => createBrandShow()}>
                  Thêm Nhãn Hàng
                </CreateButton>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={t("products.fields.category")}
            // extra="We must make sure that your are a human."
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  // label={t("products.fields.category")}
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Chưa nhập Loại Hàng !",
                    },
                  ]}
                >
                  <Select
                    options={categorySelectProps}
                    allowClear
                    placeholder={t("categories.categories")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CreateButton onClick={() => createCategoryShow()}>
                  Thêm Loại Hàng
                </CreateButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={t("products.fields.qtyMin")}
            name="qtyMin"
            rules={[
              {
                required: false,
                message: "Chưa nhập SL tiêu chuẩn !",
              },
            ]}
          >
            <InputNumber style={{ width: "100px" }} />
          </Form.Item>
          <Form.Item
            label={t("products.fields.description")}
            name="description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={6} />
          </Form.Item>
          <Form.Item label={t("categories.fields.isActive")} name="isActive">
            <Radio.Group>
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <BrandCreateDrawer
          drawerProps={createBrandDrawerProps}
          formProps={createBrandFormProps}
          saveButtonProps={createBrandSaveButtonProps}
        />
        <CategoryCreateDrawer
          drawerProps={createCategoryDrawerProps}
          formProps={createCategoryFormProps}
          saveButtonProps={createCategorySaveButtonProps}
        />
      </Create>
    </Drawer>
  );
};
