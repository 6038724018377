import {
  DateField,
  EditButton,
  FilterDropdown,
  NumberField,
  SaveButton,
  TextField,
  useModalForm,
  useTable,
} from "@refinedev/antd";
import { HttpError, useList, useTranslate, useUpdate } from "@refinedev/core";
import {
  Alert,
  Avatar,
  Button,
  Descriptions,
  Form,
  Modal,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from "antd";

import { v4 as uuidv4 } from "uuid";

import { FilterDropdownProps } from "antd/es/table/interface";
import { OrderStatus, PaymentStatus } from "components/status";
import {
  IClient,
  IOrderProduct,
  IOrderProductItem,
  IPaymentOperation,
  IProduct,
  IStore,
} from "interfaces";
import { useState } from "react";

const { Text, Paragraph } = Typography;
const { Option } = Select;

export const RecentOrders: React.FC = () => {
  const t = useTranslate();
  const { tableProps } = useTable<IOrderProduct>({
    resource: "orderProducts",
    initialSorter: [
      {
        field: "orderDate",
        order: "desc",
      },
    ],
    initialPageSize: 5,
    permanentFilter: [
      {
        field: "orderStatus",
        operator: "eq",
        value: "Pending",
      },
    ],
    syncWithLocation: false,
  });

  const { data: storeData, isLoading: storeIsLoading } = useList<
    IStore,
    HttpError
  >({
    resource: "stores",
  });

  const storeList = storeData?.data ?? [];

  const { data: productData } = useList<IProduct, HttpError>({
    resource: "products",
    liveMode: "auto",
  });
  const productList = productData?.data ?? [];

  const { data: clientData } = useList<IClient, HttpError>({
    resource: "clients",
  });

  const clientList = clientData?.data ?? [];
  const { mutate } = useUpdate();
  const [isFailedOnMutation, setIsFailedOnMutation] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
    submit,
  } = useModalForm<IOrderProduct>({
    action: "edit",
    resource: "orderProducts",
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoSubmitClose: true,
    onMutationSuccess: (data, variables, context) => {
      console.log({ data, variables, context });
      const orderProductItems = data.data;
      orderProductItems.productsToUpdate.forEach((element) => {
        mutate({
          resource: "products",
          id: element.product,
          values: {
            priceImport: element.priceImport,
            priceSell: element.priceSell,
            productStatus: element.productStatus,
            qtyOnOrder: element.qtyOnOrder,
            qtyOnTransport: element.qtyOnTransport,
            qtyInStocks: element.qtyInStocks,
            stocks: element.stocks,
          },
        });
      });
    },
    onMutationError: (data, variables, context) => {
      const error = data.response?.data?.error;
      const errorJson = JSON.parse(error);
      const errorDetail = JSON.parse(errorJson.message);
      setIsFailedOnMutation(true);
      setErrorMessages(errorDetail.errors);
    },
  });
  return (
    <>
      <Table
        {...tableProps}
        pagination={{ ...tableProps.pagination, simple: true }}
        rowKey="_id"
      >
        <Table.Column
          align="center"
          // key="orderStatus"
          dataIndex="orderNumber"
          title="Mã Đơn"
          render={(value) => {
            return (
              <TextField
                strong
                value={`#${value.toString().padStart(4, "0")}`}
              />
            );
          }}
          width={120}
        />
        <Table.Column
          align="center"
          dataIndex="client"
          title={t("orderProducts.fields.client")}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
            >
              <Select
                showSearch
                options={clientList?.map((x) => {
                  return { label: x.name, value: x._id };
                })}
                style={{ minWidth: 250 }}
                mode="multiple"
              />
            </FilterDropdown>
          )}
          render={(value) => {
            if (clientList?.find((x) => x._id === value)) {
              return clientList?.find((x) => x._id === value)?.name || "-";
            } else {
              return "...";
            }
          }}
          width={125}
        />
        <Table.Column<IOrderProduct>
          align="center"
          // key="products"
          dataIndex="products"
          title={"SP"}
          render={(_, record) => (
            <Popover
              content={
                <Space direction="vertical">
                  {record.products.map((ordProd: IOrderProductItem) => {
                    var detailProduct = productList.find(
                      (x) => x._id === ordProd.product
                    );

                    if (detailProduct !== undefined) {
                      return (
                        <Space key={uuidv4()}>
                          <Avatar
                            key={uuidv4()}
                            size={70}
                            src={detailProduct?.cover?.[0].url}
                          ></Avatar>
                          <Descriptions
                            key={uuidv4()}
                            size="small"
                            style={{
                              width: "400px",
                            }}
                            column={2}
                          >
                            <Descriptions.Item
                              label={t("orderProducts.fields.products.product")}
                              span={1}
                            >
                              <TextField strong value={detailProduct?.name} />
                            </Descriptions.Item>
                            <Descriptions.Item label="SL Đặt Mua" span={1}>
                              <TextField value={ordProd.qtyOrder}></TextField>
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t(
                                "orderProducts.fields.products.unitPrice"
                              )}
                              span={1}
                            >
                              <NumberField
                                options={{
                                  currency: "VND",
                                  style: "currency",
                                  notation: "compact",
                                }}
                                value={ordProd.unitPrice}
                              />
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t(
                                "orderProducts.fields.products.transportFee"
                              )}
                              span={1}
                            >
                              <NumberField
                                options={{
                                  currency: "VND",
                                  style: "currency",
                                  notation: "compact",
                                }}
                                value={ordProd.transportFee}
                              />
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>
                      );
                    }
                    return <></>;
                  })}
                </Space>
              }
              title={t("orderProducts.fields.products.label")}
              trigger="hover"
            >
              <Space direction="vertical">
                <TextField value={`${record.products.length} SP`}></TextField>
              </Space>
            </Popover>
          )}
        />
        <Table.Column<IOrderProduct>
          align="center"
          // key="products"
          dataIndex={["payment", "status"]}
          title="Thanh Toán"
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
            >
              <Select
                options={[
                  {
                    value: "Done",
                    label: t("enum.paymentStatuses.Done"),
                  },
                  {
                    value: "Debt",
                    label: t("enum.paymentStatuses.Debt"),
                  },
                  {
                    value: "Refund",
                    label: t("enum.paymentStatuses.Refund"),
                  },
                ]}
                style={{ minWidth: 250 }}
                mode="multiple"
              />
            </FilterDropdown>
          )}
          render={(_, record) => (
            <Popover
              content={
                <Space direction="vertical">
                  <Descriptions
                    key={uuidv4()}
                    size="small"
                    style={{
                      width: "400px",
                    }}
                    column={2}
                  >
                    <Descriptions.Item
                      label={t("orderProducts.fields.payment.total")}
                      span={1}
                    >
                      <NumberField
                        options={{
                          currency: "VND",
                          style: "currency",
                          notation: "compact",
                        }}
                        value={record.payment.total}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t("orderProducts.fields.payment.paid")}
                      span={1}
                    >
                      <NumberField
                        options={{
                          currency: "VND",
                          style: "currency",
                          notation: "compact",
                        }}
                        value={record.payment.paid}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t("orderProducts.fields.payment.refund")}
                      span={1}
                    >
                      <NumberField
                        options={{
                          currency: "VND",
                          style: "currency",
                          notation: "compact",
                        }}
                        value={record.payment.refund ?? 0}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t("orderProducts.fields.payment.debt")}
                      span={1}
                    >
                      <NumberField
                        options={{
                          currency: "VND",
                          style: "currency",
                          notation: "compact",
                        }}
                        value={record.payment.debt}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                  <TextField strong value="Lịch Sử"></TextField>
                  <Table dataSource={record.payment.paymentOperations ?? []}>
                    <Table.Column
                      // key="orderDate"
                      dataIndex="paymentDate"
                      title="Ngày TT"
                      render={(value) => (
                        <DateField value={value} format="DD/MM/YYYY LT" />
                      )}
                      width={120}
                    />
                    <Table.Column
                      align="center"
                      // key="orderStatus"
                      dataIndex="paymentType"
                      title="Loại"
                      render={(value) => {
                        return (
                          <TextField value={t(`enum.paymentType.${value}`)} />
                        );
                      }}
                      width={120}
                    />
                    <Table.Column<IPaymentOperation>
                      align="center"
                      // key="orderStatus"
                      dataIndex="paymentMethod"
                      title="Phương Thức"
                      render={(value) => {
                        return (
                          <TextField value={t(`enum.paymentMethod.${value}`)} />
                        );
                      }}
                      width={120}
                    />
                    <Table.Column<IPaymentOperation>
                      align="center"
                      dataIndex="amount"
                      title={t("orderProducts.fields.payment.paid")}
                      render={(value) => {
                        return (
                          <NumberField
                            options={{
                              currency: "VND",
                              style: "currency",
                              notation: "compact",
                            }}
                            value={value}
                          />
                        );
                      }}
                      sorter
                      width={125}
                    />
                  </Table>
                </Space>
              }
              title={t("orderProducts.fields.payment.label")}
              trigger="hover"
            >
              <Space>
                <PaymentStatus status={record.payment.status} />
              </Space>
            </Popover>
          )}
        />
        <Table.Column<IOrderProduct>
          align="center"
          // key="orderStatus"
          dataIndex="orderStatus"
          title="Trạng Thái"
          render={(_, record) => {
            return (
              <Button
                size="large"
                type="text"
                onClick={() => editModalShow(record._id)}
              >
                <OrderStatus status={record.orderStatus} />
              </Button>
            );
          }}
          width={100}
        />
        <Table.Column<IOrderProduct>
          align="center"
          // key="products"
          dataIndex="products"
          title="Kho Có Sẵn Hàng"
          render={(_, record) => {
            var dispoStores: string[] = [];
            storeList.forEach((store: IStore) => {
              var isReady = true;
              record.products.map((ordProd: IOrderProductItem) => {
                var detailProduct = productList.find(
                  (x) => x._id === ordProd.product && x.stocks.length > 0
                );
                if (detailProduct !== undefined) {
                  var stock = detailProduct.stocks.find(
                    (x) => x.store === store._id && x.stock > ordProd.qtyOrder
                  );
                  if (stock !== undefined) {
                    isReady = isReady && true;
                  } else {
                    isReady = isReady && false;
                  }
                } else {
                  isReady = isReady && false;
                }
              });
              if (isReady) {
                dispoStores.push(store.name);
              }
            });
            if (dispoStores.length > 0 && record.orderStatus === "Pending") {
              return (
                <Space direction="vertical">
                  {dispoStores.map((x) => {
                    return <TextField value={x}></TextField>;
                  })}
                </Space>
              );
            } else {
              if (record.orderStatus === "Pending") {
                return <TextField value={`Không có`}></TextField>;
              } else {
                return <></>;
              }
            }
          }}
        />
        <Table.Column<IOrderProduct>
          fixed="right"
          title=""
          render={(_, record) => {
            return (
              <EditButton
                hideText
                size="small"
                resource="orderProducts"
                recordItemId={record._id}
              />
            );
          }}
        />
      </Table>
      <Modal
        {...editModalProps}
        width={400}
        title="Cập nhập"
        footer={[
          <SaveButton
            {...editModalProps.okButtonProps}
            onClick={() => {
              const values = editFormProps.form?.getFieldsValue(true);
              var events = values.events;
              events.push({
                status: values.orderStatus,
                dateTime: new Date().toJSON(),
              });

              submit({
                ...values,
                events: events,
                productsToUpdate: values.products.map(
                  (x: IOrderProductItem) => x.product
                ),
              });
            }}
          />,
        ]}
      >
        <Form {...editFormProps} layout="vertical">
          <Form.Item
            name="orderStatus"
            label="Trạng thái"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập trạng thái!",
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  var storeOfOrder = getFieldValue("store");
                  // Check giỏ hàng
                  var orderItems = getFieldValue("products");
                  if (orderItems === undefined) {
                    return Promise.reject(new Error("Chưa có sản phẩm nào !"));
                  }
                  if (
                    value === "Ready" ||
                    value === "OnTheWay" ||
                    value === "Delivered" ||
                    value === "Done" ||
                    value === "Cancalled"
                  ) {
                    if (storeOfOrder === undefined) {
                      return Promise.reject(new Error("Chưa chọn Kho Hàng !"));
                    } else {
                      if (value === "Done") {
                        if (getFieldValue("payment").debt !== 0) {
                          return Promise.reject(
                            new Error(
                              `Không thể chuyển sang trạng thái "Hoàn Tất" vì chưa thanh toán xong !`
                            )
                          );
                        }
                      }
                      return Promise.resolve();
                      // console.log(getFieldValue("products"));
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Select
              showSearch
              placeholder="Chọn trạng thái..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                {
                  value: "Pending",
                  label: t("enum.orderStatuses.Pending"),
                },
                {
                  value: "Ready",
                  label: t("enum.orderStatuses.Ready"),
                },
                {
                  value: "OnTheWay",
                  label: t("enum.orderStatuses.OnTheWay"),
                },
                {
                  value: "Delivered",
                  label: t("enum.orderStatuses.Delivered"),
                },
                {
                  value: "Done",
                  label: t("enum.orderStatuses.Done"),
                },
                {
                  value: "Cancelled",
                  label: t("enum.orderStatuses.Cancelled"),
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("orderProducts.fields.store")}
            name="store"
            rules={[
              // {
              //   required: true,
              // },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    (getFieldValue("orderStatus") === "Ready" ||
                      getFieldValue("orderStatus") === "OnTheWay" ||
                      getFieldValue("orderStatus") === "Delivered" ||
                      getFieldValue("orderStatus") === "Cancalled") &&
                    value === undefined
                  ) {
                    return Promise.reject(new Error("Chưa chọn Kho Hàng !"));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Chọn Kho Hàng ..."
              optionLabelProp="label"
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {!storeIsLoading &&
                storeList.map((store) => {
                  return (
                    <Option
                      key={store._id}
                      value={store._id}
                      label={store.name}
                    >
                      <Space key={uuidv4()}>
                        <Descriptions size="small">
                          <Descriptions.Item
                            label={t("stores.fields.name")}
                            span={3}
                          >
                            {store?.name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={t("stores.fields.address")}
                            span={3}
                          >
                            {store?.address}
                          </Descriptions.Item>
                        </Descriptions>
                      </Space>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
        {isFailedOnMutation === true &&
          errorMessages.map((x: any) => {
            var message = `${x.message} (${
              storeList.find((store) => store._id === x.store)?.name ?? x.store
            })!`;
            return (
              <Alert
                key={uuidv4()}
                message={message}
                type="warning"
                style={{ marginBottom: 20 }}
              />
            );
          })}
      </Modal>
    </>
  );
};
