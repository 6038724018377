import { Line } from "@ant-design/charts";
import { TextField } from "@refinedev/antd";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Space, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";

import { ISalesChart } from "interfaces";
import {
  DailyRevenueWrapper,
  RangePicker,
  TitleAreNumber,
  TitleArea,
  TitleAreaAmount,
} from "./styled";

export const DailyRevenue: React.FC = () => {
  const t = useTranslate();
  const API_URL = useApiUrl();

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(7, "days").startOf("day"),
    dayjs().startOf("day"),
  ]);
  const [_startDate, _endDate] = dateRange;

  const query = {
    _startDate: _startDate.toISOString(),
    _endDate: _endDate.toISOString(),
  };

  const url = `${API_URL}/dailyRevenue`;
  const { data, isLoading } = useCustom<{
    data: ISalesChart[];
    total: number;
    // trend: number;
  }>({
    url,
    method: "get",
    config: {
      query,
    },
  });
  const config = useMemo(() => {
    const config = {
      data: data?.data.data || [],
      loading: isLoading,
      // padding: "auto",
      xField: "paymentDate",
      yField: "value",
      color: "rgba(255, 255, 255, 0.5)",
      tooltip: {
        customContent: (title: any, data: { value: any }[]) => {
          return `<div style="padding: 8px 4px; font-size:16px; font-weight:600">${title} - ${new Intl.NumberFormat().format(
            data[0]?.value
          )} đ</div>`;         
        },
      },

      xAxis: {
        label: null,
        line: null,
      },
      yAxis: {
        label: null,
        grid: null,
      },
      smooth: true,
      lineStyle: {
        lineWidth: 4,
      },
    };

    return config;
  }, [data]);

  const disabledDate = (date: Dayjs) => date > dayjs();

  return (
    <DailyRevenueWrapper>
      <TitleArea>
        <TitleAreaAmount>
          <Typography.Title level={3}>Doanh Thu</Typography.Title>
          <TitleAreNumber>
            <TextField
              style={{ fontSize: 20 }}
              value={`${new Intl.NumberFormat().format(
                data?.data.total ?? 0
              )}đ`}
            />            
          </TitleAreNumber>
        </TitleAreaAmount>

        <RangePicker
          size="small"
          value={dateRange}
          onChange={(values) => {
            if (values && values[0] && values[1]) {
              setDateRange([values[0], values[1]]);
            }
          }}
          disabledDate={disabledDate}
          style={{
            float: "right",
            color: "#fffff !important",
            background: "rgba(255, 255, 255, 0.3)",
          }}
          ranges={{
            "Tuần Này": [dayjs().startOf("week"), dayjs().endOf("week")],
            "Tháng Trước": [
              dayjs().startOf("month").subtract(1, "month"),
              dayjs().endOf("month").subtract(1, "month"),
            ],
            "Tháng Này": [dayjs().startOf("month"), dayjs().endOf("month")],
            "Năm Nay": [dayjs().startOf("year"), dayjs().endOf("year")],
          }}
          format="YYYY/MM/DD"
        />
      </TitleArea>
      <Line
        padding={0}
        appendPadding={10}
        height={135}
        style={{ maxHeight: "135px" }}
        {...config}
      />
    </DailyRevenueWrapper>
  );
};
