// import { IResourceComponentsProps } from "@refinedev/core";
// import { AntdEditInferencer } from "@refinedev/inferencer/antd";

// export const BrandEdit: React.FC<IResourceComponentsProps> = () => {
//     return <AntdEditInferencer />;
// };
import dayjs from "dayjs";

import {
  HttpError,
  IResourceComponentsProps,
  useList,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import {
  Create,
  CreateButton,
  DateField,
  NumberField,
  SaveButton,
  TextField,
  useDrawerForm,
  useStepsForm,
} from "@refinedev/antd";

import {
  Alert,
  Avatar,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Steps,
  Table,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ClientCreateDrawer } from "components/client";
import { ProductCreateDrawer } from "components/product";
import { StoreCreateDrawer } from "components/store";
import {
  IClient,
  IOrderProduct,
  IOrderProductItem,
  IPaymentOperation,
  IProduct,
  IStore,
} from "interfaces";
import React, { useEffect, useRef, useState } from "react";
const { Option } = Select;
const { Step } = Steps;

export const OrderProductCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [isFailedOnMutation, setIsFailedOnMutation] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const { mutate } = useUpdate();
  // const { formProps, saveButtonProps, queryResult } = useForm<IOrderProduct>({
  //   onMutationSuccess: (data, variables, context) => {
  //     console.log({ data, variables, context });
  //     const orderProductItems = data.data;
  //     orderProductItems.productsToUpdate.forEach((element) => {
  //       mutate({
  //         resource: "products",
  //         id: element.product,
  //         values: {
  //           priceImport: element.priceImport,
  //           priceSell: element.priceSell,
  //           productStatus: element.productStatus,
  //           qtyOnOrder: element.qtyOnOrder,
  //           qtyOnTransport: element.qtyOnTransport,
  //           qtyInStocks: element.qtyInStocks,
  //           stocks: element.stocks,
  //         },
  //       });
  //     });
  //   },
  //   onMutationError: (data, variables, context) => {
  //     console.log({ data, variables, context });
  //     // "{\"message\":\"SL SP không đủ để giao !\",\"name\":\"Error\"}
  //     // {"message":"SL SP không đủ để giao !","name":"Error"}
  //     // {"message":"{\"errors\":[{\"product\":\"Nước Hoa Chanel N5\",\"store\":\"646d18fd60a13a464809f02a\",\"message\":\"Tồn kho âm !\"}]}","name":"Error"}
  //     const error = data.response?.data?.error;
  //     console.log(error);
  //     const errorJson = JSON.parse(error);
  //     const errorDetail = JSON.parse(errorJson.message);
  //     setIsFailedOnMutation(true);
  //     setErrorMessages(errorDetail.errors);
  //     console.log(errorDetail);
  //   },
  // });
  const {
    current,
    gotoStep,
    stepsProps,
    formProps,
    saveButtonProps,
    onFinish,
    queryResult,
  } = useStepsForm<IOrderProduct>({
    submit: (values) => {
      const formValues = formProps.form?.getFieldsValue(true);
      var orderPayment = formValues.payment;
      orderPayment.debt =
        orderPayment.total + orderPayment.refund - orderPayment.paid;
      if (orderPayment.debt < 0) {
        orderPayment.status = "Refund";
      } else if (orderPayment.debt > 0) {
        orderPayment.status = "Debt";
      } else {
        orderPayment.status = "Done";
      }

      return (
        formProps.onFinish &&
        formProps.onFinish({
          ...formValues,
          events: [
            {
              status: formValues.orderStatus,
              dateTime: new Date().toJSON(),
            },
          ],
          payment: orderPayment,
        })
      );
    },
    onMutationSuccess: (data, variables, context) => {
      const orderProductItems = data.data;
      orderProductItems.productsToUpdate.forEach((element) => {
        mutate({
          resource: "products",
          id: element.product,
          values: {
            priceImport: element.priceImport,
            priceSell: element.priceSell,
            productStatus: element.productStatus,
            qtyOnOrder: element.qtyOnOrder,
            qtyOnTransport: element.qtyOnTransport,
            qtyInStocks: element.qtyInStocks,
            stocks: element.stocks,
          },
        });
      });
    },
    onMutationError: (data, variables, context) => {
      const error = data.response?.data?.error;
      const errorJson = JSON.parse(error);
      const errorDetail = JSON.parse(errorJson.message);
      setIsFailedOnMutation(true);
      setErrorMessages(errorDetail.errors);
    },
  });
  const { data: ProductData, isLoading: productDataIsLoading } = useList<
    IProduct,
    HttpError
  >({
    resource: "products",
    liveMode: "auto",
    pagination: {
      mode: "off",
    },
  });
  const productList = ProductData?.data ?? [];

  // const { options: storeSelectProps } = useSelect<IStore>({
  //   resource: "stores",
  //   optionLabel: "name",
  //   optionValue: "_id",
  // });
  const { data: clientData, isLoading: clientDataIsLoading } = useList<
    IClient,
    HttpError
  >({
    resource: "clients",
    pagination: {
      mode: "off",
    },
  });
  const clientList = clientData?.data ?? [];

  const { data: storeData, isLoading: storeDataIsLoading } = useList<
    IStore,
    HttpError
  >({
    resource: "stores",
    pagination: {
      mode: "off",
    },
  });
  const storeList = storeData?.data ?? [];

  const [selectedCustomer, setSelectedCustomer] = useState();

  const onChangeCustomer = (id: string) => {
    setSelectedCustomer(formProps.form?.getFieldValue("client"));

    formProps.form?.setFieldsValue({
      shipping: {
        address: clientList.find((x) => x._id === id)?.address,
      },
    });
  };

  const {
    drawerProps: createProductDrawerProps,
    formProps: createProductFormProps,
    saveButtonProps: createProductSaveButtonProps,
    show: createProductShow,
  } = useDrawerForm<IProduct>({
    action: "create",
    resource: "products",
    redirect: false,
  });
  const {
    drawerProps: createClientDrawerProps,
    formProps: createClientFormProps,
    saveButtonProps: createClientSaveButtonProps,
    show: createClientShow,
  } = useDrawerForm<IClient>({
    action: "create",
    resource: "clients",
    redirect: false,
  });

  const {
    drawerProps: createStoreDrawerProps,
    formProps: createStoreFormProps,
    saveButtonProps: createStoreSaveButtonProps,
    show: createStoreShow,
  } = useDrawerForm<IStore>({
    action: "create",
    resource: "stores",
    redirect: false,
  });

  const useResetFormOnCloseModal = ({
    form,
    open,
  }: {
    form: FormInstance;
    open: boolean;
  }) => {
    const prevOpenRef = useRef<boolean>();
    useEffect(() => {
      prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {
      if (!open && prevOpen) {
        form.resetFields();
      }
    }, [form, prevOpen, open]);
  };
  const ModalForm: React.FC<{
    open: boolean;
    onCancel: () => void;
  }> = ({ open, onCancel }) => {
    const [form] = Form.useForm();

    useResetFormOnCloseModal({
      form,
      open,
    });

    const onOk = () => {
      form.submit();
    };

    return (
      <Modal
        title="Thanh Toán Đơn Hàng"
        open={open}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form
          form={form}
          layout="vertical"
          name="paymentForm"
          initialValues={{
            paymentDate: dayjs(new Date()),
          }}
        >
          <Form.Item
            label="Ngày Thanh Toán"
            name="paymentDate"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập trạng thái!",
              },
            ]}
          >
            <DatePicker showTime placeholder="Chọn Ngày ..." />
          </Form.Item>
          <Form.Item
            name="paymentType"
            label="Loại Thanh Toán"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              // onChange={handleChangeOrderStatus}
              style={{ width: "70%" }}
              placeholder="Loại Thanh Toán..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                {
                  value: "PaymentByClient",
                  label: "Khách Thanh Toán",
                },
                {
                  value: "RefundForClient",
                  label: "Hoàn Lại Cho Khách",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="paymentMethod"
            label="Phương Thức Thanh Toán"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              // onChange={handleChangeOrderStatus}
              style={{ width: "70%" }}
              placeholder="Phương Thức Thanh Toán..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                {
                  value: "TransferViaBank",
                  label: "Chuyển Khoản",
                },
                {
                  value: "COD",
                  label: "Nhận COD",
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="amount" label="Số Tiền" rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonAfter="VNĐ"
              style={{ width: "300px" }}
              min={0}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const [open, setOpen] = useState(false);

  const showUserModal = () => {
    setOpen(true);
  };

  const hideUserModal = () => {
    setOpen(false);
  };

  const CreateOrderFormList = [
    <>
      <Form.Item
        label={t("orderProducts.fields.orderDate")}
        name="orderDate"
        rules={[
          {
            required: true,
            message: "Vui lòng nhập trạng thái!",
          },
        ]}
      >
        <DatePicker showTime placeholder="Chọn Ngày ..." />
      </Form.Item>

      <Form.Item
        label={t("orderProducts.fields.client")}
        // extra="We must make sure that your are a human."
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="client"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn Khách Hàng!",
                },
              ]}
            >
              <Select
                showSearch
                // style={{ width: "300px" }}
                allowClear
                placeholder="Chọn Khách Hàng ..."
                optionLabelProp="label"
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={onChangeCustomer}
              >
                {!clientDataIsLoading &&
                  clientList.map((client) => {
                    return (
                      <Option
                        key={client._id}
                        value={client._id}
                        label={client.name}
                      >
                        <Space key={uuidv4()}>
                          <Descriptions size="small">
                            <Descriptions.Item
                              label={t("clients.fields.name")}
                              span={3}
                            >
                              {client?.name}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t("clients.fields.networkContact")}
                              span={3}
                            >
                              {client?.networkContact}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t("clients.fields.phone")}
                              span={3}
                            >
                              {client?.phone}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t("clients.fields.address")}
                              span={3}
                            >
                              {client?.address}
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <CreateButton onClick={() => createClientShow()}>
              Thêm Khách Hàng Mới
            </CreateButton>
          </Col>
        </Row>
      </Form.Item>

      {selectedCustomer !== undefined && (
        <>
          <Descriptions column={1}>
            <Descriptions.Item label="Khách hàng">
              {clientList.find((x) => x._id === selectedCustomer)?.name}
            </Descriptions.Item>
            <Descriptions.Item label="SĐT">
              {clientList.find((x) => x._id === selectedCustomer)?.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Link FB/Zalo">
              {
                clientList.find((x) => x._id === selectedCustomer)
                  ?.networkContact
              }
            </Descriptions.Item>
            <Descriptions.Item label="Địa Chỉ">
              {clientList.find((x) => x._id === selectedCustomer)?.address}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      <Form.Item
        name="orderType"
        label="Loại đơn"
        rules={[
          {
            required: true,
            message: "Vui lòng nhập loại đơn!",
          },
        ]}
      >
        <Select
          showSearch
          style={{ width: "70%" }}
          placeholder="Chọn loại đơn..."
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          options={[
            {
              value: "WholeSale",
              label: "Đơn sỉ",
            },
            {
              value: "RetailSale",
              label: "Đơn lẻ",
            },
            {
              value: "ECSale",
              label: "Đơn TMĐT",
            },
          ]}
        />
      </Form.Item>

      <Form.Item
        label={t("orderProducts.fields.description")}
        name="description"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input placeholder="Ghi Chú..." />
      </Form.Item>
    </>,
    <>
      <Form.List
        name="products"
        rules={[
          {
            validator: async (_, products) => {
              if (products === undefined || products.length === 0) {
                return Promise.reject(new Error("Chưa có sản phẩm nào !"));
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field) => {
                // var selectedProduct: IProduct | undefined;
                // if (
                //   formProps.form?.getFieldValue("products").length > 0 &&
                //   formProps.form?.getFieldValue("products")[field.key] !==
                //     undefined
                // ) {
                //   selectedProduct = productList.find(
                //     (x) =>
                //       x._id ===
                //       formProps.form?.getFieldValue("products")[field.key]
                //         .product
                //   );
                // }

                return (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      style={{
                        width: "100%",

                        marginBottom: -10,
                      }}
                      shouldUpdate
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          label={t("importProducts.fields.products.product")}
                          name={[field.name, "product"]}
                          rules={[
                            {
                              required: true,
                              message: "Chưa chọn sản phẩm !",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  getFieldValue("products").filter(
                                    (x: { product: any }) => x.product === value
                                  ).length > 1
                                ) {
                                  return Promise.reject(
                                    new Error("Đã tồn tại sản phẩm !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Select
                            showSearch
                            style={{
                              width: "300px",
                            }}
                            allowClear
                            placeholder="Chọn Sản Phẩm ..."
                            optionLabelProp="label"
                            filterOption={(input, option) =>
                              (option?.label?.toString() ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // onChange={(value) => {
                            //   selectedProduct = productList.find(
                            //     (x) => x._id === value
                            //   );
                            // }}
                          >
                            {!productDataIsLoading &&
                              productList.map((product) => {
                                return (
                                  <Option
                                    key={product._id}
                                    value={product._id}
                                    label={`${
                                      product?.name
                                    } (${new Intl.NumberFormat().format(
                                      product?.priceSell
                                    )}đ)`}
                                  >
                                    <Space key={uuidv4()}>
                                      <Avatar
                                        size={100}
                                        src={product?.cover?.[0].url}
                                      ></Avatar>
                                      <Descriptions size="small">
                                        <Descriptions.Item
                                          label={t("products.fields.name")}
                                          span={3}
                                        >
                                          {product?.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={t("products.fields.priceSell")}
                                          span={3}
                                        >
                                          {product?.priceSell}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={t(
                                            "products.fields.qtyOnOrder"
                                          )}
                                          span={3}
                                        >
                                          {product?.qtyOnOrder}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label={t(
                                            "products.fields.qtyOnTransport"
                                          )}
                                          span={3}
                                        >
                                          {product?.qtyOnTransport}
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Space>
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>

                    <Form.Item
                      key={uuidv4()}
                      style={{
                        width: "100px",
                        marginBottom: -8,
                      }}
                      label={
                        // field.key === 0 &&
                        t("orderProducts.fields.products.qtyOrder")
                      }
                      name={[field.name, "qtyOrder"]}
                      rules={[
                        {
                          required: true,
                          message: "Chưa nhập số lượng mua !",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Phải lớn hơn 0 !")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        style={{
                          width: "100px",
                        }}
                        min={0}
                      />
                    </Form.Item>

                    <Form.Item
                      key={uuidv4()}
                      style={{ width: "150px" }}
                      label={
                        // field.key === 0 &&
                        t("orderProducts.fields.products.unitPrice")
                      }
                      name={[field.name, "unitPrice"]}
                      rules={[
                        {
                          required: true,
                          message: "Chưa nhập giá bán !",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Phải lớn hơn 0 !")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        style={{
                          width: "150px",
                        }}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      key={uuidv4()}
                      style={{ width: "150px" }}
                      label={
                        // field.key === 0 &&
                        t("orderProducts.fields.products.transportFee")
                      }
                      name={[field.name, "transportFee"]}
                      rules={[
                        {
                          required: true,
                          message: "Chưa nhập giá vận chuyển",
                        },
                        () => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(
                                new Error("Phải lớn hơn 0 !")
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        style={{
                          width: "150px",
                        }}
                        min={0}
                      />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                );
              })}
              <Form.Item style={{ width: "300px" }}>
                <Space direction="horizontal">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Thêm SP vào giỏ
                  </Button>
                  <CreateButton onClick={() => createProductShow()}>
                    Sản Phẩm Mới ?
                  </CreateButton>
                </Space>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>,
    <>
      <Divider orientation="left">Trạng thái đơn hàng</Divider>
      <Form.Item
        name="orderStatus"
        label="Trạng thái"
        rules={[
          {
            required: true,
            message: "Vui lòng nhập trạng thái!",
          },

          ({ getFieldValue }) => ({
            validator(_, value) {
              var storeOfOrder = getFieldValue("store");
              // Check giỏ hàng
              var orderItems = getFieldValue("products");
              if (orderItems === undefined) {
                return Promise.reject(new Error("Chưa có sản phẩm nào !"));
              }
              if (
                value === "Ready" ||
                value === "OnTheWay" ||
                value === "Delivered" ||
                value === "Done" ||
                value === "Cancalled"
              ) {
                if (storeOfOrder === undefined) {
                  return Promise.reject(new Error("Chưa chọn Kho Hàng !"));
                } else {
                  if (value === "Done") {
                    if (getFieldValue("payment").debt !== 0) {
                      return Promise.reject(
                        new Error(
                          `Không thể chuyển sang trạng thái "Hoàn Tất" vì chưa thanh toán xong !`
                        )
                      );
                    }
                  }
                  return Promise.resolve();
                  // console.log(getFieldValue("products"));
                }
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Select
          showSearch
          // onChange={handleChangeOrderStatus}
          style={{ width: "70%" }}
          placeholder="Chọn trạng thái..."
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          options={[
            {
              value: "Pending",
              label: t("enum.orderStatuses.Pending"),
            },
            {
              value: "Ready",
              label: t("enum.orderStatuses.Ready"),
            },
            {
              value: "OnTheWay",
              label: t("enum.orderStatuses.OnTheWay"),
            },
            {
              value: "Delivered",
              label: t("enum.orderStatuses.Delivered"),
            },
            {
              value: "Done",
              label: t("enum.orderStatuses.Done"),
            },
            {
              value: "Cancelled",
              label: t("enum.orderStatuses.Cancelled"),
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label={t("orderProducts.fields.store")}
        // extra="We must make sure that your are a human."
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="store"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      (getFieldValue("orderStatus") === "Ready" ||
                        getFieldValue("orderStatus") === "OnTheWay" ||
                        getFieldValue("orderStatus") === "Delivered" ||
                        getFieldValue("orderStatus") === "Cancalled") &&
                      value === undefined
                    ) {
                      return Promise.reject(new Error("Chưa chọn Kho Hàng !"));
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Chọn Kho Hàng ..."
                optionLabelProp="label"
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {!storeDataIsLoading &&
                  storeList.map((store) => {
                    return (
                      <Option
                        key={store._id}
                        value={store._id}
                        label={store.name}
                      >
                        <Space key={uuidv4()}>
                          <Descriptions size="small">
                            <Descriptions.Item
                              label={t("stores.fields.name")}
                              span={3}
                            >
                              {store?.name}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t("stores.fields.address")}
                              span={3}
                            >
                              {store?.address}
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <CreateButton onClick={() => createStoreShow()}>
              Thêm Kho Hàng Mới
            </CreateButton>
          </Col>
        </Row>
      </Form.Item>
      <Divider orientation="left">Thông Tin Vận Chuyển</Divider>
      <Form.Item
        label={t("orderProducts.fields.shipping.address")}
        name={["shipping", "address"]}
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("orderProducts.fields.shipping.note")}
        name={["shipping", "note"]}
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
    </>,
    <>
      <Form.Item
        label={t("orderProducts.fields.payment.total")}
        name={["payment", "total"]}
        initialValue={0}
      >
        <InputNumber
          readOnly
          style={{ width: "300px" }}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          addonAfter="VNĐ"
        />
      </Form.Item>
      <Form.Item
        label={t("orderProducts.fields.payment.paid")}
        name={["payment", "paid"]}
        initialValue={0}
        rules={[
          // { required: true, message: "Missing price" },
          () => ({
            validator(_, value) {
              if (value < 0) {
                return Promise.reject(new Error("Phải lớn hơn 0 !"));
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <InputNumber
          readOnly
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          addonAfter="VNĐ"
          style={{ width: "300px" }}
          min={0}
        />
      </Form.Item>
      <Form.Item
        label={t("orderProducts.fields.payment.refund")}
        name={["payment", "refund"]}
        initialValue={0}
        rules={[
          // { required: true, message: "Missing price" },
          () => ({
            validator(_, value) {
              if (value < 0) {
                return Promise.reject(new Error("Phải lớn hơn 0 !"));
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <InputNumber
          readOnly
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          addonAfter="VNĐ"
          style={{ width: "300px" }}
          min={0}
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="button"
          style={{ margin: "0 8px" }}
          onClick={showUserModal}
          icon={<PlusOutlined />}
        >
          Thanh Toán ?
        </Button>
      </Form.Item>
      <Divider orientation="left">Lịch Sử Thanh Toán</Divider>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.payment !== curValues.payment
        }
      >
        {({ getFieldValue }) => {
          const paymentOperations: IPaymentOperation[] =
            getFieldValue("payment").paymentOperations || [];
          return (
            <Table dataSource={paymentOperations}>
              <Table.Column
                // key="orderDate"
                dataIndex="paymentDate"
                title="Ngày TT"
                render={(value) => (
                  <DateField value={value} format="DD/MM/YYYY LT" />
                )}
                width={120}
              />
              <Table.Column
                align="center"
                // key="orderStatus"
                dataIndex="paymentType"
                title="Loại"
                render={(value) => {
                  return <TextField value={t(`enum.paymentType.${value}`)} />;
                }}
                width={120}
              />
              <Table.Column<IPaymentOperation>
                align="center"
                // key="orderStatus"
                dataIndex="paymentMethod"
                title="Phương Thức"
                render={(value) => {
                  return <TextField value={t(`enum.paymentMethod.${value}`)} />;
                }}
                width={120}
              />
              <Table.Column<IPaymentOperation>
                align="center"
                dataIndex="amount"
                title={t("orderProducts.fields.payment.paid")}
                render={(value) => {
                  return (
                    <NumberField
                      options={{
                        currency: "VND",
                        style: "currency",
                        notation: "compact",
                      }}
                      value={value}
                    />
                  );
                }}
                sorter
                width={125}
              />
            </Table>
          );
        }}
      </Form.Item>
    </>,
  ];
  return (
    <>
      <Create
        isLoading={queryResult?.isFetching}
        footerButtons={
          <>
            {current > 0 && (
              <Button
                onClick={() => {
                  console.log(current);
                  gotoStep(current - 1);
                }}
              >
                Quay lại
              </Button>
            )}
            {current < CreateOrderFormList.length - 1 && (
              <Button
                onClick={() => {
                  console.log(current);
                  if (current === 1) {
                    const total =
                      formProps.form
                        ?.getFieldValue("products")
                        ?.reduce(
                          (
                            accumulator: number,
                            currentValue: IOrderProductItem
                          ) => {
                            if (
                              currentValue !== undefined &&
                              currentValue.unitPrice !== undefined &&
                              currentValue.transportFee !== undefined &&
                              currentValue.qtyOrder !== undefined
                            ) {
                              return (
                                accumulator +
                                (currentValue.unitPrice +
                                  currentValue.transportFee) *
                                  currentValue.qtyOrder
                              );
                            } else {
                              return accumulator;
                            }
                          },
                          0
                        ) ?? 0;

                    const payment = formProps.form?.getFieldValue("payment");
                    formProps.form?.setFieldsValue({
                      payment: {
                        total: total,
                      },
                    });
                  }
                  gotoStep(current + 1);
                }}
              >
                Tiếp Theo
              </Button>
            )}
            {current === CreateOrderFormList.length - 1 && (
              <SaveButton {...saveButtonProps} />
            )}
          </>
        }
      >
        <Steps
          {...stepsProps}
          items={[
            {
              title: "Thông Tin Chung",
            },
            {
              title: "Sản Phẩm",
            },
            {
              title: "Trạng Thái Đơn",
            },
            {
              title: "Thanh Toán Đơn Hàng",
            },
          ]}
        />
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            console.log({ name, values });
            if (name === "paymentForm") {
              const paymentOperations =
                formProps.form?.getFieldValue("payment").paymentOperations ||
                [];
              const newPaymentOperations = [...paymentOperations, values];

              const customerPayments =
                newPaymentOperations.reduce(
                  (accumulator: number, currentValue: IPaymentOperation) => {
                    if (
                      currentValue !== undefined &&
                      currentValue.paymentType == "PaymentByClient"
                    ) {
                      return accumulator + currentValue.amount;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                ) ?? 0;
              const refundForCustomer =
                newPaymentOperations.reduce(
                  (accumulator: number, currentValue: IPaymentOperation) => {
                    if (
                      currentValue !== undefined &&
                      currentValue.paymentType == "RefundForClient"
                    ) {
                      return accumulator + currentValue.amount;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                ) ?? 0;
              formProps.form?.setFieldsValue({
                payment: {
                  paid: customerPayments,
                  refund: refundForCustomer,
                  paymentOperations: newPaymentOperations,
                },
              });
              setOpen(false);
            }
          }}
        >
          <Form
            {...formProps}
            name="formOrder"
            layout="vertical"
            initialValues={{
              orderDate: dayjs(new Date()),
              events: [],
            }}
            style={{ marginTop: 30 }}
            scrollToFirstError
          >
            {CreateOrderFormList[current]}
          </Form>
          <ModalForm open={open} onCancel={hideUserModal} />
        </Form.Provider>

        <ProductCreateDrawer
          drawerProps={createProductDrawerProps}
          formProps={createProductFormProps}
          saveButtonProps={createProductSaveButtonProps}
        />
        <ClientCreateDrawer
          drawerProps={createClientDrawerProps}
          formProps={createClientFormProps}
          saveButtonProps={createClientSaveButtonProps}
        />
        <StoreCreateDrawer
          drawerProps={createStoreDrawerProps}
          formProps={createStoreFormProps}
          saveButtonProps={createStoreSaveButtonProps}
        />
        {isFailedOnMutation === true &&
          errorMessages.map((x: any) => {
            var message = `${x.message} (${
              storeList.find((store) => store._id === x.store)?.name ?? x.store
            })!`;
            return (
              <Alert
                key={uuidv4()}
                message={message}
                type="warning"
                style={{ marginBottom: 20 }}
              />
            );
          })}
      </Create>
    </>
  );
};
