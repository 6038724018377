import { AuthProps } from "@refinedev/antd";
import { CustomeAuthPage } from "components/auth";

const authWrapperProps = {
    style: {
        // background:
        //     "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%),url('images/bg-cosmetic.jpg')",
        background: "url('images/bg-cosmetic.jpg')",
        backgroundSize: "cover",
    },
};

export const AuthPage: React.FC<AuthProps> = ({ type, formProps }) => {
    return (
        <CustomeAuthPage
            type={type}
            wrapperProps={authWrapperProps}
            //   renderContent={renderAuthContent}
            formProps={formProps}
        />
    );
};
