// import { IResourceComponentsProps } from "@refinedev/core";
// import { AntdEditInferencer } from "@refinedev/inferencer/antd";

// export const BrandEdit: React.FC<IResourceComponentsProps> = () => {
//     return <AntdEditInferencer />;
// };

import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import { Create, useForm } from "@refinedev/antd";

import { Form, Input, Radio } from "antd";
import { IClient } from "interfaces";

export const ClientCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm<IClient>();

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <Form
                {...formProps}
                layout="vertical"
                initialValues={{
                    isActive: true,
                }}
            >
                <Form.Item
                    label={t("clients.fields.name")}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("clients.fields.networkContact")}
                    name="networkContact"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("clients.fields.phone")}
                    name="phone"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("clients.fields.address")}
                    name="address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input.TextArea rows={8} />
                </Form.Item>
                <Form.Item label={t("brands.fields.isActive")} name="isActive">
                    <Radio.Group>
                        <Radio value={true}>{t("status.enable")}</Radio>
                        <Radio value={false}>{t("status.disable")}</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Create>
    );
};
