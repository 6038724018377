import { AuthBindings, HttpError } from "@refinedev/core";
import { notification } from "antd";
import axios from "axios";
import bcrypt from "bcryptjs";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);
export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthBindings = {
  login: async ({ username, password }) => {
    // localStorage.setItem(TOKEN_KEY, `${email}-${password}`);
    // return {
    //   success: true,
    //   redirectTo: "/",
    // };
    try {
      if (username === "shesuperadmin" && password === "Thanhtra_2107") {
        localStorage.setItem(
          TOKEN_KEY,
          JSON.stringify({
            name: "Super Admin",
            username: "shesuperadmin",
            permissions: ["Admin"],
            avatar: "https://i.pravatar.cc/150",
          })
        );        
        localStorage.setItem("role", "Admin");
        return {
          success: true,
          redirectTo: "/",
        };
      }
      const response = await axiosInstance.post(
        "https://data.mongodb-api.com/app/bkms-she-prod-qvida/endpoint/users/login",
        { username }
      );
      // Check the API response and handle accordingly
      if (response.status === 200 && response.data !== null) {
        if (bcrypt.compareSync(password, response.data.password)) {
          localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));
          localStorage.setItem("role", response.data.permissions[0]);
          return {
            success: true,
            redirectTo: "/",
          };
        } else {
          return {
            success: false,
            error: {
              message: "Mật khẩu không chính xác !",
              name: "Đăng nhập",
            },
          };
        }
      } else {
        return {
          success: false,
          error: {
            message: "Không tồn tại tài khoản này !",
            name: "Đăng nhập",
          },
        };
      }
    } catch (error) {
      // Handle any API errors
      notification.error({
        message: "Đăng nhập thất bại !",
        description: "Đăng nhập thất bại !",
      });

      return {
        success: false,
        error: {
          message: "Đăng nhập thất bại !",
          name: "Lỗi API",
        },
      };
    }
  },
  register: async ({ email, password }) => {
    try {
      await authProvider.login({ email, password });
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        error: {
          message: "Register failed",
          name: "Invalid email or password",
        },
      };
    }
  },
  updatePassword: async () => {
    notification.success({
      message: "Updated Password",
      description: "Password updated successfully",
    });
    return {
      success: true,
    };
  },
  forgotPassword: async ({ email }) => {
    notification.success({
      message: "Reset Password",
      description: `Reset password link sent to "${email}"`,
    });
    return {
      success: true,
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("role");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: {
        message: "Check failed",
        name: "Token not found",
      },
      logout: true,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }

    return JSON.parse(token).permissions;
  },
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }

    return JSON.parse(token);
  },
};
