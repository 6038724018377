import { AuthPageProps } from "@refinedev/core";
import { CardProps, FormProps, LayoutProps } from "antd";
import React from "react";

import { LoginPage } from "./components";

export type AuthProps = AuthPageProps<LayoutProps, CardProps, FormProps> & {
    renderContent?: (
        content: React.ReactNode,
        title: React.ReactNode
    ) => React.ReactNode;
    title?: React.ReactNode;
};

/**
 * **refine** has a default auth page form served on the `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/components/antd-auth-page/} for more details.
 */
export const CustomeAuthPage: React.FC<AuthProps> = (props) => {
    const renderView = () => {
        return <LoginPage {...props} />;
    };

    return <>{renderView()}</>;
};
