import { SearchOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  FilterDropdown,
  List,
  NumberField,
  TextField,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useCan,
  useList,
  useMany,
  useSelect,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Avatar,
  Button,
  DatePicker,
  Descriptions,
  Form,
  FormProps,
  Input,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { ImportStatus, PaymentStatus } from "components/status";
import dayjs from "dayjs";
import {
  IImpProd,
  IImportProduct,
  IImportProductFilterVariables,
  IPaymentOperation,
  IProduct,
  IStore,
  ISupplier,
} from "interfaces";
import React, { useMemo } from "react";

const { Text } = Typography;

export const ImportProductList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IImportProduct,
    HttpError,
    IImportProductFilterVariables
  >({
    pagination: {
      mode: "off",
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { store, supplier, createdAt } = params;
      filters.push({
        field: "store",
        operator: "in",
        value: store,
      });

      filters.push({
        field: "supplier",
        operator: "in",
        value: supplier,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );
      return filters;
    },
  });

  const t = useTranslate();
  const { mutate } = useUpdate();

  const imporProducts = tableQueryResult?.data?.data ?? [];

  const { data: productData } = useList<IProduct, HttpError>({
    resource: "products",
    pagination: {
      mode: "off",
    },
  });

  const productList = productData?.data ?? [];

  const { data: storeData, isLoading: storeIsLoading } = useMany<
    IStore,
    HttpError
  >({
    resource: "stores",
    // Creates the array of ids. This will filter and fetch the category data for the relevant posts.
    ids: imporProducts.map((item) => item?.store),
    queryOptions: {
      // Set to true only if the posts array is not empty.
      enabled: !!imporProducts.length,
    },
  });

  const { data: supplierData, isLoading: supplierIsLoading } = useMany<
    ISupplier,
    HttpError
  >({
    resource: "suppliers",
    // Creates the array of ids. This will filter and fetch the category data for the relevant posts.
    ids: imporProducts.map((item) => item?.supplier),
    queryOptions: {
      // Set to true only if the posts array is not empty.
      enabled: !!imporProducts.length,
    },
  });

  const { data: canAccessPrice } = useCan({
    resource: "importProducts",
    action: "field",
    params: { field: "payment" },
  });

  const { options: supplierSelectProps } = useSelect<ISupplier>({
    resource: "suppliers",
    optionLabel: "name",
    optionValue: "_id",
  });

  const { options: storeSelectPropos } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "_id",
  });
  // const { data: canAccess } = useCan({
  //   resource: "importProducts",
  //   action: "field",
  //   params: { field: ["payment", "products.importPrice"] },
  //   queryOptions: {
  //     staleTime: 5 * 60 * 1000, // 5 minutes
  //   },
  // });

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List
        // headerProps={{
        //   footer: (
        //     <Filter formProps={searchFormProps} filters={filters || []} />
        //   ),
        // }}
        >
          <Filter formProps={searchFormProps} filters={filters || []} />
          <Table {...tableProps} rowKey="_id">
            <Table.Column
              key="importDate"
              dataIndex="importDate"
              title={t("importProducts.fields.importDate")}
              render={(value) => (
                <DateField value={value} format="DD/MM/YYYY LTS" />
              )}
              sorter
              width={200}
            />
            <Table.Column
              align="center"
              dataIndex="store"
              title={t("importProducts.fields.store")}
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                >
                  <Select
                    options={storeSelectPropos}
                    style={{ minWidth: 250 }}
                    mode="multiple"
                  />
                </FilterDropdown>
              )}
              render={(value) => {
                if (storeIsLoading) {
                  return "...";
                } else {
                  return (
                    storeData?.data.find((x) => x._id === value)?.name || "-"
                  );
                }
              }}
              width={180}
            />
            <Table.Column
              align="center"
              dataIndex="supplier"
              title={t("importProducts.fields.supplier")}
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                >
                  <Select
                    options={supplierSelectProps}
                    style={{ minWidth: 250 }}
                    mode="multiple"
                  />
                </FilterDropdown>
              )}
              render={(value) => {
                if (supplierIsLoading) {
                  return "...";
                } else {
                  return (
                    supplierData?.data.find((x) => x._id === value)?.name || "-"
                  );
                }
              }}
              width={125}
            />
            <Table.Column
              align="center"
              key="importStatus"
              dataIndex="importStatus"
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                >
                  <Select
                    options={[
                      {
                        value: "OnTransport",
                        label: t("enum.importStatuses.OnTransport"),
                      },
                      {
                        value: "OnArrived",
                        label: t("enum.importStatuses.OnArrived"),
                      },
                    ]}
                    style={{ minWidth: 250 }}
                    mode="multiple"
                  />
                </FilterDropdown>
              )}
              title={t("importProducts.fields.importStatus")}
              render={(value) => {
                return <ImportStatus status={value} />;
              }}
              width={150}
            />
            {canAccessPrice?.can && (
              <>
                <Table.Column<IImportProduct>
                  align="center"
                  // key="products"
                  dataIndex={["payment", "status"]}
                  title="Thanh Toán"
                  filterDropdown={(props: FilterDropdownProps) => (
                    <FilterDropdown
                      {...props}
                      mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                    >
                      <Select
                        options={[
                          {
                            value: "Done",
                            label: t("enum.paymentStatuses.Done"),
                          },
                          {
                            value: "Debt",
                            label: t("enum.paymentStatuses.Debt"),
                          },
                          {
                            value: "Refund",
                            label: t("enum.paymentStatuses.Refund"),
                          },
                        ]}
                        style={{ minWidth: 250 }}
                        mode="multiple"
                      />
                    </FilterDropdown>
                  )}
                  render={(_, record) => (
                    <Popover
                      content={
                        <Space direction="vertical">
                          <TextField
                            value={`Tổng Tiền: ${new Intl.NumberFormat().format(
                              record.payment.total
                            )}đ`}
                          />
                          <TextField
                            value={`Đã Thanh Toán: ${new Intl.NumberFormat().format(
                              record.payment.paid
                            )}đ`}
                          />
                          <TextField
                            value={`Còn Nợ: ${new Intl.NumberFormat().format(
                              record.payment.debt
                            )}đ`}
                          />
                          <TextField strong value="Lịch Sử"></TextField>
                          <Table
                            dataSource={record.payment.paymentOperations ?? []}
                          >
                            <Table.Column
                              // key="orderDate"
                              dataIndex="paymentDate"
                              title="Ngày TT"
                              render={(value) => (
                                <DateField
                                  value={value}
                                  format="DD/MM/YYYY LT"
                                />
                              )}
                              width={120}
                            />
                            <Table.Column
                              align="center"
                              // key="orderStatus"
                              dataIndex="paymentType"
                              title="Loại"
                              render={(value) => {
                                return (
                                  <TextField
                                    value={t(`enum.paymentType.${value}`)}
                                  />
                                );
                              }}
                              width={120}
                            />
                            <Table.Column<IPaymentOperation>
                              align="center"
                              // key="orderStatus"
                              dataIndex="paymentMethod"
                              title="Phương Thức"
                              render={(value) => {
                                return (
                                  <TextField
                                    value={t(`enum.paymentMethod.${value}`)}
                                  />
                                );
                              }}
                              width={120}
                            />
                            <Table.Column<IPaymentOperation>
                              align="center"
                              dataIndex="amount"
                              title={t("importProducts.fields.payment.paid")}
                              render={(value) => {
                                return (
                                  <NumberField
                                    options={{
                                      currency: "VND",
                                      style: "currency",
                                      notation: "compact",
                                    }}
                                    value={value}
                                  />
                                );
                              }}
                              sorter
                              width={125}
                            />
                          </Table>
                          {/* <Descriptions
                            size="small"
                            style={{
                              width: "400px",
                            }}
                            column={2}
                          >
                            
                            <Descriptions.Item
                              label={t("importProducts.fields.payment.total")}
                              span={1}
                            >
                              <NumberField
                                options={{
                                  currency: "VND",
                                  style: "currency",
                                  notation: "compact",
                                }}
                                value={record.payment.total}
                              />
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={t("importProducts.fields.payment.paid")}
                              span={1}
                            >
                              <NumberField
                                options={{
                                  currency: "VND",
                                  style: "currency",
                                  notation: "compact",
                                }}
                                value={record.payment.paid}
                              />
                            </Descriptions.Item>

                            <Descriptions.Item
                              label={t("importProducts.fields.payment.debt")}
                              span={1}
                            >
                              <NumberField
                                options={{
                                  currency: "VND",
                                  style: "currency",
                                  notation: "compact",
                                }}
                                value={record.payment.debt}
                              />
                            </Descriptions.Item>
                          </Descriptions> */}
                        </Space>
                      }
                      title={t("orderProducts.fields.payment.label")}
                      trigger="hover"
                    >
                      <Space>
                        <PaymentStatus status={record.payment.status} />
                      </Space>
                    </Popover>
                  )}
                />
              </>
            )}

            <Table.Column<IImportProduct>
              align="center"
              key="products"
              dataIndex="products"
              title={t("importProducts.fields.products.label")}
              render={(_, record) => (
                <Popover
                  content={
                    <>
                      <Space direction="vertical">
                        {record.products.map((impProd: IImpProd) => {
                          var detailProduct = productList.find(
                            (x) => x._id === impProd.product
                          );

                          if (detailProduct !== undefined) {
                            return (
                              <Space key={impProd.product}>
                                <Avatar
                                  size={70}
                                  src={detailProduct?.cover?.[0].url}
                                ></Avatar>
                                <Descriptions
                                  size="small"
                                  style={{
                                    width: "400px",
                                  }}
                                  column={2}
                                >
                                  <Descriptions.Item
                                    label={t(
                                      "importProducts.fields.products.product"
                                    )}
                                    span={1}
                                  >
                                    <TextField
                                      strong
                                      value={detailProduct?.name}
                                    />
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Đã về" span={1}>
                                    <TextField
                                      value={`${impProd.qtyArrived}/${impProd.qtyImport}`}
                                    ></TextField>
                                  </Descriptions.Item>

                                  {canAccessPrice?.can && (
                                    <>
                                      <Descriptions.Item
                                        label={t(
                                          "importProducts.fields.products.importPrice"
                                        )}
                                        span={1}
                                      >
                                        <NumberField
                                          options={{
                                            currency: "VND",
                                            style: "currency",
                                            notation: "compact",
                                          }}
                                          value={impProd.importPrice}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item
                                        label={t(
                                          "importProducts.fields.products.transportFee"
                                        )}
                                        span={1}
                                      >
                                        <NumberField
                                          options={{
                                            currency: "VND",
                                            style: "currency",
                                            notation: "compact",
                                          }}
                                          value={impProd.transportFee}
                                        />
                                      </Descriptions.Item>
                                    </>
                                  )}
                                </Descriptions>
                              </Space>
                            );
                          }
                          return <></>;
                        })}
                      </Space>
                    </>
                  }
                  title={t("importProducts.fields.products.label")}
                  trigger="hover"
                >
                  {record.products.length}{" "}
                  {t("importProducts.fields.products.product")}
                </Popover>
              )}
            />
            <Table.Column<IImportProduct>
              fixed="right"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      resource="importProducts"
                      recordItemId={record._id}
                    />
                    <DeleteButton
                      hideText
                      size="small"
                      resource="importProducts"
                      recordItemId={record._id}
                      onSuccess={(data) => {
                        data.data.productsToUpdate.forEach(
                          (element: {
                            product: any;
                            priceImport: any;
                            priceSell: any;
                            productStatus: any;
                            qtyOnOrder: any;
                            qtyOnTransport: any;
                            qtyInStocks: any;
                            stocks: any;
                          }) => {
                            mutate({
                              resource: "products",
                              id: element.product,
                              values: {
                                priceImport: element.priceImport,
                                priceSell: element.priceSell,
                                productStatus: element.productStatus,
                                qtyOnOrder: element.qtyOnOrder,
                                qtyOnTransport: element.qtyOnTransport,
                                qtyInStocks: element.qtyInStocks,
                                stocks: element.stocks,
                              },
                            });
                          }
                        );
                      }}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Space>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();

  const { formProps, filters } = props;

  // const { options: brandSelectPropos } = useSelect<IBrand>({
  //   resource: "stores",
  //   optionLabel: "name",
  //   optionValue: "_id",
  // });
  // const { options: categorySelectProps } = useSelect<ICategory>({
  //   resource: "suppliers",
  //   optionLabel: "name",
  //   optionValue: "_id",
  // });

  const { RangePicker } = DatePicker;

  const createdAt = useMemo(() => {
    const start = getDefaultFilter("createdAt", filters, "gte");
    const end = getDefaultFilter("createdAt", filters, "lte");

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        // store: getDefaultFilter("store", filters, "in"),
        // supplier: getDefaultFilter("supplier", filters, "in"),
        createdAt,
      }}
    >
      <Form.Item>
        <Space>
          <Form.Item name="createdAt">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t("products.filter.submit")}
            </Button>
          </Form.Item>
        </Space>
      </Form.Item>
    </Form>
  );
};

const FilterSearch: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();

  const { formProps, filters } = props;

  return (
    <Form
      {...formProps}
      onValuesChange={() => {
        formProps.form?.submit();
      }}
      initialValues={{
        name: getDefaultFilter("name", filters, "contains"),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "8px",
          marginBottom: "16px",
        }}
      >
        <Text style={{ fontSize: "24px" }} strong>
          {t("importProducts.importProducts")}
        </Text>
        <Form.Item name="name" noStyle>
          <Input
            style={{
              width: "400px",
            }}
            placeholder={t("products.actions.searchFast")}
            suffix={<SearchOutlined />}
          />
        </Form.Item>
        <Space>
          <CreateButton resource="importProducts">
            {t("buttons.create")}
          </CreateButton>
        </Space>
      </div>
    </Form>
  );
};
