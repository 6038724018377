import { DeleteOutlined } from "@ant-design/icons";
import { DateField, List, TextField, useTable } from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useDeleteMany,
  useTranslate,
} from "@refinedev/core";
import { Button, Form, Space, Table, Typography } from "antd";
import { ILog } from "interfaces";
import stableStringify from "json-stable-stringify";
import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

const { Text } = Typography;

export const AuditLogList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, filters } = useTable<
    ILog,
    HttpError,
    { name: string }
  >({
    pagination: {
      mode: "client",
    },
    sorters: {
      permanent: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    onSearch: ({ name }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "resource",
        operator: "contains",
        value: name ? name : undefined,
      });

      return filters;
    },
  });

  const t = useTranslate();

  // multi select
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const { mutate } = useDeleteMany();

  const deleteAudits = () => {
      mutate({
        resource: "auditLogs",
        ids: selectedRowKeys,
    });
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List
          headerProps={{
            subTitle: hasSelected && (
              <Space style={{ gap: 0, marginLeft: "1em" }}>
                <Button
                  type="text"
                  onClick={() => deleteAudits()}
                  // loading={isLoading}
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                >
                  Xóa
                </Button>
              </Space>
            ),
          }}
        >
         

          <Table
            {...tableProps}
            rowKey="_id"
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => (
                <Space direction="vertical">
                  <ReactDiffViewer
                    leftTitle={t("auditLogs.fields.previousData")}
                    rightTitle={t("auditLogs.fields.data")}
                    compareMethod={DiffMethod.WORDS}
                    splitView={true}
                    oldValue={stableStringify(record.previousData, {
                      space: " ",
                    })}
                    newValue={stableStringify(record.data, {
                      space: " ",
                    })}
                  />
                  {/* <TextField
                                        value={t("auditLogs.fields.data")}
                                        strong
                                    ></TextField>
                                    <pre>
                                        {JSON.stringify(record.data, null, 2)}
                                    </pre>
                                    <TextField
                                        value={t(
                                            "auditLogs.fields.previousData"
                                        )}
                                        strong
                                    ></TextField>
                                    <pre>
                                        {JSON.stringify(
                                            record.previousData,
                                            null,
                                            2
                                        )}
                                    </pre> */}

                  <TextField
                    value={t("auditLogs.fields.author")}
                    strong
                  ></TextField>
                  <pre>{JSON.stringify(record.author, null, 2)}</pre>
                  <TextField
                    value={t("auditLogs.fields.meta")}
                    strong
                  ></TextField>
                  <pre>{JSON.stringify(record.meta, null, 2)}</pre>
                </Space>
              ),
            }}
          >
            <Table.Column
              // key="orderDate"
              dataIndex="createdAt"
              title={t("auditLogs.fields.createdAt")}
              render={(value) => {
                if (value !== undefined) {
                  return <DateField value={value} format="DD/MM/YYYY LT" />;
                } else {
                  return <TextField value="Không Xác Định" />;
                }
              }}
              width={180}
            />
            <Table.Column
              key="resource"
              dataIndex="resource"
              title={t("auditLogs.fields.resource")}
              render={(value) => <TextField value={t(`${value}.${value}`)} />}
            />
            <Table.Column
              dataIndex="action"
              title={t("auditLogs.fields.action")}
              render={(value) => (
                <TextField value={t(`enum.action.${value}`)} />
              )}
            />
            <Table.Column
              dataIndex={["author", "name"]}
              title={t("auditLogs.fields.author")}
            />
          </Table>
        </List>
      </Space>
    </>
  );
};
