import { Column } from "@ant-design/charts";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";

import { TextField } from "@refinedev/antd";
import { ISalesChart } from "interfaces";
import {
  DailyOrderWrapper,
  RangePicker,
  TitleAreNumber,
  TitleArea,
  TitleAreaAmount,
} from "./styled";

export const DailyOrders: React.FC = () => {
  const t = useTranslate();
  const API_URL = useApiUrl();

  const url = `${API_URL}/dailyOrders`;
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(30, "days").startOf("day"),
    dayjs().startOf("day"),
  ]);
  const [_startDate, _endDate] = dateRange;

  const query = {
    _startDate: _startDate.toISOString(),
    _endDate: _endDate.toISOString(),
  };

  const { data, isLoading } = useCustom<{
    data: ISalesChart[];
    total: number;
    trend: number;
  }>({
    url,
    method: "get",
    config: {
      query,
    },
  });

  const { Text, Title } = Typography;

  const config = useMemo(() => {
    const config = {
      data: data?.data.data || [],
      loading: isLoading,
      xField: "orderDate",
      yField: "value",
      maxColumnWidth: 16,
      columnStyle: {
        radius: [4, 4, 0, 0],
      },
      color: "rgba(255, 255, 255, 0.5)",
      tooltip: {
        customContent: (title: any, data: { value: any }[]) => {
          return `<div style="padding: 8px 4px; font-size:16px; font-weight:600">${title} - ${new Intl.NumberFormat().format(
            data[0]?.value
          )} đơn</div>`;
        },
      },

      xAxis: {
        label: null,
        line: null,
        tickLine: null,
      },
      yAxis: {
        label: null,
        grid: null,
        tickLine: null,
      },
    };

    return config;
  }, [data]);
  const disabledDate = (date: Dayjs) => date > dayjs();

  return (
    <DailyOrderWrapper>
      <TitleArea>
        <TitleAreaAmount>
          <Typography.Title level={3}>Đơn Bán Hàng</Typography.Title>
          <TitleAreNumber>
            <TextField
              style={{ fontSize: 20 }}
              value={`${new Intl.NumberFormat().format(
                data?.data.total ?? 0
              )} đơn`}
            />
          </TitleAreNumber>
        </TitleAreaAmount>

        <RangePicker
          size="small"
          value={dateRange}
          onChange={(values) => {
            if (values && values[0] && values[1]) {
              setDateRange([values[0], values[1]]);
            }
          }}
          disabledDate={disabledDate}
          style={{
            float: "right",
            color: "#fffff !important",
            background: "rgba(255, 255, 255, 0.3)",
          }}
          ranges={{
            "Tuần Này": [dayjs().startOf("week"), dayjs().endOf("week")],
            "Tháng Trước": [
              dayjs().startOf("month").subtract(1, "month"),
              dayjs().endOf("month").subtract(1, "month"),
            ],
            "Tháng Này": [dayjs().startOf("month"), dayjs().endOf("month")],
            "Năm Nay": [dayjs().startOf("year"), dayjs().endOf("year")],
          }}
          format="YYYY/MM/DD"
        />
      </TitleArea>
      <Column
        style={{ padding: 0, height: 135 }}
        appendPadding={10}
        {...config}
      />
    </DailyOrderWrapper>
  );
};
