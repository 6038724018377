import { FormOutlined } from "@ant-design/icons";
import {
  useGetIdentity,
  useGetLocale,
  useList,
  useSetLocale,
  useTranslate,
} from "@refinedev/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Layout as AntdLayout,
  Avatar,
  Button,
  Col,
  Dropdown,
  Grid,
  Menu,
  MenuProps,
  Row,
  Space,
  Typography,
  theme,
} from "antd";
import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { TextField, useDrawerForm } from "@refinedev/antd";
import { UserInformationAndEdit } from "components/auth/components";
import { IconMoon, IconSun } from "components/icons";
import { IBrand, ICategory, IIdentity, IUser } from "interfaces";
import { ColorModeContext } from "../../contexts/color-mode";
import { HeaderTitle } from "./styled";

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

const { Header: AntdHeader } = AntdLayout;
const { useToken } = theme;
const { Text } = Typography;
const { useBreakpoint } = Grid;

export const Header: React.FC = () => {
  const { token } = useToken();
  const { mode, setMode } = useContext(ColorModeContext);
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<IIdentity>();
  const screens = useBreakpoint();
  const t = useTranslate();
  const currentLocale = locale();

  const renderTitle = (title: string, source: string) => (
    <HeaderTitle>
      <Text style={{ fontSize: "16px" }}>{title}</Text>
      <Link to={`/${source.toLowerCase()}`}>{t("search.more")}</Link>
    </HeaderTitle>
  );

  const renderItem = (title: string, imageUrl: string, link: string) => ({
    value: title,
    label: (
      <Link to={link} style={{ display: "flex", alignItems: "center" }}>
        <Avatar size={64} src={imageUrl} style={{ minWidth: "64px" }} />
        <Text style={{ marginLeft: "16px" }}>{title}</Text>
      </Link>
    ),
  });

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);

  const { refetch: refetchBrand } = useList<IBrand>({
    resource: "brands",
    config: {
      filters: [{ field: "name", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const brandOptionGroup = data.data.map((item) =>
          renderItem(
            `${item.name}`,
            item.cover[0].url,
            `/brands/show/${item._id}`
          )
        );
        if (brandOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(t("brands.brands"), "brands"),
              options: brandOptionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchCategory } = useList<ICategory>({
    resource: "categories",
    config: {
      filters: [{ field: "name", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const brandOptionGroup = data.data.map((item) =>
          renderItem(
            `${item.name}`,
            item.cover[0].url,
            `/categories/show/${item._id}`
          )
        );
        if (brandOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(t("categories.categories"), "categories"),
              options: brandOptionGroup,
            },
          ]);
        }
      },
    },
  });

  // Notif

  // const [subscriptionCount, setSubscriptionCount] = useState(0);

  // const [notifContent, setNotifContent] = useState<string[]>([]);
  // useSubscription({
  //     channel: "resources/brands",
  //     types: ["*"],
  //     onLiveEvent: (event) => {
  //         console.log(event);
  //         var message = `${event.payload.message} vào lúc ${event.date}`;
  //         setNotifContent((notifContent) => [...notifContent, message]);
  //         setSubscriptionCount((prev) => prev + 1);
  //     },
  // });

  // const [openNotifBox, setNotifBox] = useState(false);

  // const contentNotifBox = (
  //     <Card
  //         size="small"
  //         style={{ width: 400 }}
  //         // actions={[
  //         //   // <Button onClick={hideNotifBox} icon={<ReadOutlined />}>
  //         //   //   Đã đọc ?
  //         //   // </Button>,
  //         //   <p>*Các thông báo sẽ được xóa sau 24h</p>,
  //         // ]}
  //     >
  //         {notifContent.length > 0 ? (
  //             notifContent.map((x, index) => {
  //                 return <p key={index}>{x}</p>;
  //             })
  //         ) : (
  //             <p>Không có thông báo.</p>
  //         )}
  //     </Card>
  // );
  // const handleOpenChange = (newOpen: boolean) => {
  //     setNotifBox(newOpen);
  // };
  // const GetHistoric = async () => {
  //     var channelInstance = ablyClient.channels.get("resources/brands");
  //     const historic = await channelInstance.history();
  //     setNotifContent([]);
  //     setSubscriptionCount(0);
  //     if (historic.items.length > 0) {
  //         historic.items.forEach((event) => {
  //             var message = `${
  //                 event.data.payload.message
  //             } vào ${formatDistanceToNow(parseISO(event.data.date), {
  //                 locale: vi,
  //             })}`;
  //             setSubscriptionCount((prev) => prev + 1);
  //             setNotifContent((notifContent) => [...notifContent, message]);
  //         });
  //     }
  // };
  useEffect(() => {
    setOptions([]);
    refetchBrand();
    refetchCategory();
    // GetHistoric();
  }, [value, refetchBrand, refetchCategory]);

  const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label: lang === "en" ? "English" : "German",
    }));

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
    id: editId,
  } = useDrawerForm<IUser>({
    action: "edit",
    resource: "users",
    redirect: false,
  });

  return (
    <AntdHeader
      style={{
        backgroundColor: token.colorBgElevated,
        padding: "0 24px",
      }}
    >
      <Row
        align="middle"
        style={{
          justifyContent: "end",
        }}
      >
        {/* <Col xs={0} sm={12}>
                    <AutoComplete
                        style={{
                            width: "100%",
                            maxWidth: "550px",
                        }}
                        options={options}
                        filterOption={false}
                        onSearch={debounce(
                            (value: string) => setValue(value),
                            300
                        )}
                    >
                        <Input
                            size="large"
                            placeholder={"Tìm kiếm"}
                            suffix={<SearchOutlined />}
                        />
                    </AutoComplete>
                </Col> */}
        <Col>
          <Space size="middle" align="center">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="default"
              icon={mode === "light" ? <IconMoon /> : <IconSun />}
              onClick={() => {
                setMode(mode === "light" ? "dark" : "light");
              }}
            />
            {/* <Popover
                            open={openNotifBox}
                            content={contentNotifBox}
                            trigger="click"
                            title={
                                <Space>
                                    <Tooltip title="Các thông báo sẽ xóa sau 24h">
                                        <Button
                                            shape="circle"
                                            size="small"
                                            icon={<InfoCircleOutlined />}
                                        ></Button>
                                    </Tooltip>
                                    Thông báo
                                </Space>
                            }
                            onOpenChange={handleOpenChange}
                        >
                            <Badge count={subscriptionCount} showZero>
                                <NotificationOutlined style={{ padding: 10 }} />
                            </Badge>
                        </Popover> */}
            {/* <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} /> */}
            {/* <Dropdown
              menu={{
                items: menuItems,
                selectedKeys: currentLocale ? [currentLocale] : [],
              }}
            >
              <a
                style={{ color: "inherit" }}
                onClick={(e) => e.preventDefault()}
              >
                <Space>
                  <Avatar
                    size={16}
                    src={`/images/flags/${currentLocale}.svg`}
                  />
                  <div
                    style={{
                      display: screens.lg ? "block" : "none",
                    }}
                  >                    
                    <DownOutlined
                      style={{
                        fontSize: "12px",
                        marginLeft: "6px",
                      }}
                    />
                  </div>
                </Space>
              </a>
            </Dropdown> */}
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <Menu mode="vertical">
                        <Menu.Item
                          key="2"
                          style={{
                            fontWeight: 500,
                          }}
                          icon={
                            <FormOutlined
                              style={{
                                color: "green",
                              }}
                            />
                          }
                          onClick={() => editShow(user?._id)}
                        >
                          Cập Nhập
                        </Menu.Item>
                      </Menu>
                    ),
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Space>
                <Avatar size="large" src={user?.avatar} alt={user?.name} />
                <TextField strong value={user?.name} />
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Row>
      <UserInformationAndEdit
        drawerProps={editDrawerProps}
        formProps={editFormProps}
        saveButtonProps={editSaveButtonProps}
        editId={editId}
      />
    </AntdHeader>
  );
};
