import React, { useState } from "react";

import {
    BooleanField,
    DateField,
    ListButton,
    RefreshButton,
    Show,
    TextField,
} from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Alert, Image, Typography } from "antd";
import { IBrand } from "interfaces";

const { Title } = Typography;

export const BrandShow: React.FC<IResourceComponentsProps> = () => {
    const [deprecated, setDeprecated] = useState<
        "deleted" | "updated" | undefined
    >();
    const { queryResult } = useShow<IBrand>({
        liveMode: "manual",
        onLiveEvent: (event) => {
            if (event.type === "deleted" || event.type === "updated") {
                setDeprecated(event.type);
            }
        },
    });
    const { data, isLoading } = queryResult;
    const t = useTranslate();

    const record = data?.data;
    const handleRefresh = () => {
        queryResult?.refetch();
        setDeprecated(undefined);
    };

    return (
        <Show isLoading={isLoading}>
            {deprecated === "deleted" && (
                <Alert
                    message="This post is deleted."
                    type="warning"
                    style={{
                        marginBottom: 20,
                    }}
                    action={<ListButton size="small" />}
                />
            )}

            {deprecated === "updated" && (
                <Alert
                    message="This post is updated. Refresh to see changes."
                    type="warning"
                    style={{
                        marginBottom: 20,
                    }}
                    action={
                        <RefreshButton size="small" onClick={handleRefresh} />
                    }
                />
            )}
            <Title level={5}>{t("brands.fields.cover")}</Title>
            <Image width={150} src={record?.cover[0].url} alt="No Image" />
            <Title level={5}>{t("brands.fields.name")}</Title>
            <TextField value={record?.name} />
            <Title level={5}>{t("brands.fields.isActive")}</Title>
            <BooleanField value={record?.isActive} />
            <Title level={5}>{t("brands.fields.createdAt")}</Title>
            <DateField value={record?.createdAt} />
        </Show>
    );
};
