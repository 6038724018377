// import { Typography } from "antd";
// import { useTranslation } from "react-i18next";

// const { Text } = Typography;

// export const DashboardPage: React.FC = () => {
//     const { t } = useTranslation();

//     return <>Dashboard</>;
// };

import { Card, Col, Row, Typography } from "antd";
import { DailyOrders, DailyRevenue, RecentOrders } from "components/dashboard";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    // <Space direction="vertical">
    //   <Card
    //     bodyStyle={{
    //       padding: 10,
    //       paddingBottom: 0,
    //     }}
    //     style={{
    //       background: "url(images/daily-revenue.png)",
    //       backgroundRepeat: "no-repeat",
    //       backgroundPosition: "right",
    //       backgroundSize: "cover",
    //     }}
    //   >
    //     <DailyRevenue />
    //   </Card>
    //   <Card
    //     bodyStyle={{
    //       padding: 10,
    //       paddingBottom: 0,
    //     }}
    //     style={{
    //       background: "url(images/daily-order.png)",
    //       backgroundRepeat: "no-repeat",
    //       backgroundPosition: "right",
    //       backgroundSize: "cover",
    //     }}
    //   >
    //     <DailyOrders />
    //   </Card>
    // </Space>
    <Row gutter={[16, 16]}>
      <Col md={24}>
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={{
                background: "url(images/daily-revenue.png)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                backgroundSize: "cover",
              }}
            >
              <DailyRevenue />
            </Card>
          </Col>
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={{
                background: "url(images/daily-order.png)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                backgroundSize: "cover",
              }}
            >
              <DailyOrders />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xl={24} lg={16} md={24} sm={24} xs={24}>
        <Card title={<Text strong>Đơn Mới</Text>}>
          <RecentOrders />
        </Card>
      </Col>
    </Row>
  );
};
