import { useLink } from "@refinedev/core";
import { Avatar, Space, Typography } from "antd";
const { Title: LabelTitle } = Typography;

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const Link = useLink();

  return (
    // <Logo>
    //     <Link to="/">
    //         {collapsed ? (
    //             <BikeWhiteIcon
    //                 style={{
    //                     fontSize: "32px",
    //                     color: token.colorTextHeading,
    //                 }}
    //             />
    //         ) : (
    //             <FineFoodsIcon style={{ color: token.colorTextHeading }} />
    //         )}
    //     </Link>
    // </Logo>
    <Space direction="vertical">
      <Link to="/">
        {collapsed ? (
          <Avatar size={32} src={`/images/she-icon.png`} />
        ) : (
          <Space>
            <Avatar size={50} src={`/images/she-icon.png`} />
            <LabelTitle level={4}>S H E</LabelTitle>
          </Space>
        )}
      </Link>
    </Space>
  );
};
