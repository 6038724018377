import { useTranslate } from "@refinedev/core";
import { useEffect, useState } from "react";

import { Button, Space } from "antd";

import { v4 as uuidv4 } from "uuid";

type OrderStatusItemProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
};

export const OrderStatusFilter: React.FC<OrderStatusItemProps> = ({
  onChange,
  value,
}) => {
  const t = useTranslate();

  const [filterOrderStatus, setFilterOrderStatus] = useState<string[]>(
    value ?? []
  );

  useEffect(() => {
    if (filterOrderStatus.length > 0) {
      onChange?.(filterOrderStatus);
    }
  }, [filterOrderStatus]);

  const orderStatusList = [
    { status: "Pending", text: "Đang Xử Lý" },
    { status: "Ready", text: "Sẵn Sàng Giao" },
    { status: "OnTheWay", text: "Đang Giao Hàng" },
    { status: "Delivered", text: "Đã Giao Hàng" },
    { status: "Done", text: "Hoàn Tất" },
    { status: "Cancelled", text: "Hủy Đơn" },
  ];

  const toggleFilterOrderStatus = (clickedOrderStatus: string) => {
    const target = filterOrderStatus.findIndex(
      (orderStatus) => orderStatus === clickedOrderStatus
    );
    if (target < 0) {
      setFilterOrderStatus((prevOrderStatus) => {
        return [...prevOrderStatus, clickedOrderStatus];
      });
    } else {
      const copyFilterOrderStatus = [...filterOrderStatus];

      copyFilterOrderStatus.splice(target, 1);

      setFilterOrderStatus(copyFilterOrderStatus);
    }
    if (
      filterOrderStatus.length === 1 &&
      filterOrderStatus[0] === clickedOrderStatus
    ) {
      onChange?.([]);
    } else {
      onChange?.(filterOrderStatus);
    }
  };

  return (
    <Space wrap>
      <Button
        shape="round"
        type={filterOrderStatus.length === 0 ? "primary" : "default"}
        onClick={() => {
          setFilterOrderStatus([]);
          onChange?.([]);
        }}
      >
        Tất Cả
      </Button>
      {orderStatusList.map((status, index) => (
        <Button
          key={uuidv4()}
          shape="round"
          type={
            filterOrderStatus.includes(status.status) ? "primary" : "default"
          }
          onClick={() => toggleFilterOrderStatus(status.status)}
        >
          {status.text}
        </Button>
      ))}
    </Space>
  );
};
