// import { IResourceComponentsProps } from "@refinedev/core";
// import { AntdEditInferencer } from "@refinedev/inferencer/antd";

// export const BrandEdit: React.FC<IResourceComponentsProps> = () => {
//     return <AntdEditInferencer />;
// };

import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import { Edit, useForm } from "@refinedev/antd";

import { Form, Input, Select } from "antd";
import { IUser } from "interfaces";

import bcrypt from "bcryptjs";
function hashPassword(password: string): string {
  var salt = bcrypt.genSaltSync(10);
  const hashedPassword = bcrypt.hashSync(password, salt);
  return hashedPassword;
}
export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm<IUser>();
  const handleOnFinish = async (values: any) => {
    var isChangedPassword =
      values.password !== formProps.initialValues?.password;
    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        password: isChangedPassword
          ? hashPassword(values.password)
          : values.password,
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/shemedia-fb24c.appspot.com/o/media%2Fcommon%2Fuser-avatar.jpg?alt=media&token=b7bdf75e-5ac0-4553-8904-2ed2ad3fea09",
      })
    );
  };
  return (
    <Edit isLoading={queryResult?.isFetching} saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
        }}
        onFinish={handleOnFinish}
      >
        <Form.Item
          label={t("users.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("users.fields.username")}
          name="username"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("users.fields.password")}
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("users.fields.description")} name="description">
          <Input />
        </Form.Item>
        <Form.Item
          label={t("users.fields.permissions")}
          name="permissions"
          rules={[
            {
              required: true,
              message: "Vui lòng chọn quyền truy cập (1) !",
            },
          ]}
        >
          <Select
            showSearch
            // onChange={handleChangeOrderStatus}
            style={{ width: "70%" }}
            placeholder="Chọn quyền truy cập..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            allowClear
            mode="multiple"
            options={[
              {
                value: "Admin",
                label: "Quản Trị Viên",
              },
              {
                value: "Employee",
                label: "Nhân Viên",
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
