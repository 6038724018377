import { useTranslate } from "@refinedev/core";

import { Create } from "@refinedev/antd";

import {
  ButtonProps,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Grid,
  Input,
  Radio,
} from "antd";

type StoreCreateProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const StoreCreateDrawer: React.FC<StoreCreateProps> = ({
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      zIndex={1001}
    >
      <Create
        resource="stores"
        saveButtonProps={saveButtonProps}
        goBack={false}
        contentProps={{
          style: {
            boxShadow: "none",
          },
          bodyStyle: {
            padding: 0,
          },
        }}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            isActive: true,
          }}
        >
          <Form.Item
            label={t("stores.fields.name")}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("stores.fields.address")}
            name="address"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea rows={8} />
          </Form.Item>
          <Form.Item label={t("stores.fields.description")} name="description">
            <Input.TextArea rows={8} />
          </Form.Item>
          <Form.Item label={t("brands.fields.isActive")} name="isActive">
            <Radio.Group>
              <Radio value={true}>{t("status.enable")}</Radio>
              <Radio value={false}>{t("status.disable")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
