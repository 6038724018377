import { SearchOutlined } from "@ant-design/icons";
import {
  CreateButton,
  DateField,
  DeleteButton,
  EditButton,
  FilterDropdown,
  List,
  TextField,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useCan,
  useList,
  useMany,
  useSelect,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Avatar,
  Button,
  DatePicker,
  Descriptions,
  Form,
  FormProps,
  Input,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import dayjs from "dayjs";
import {
  IDamagedProductItem,
  IExportDamagedProduct,
  IExportDamagedProductFilterVariables,
  IProduct,
  IStore,
} from "interfaces";
import React, { useMemo } from "react";

const { Text } = Typography;

export const ExportDamagedProductList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IExportDamagedProduct,
    HttpError,
    IExportDamagedProductFilterVariables
  >({
    pagination: {
      mode: "off",
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { store, createdAt } = params;
      filters.push({
        field: "store",
        operator: "in",
        value: store,
      });

      filters.push(
        {
          field: "createdAt",
          operator: "gte",
          value: createdAt
            ? createdAt[0].startOf("day").toISOString()
            : undefined,
        },
        {
          field: "createdAt",
          operator: "lte",
          value: createdAt
            ? createdAt[1].endOf("day").toISOString()
            : undefined,
        }
      );
      return filters;
    },
  });

  const t = useTranslate();
  const { mutate } = useUpdate();

  const damagedProducts = tableQueryResult?.data?.data ?? [];

  const { data: productData } = useList<IProduct, HttpError>({
    resource: "products",
    liveMode: "auto",
    pagination: {
      mode: "off",
    },
  });

  const productList = productData?.data ?? [];

  const { data: storeData, isLoading: storeIsLoading } = useMany<
    IStore,
    HttpError
  >({
    resource: "stores",
    // Creates the array of ids. This will filter and fetch the category data for the relevant posts.
    ids: damagedProducts.map((item) => item?.store),
    queryOptions: {
      // Set to true only if the posts array is not empty.
      enabled: !!damagedProducts.length,
    },
  });

  const { data: canAccessPrice } = useCan({
    resource: "exportDamagedProducts",
    action: "field",
    params: { field: "totalCost" },
  });
  const { options: storeSelectPropos } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "_id",
  });
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List>
          <Filter formProps={searchFormProps} filters={filters || []} />

          <Table {...tableProps} rowKey="_id">
            <Table.Column
              key="exportDate"
              dataIndex="exportDate"
              title={t("exportDamagedProducts.fields.exportDate")}
              render={(value) => (
                <DateField value={value} format="DD/MM/YYYY LTS" />
              )}
            />
            <Table.Column
              align="center"
              dataIndex="store"
              title={t("exportDamagedProducts.fields.store")}
              filterDropdown={(props: FilterDropdownProps) => (
                <FilterDropdown
                  {...props}
                  mapValue={(selectedKeys) => selectedKeys.map((i) => i)}
                >
                  <Select
                    options={storeSelectPropos}
                    style={{ minWidth: 250 }}
                    mode="multiple"
                  />
                </FilterDropdown>
              )}
              render={(value) => {
                if (storeIsLoading) {
                  return "...";
                } else {
                  return (
                    storeData?.data.find((x) => x._id === value)?.name || "-"
                  );
                }
              }}
            />
            {canAccessPrice?.can && (
              <Table.Column
                align="center"
                dataIndex="totalCost"
                title={t("exportDamagedProducts.fields.totalCost")}
                render={(value) => {
                  return (
                    // <NumberField
                    //   options={{
                    //     currency: "VND",
                    //     style: "currency",
                    //     notation: "compact",
                    //   }}
                    //   value={value}
                    // />
                    new Intl.NumberFormat().format(value)
                  );
                }}
                sorter
              />
            )}

            <Table.Column<IExportDamagedProduct>
              align="center"
              key="products"
              dataIndex="products"
              title={t("exportDamagedProducts.fields.products.label")}
              render={(_, record) => (
                <Popover
                  content={
                    <>
                      <Space direction="vertical">
                        {record.products.map(
                          (damagedProduct: IDamagedProductItem) => {
                            var detailProduct = productList.find(
                              (x) => x._id === damagedProduct.product
                            );

                            if (detailProduct !== undefined) {
                              return (
                                <Space key={damagedProduct.product}>
                                  <Avatar
                                    size={70}
                                    src={detailProduct?.cover?.[0].url}
                                  ></Avatar>
                                  <Descriptions
                                    size="small"
                                    style={{
                                      width: "400px",
                                    }}
                                    column={2}
                                  >
                                    <Descriptions.Item
                                      label={t(
                                        "exportDamagedProducts.fields.products.product"
                                      )}
                                      span={1}
                                    >
                                      <TextField
                                        strong
                                        value={detailProduct?.name}
                                      />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="SL Hỏng" span={1}>
                                      <TextField
                                        value={damagedProduct.qtyDamaged}
                                      ></TextField>
                                    </Descriptions.Item>
                                    {canAccessPrice?.can && (
                                      <Descriptions.Item
                                        label={t(
                                          "exportDamagedProducts.fields.products.unitCost"
                                        )}
                                        span={1}
                                      >
                                        {new Intl.NumberFormat().format(
                                          damagedProduct.unitCost
                                        )}
                                        {/* <NumberField
                                          options={{
                                            currency: "VND",
                                            style: "currency",
                                            notation: "compact",
                                          }}
                                          value={damagedProduct.unitCost}
                                        /> */}
                                      </Descriptions.Item>
                                    )}
                                  </Descriptions>
                                </Space>
                              );
                            }
                            return <></>;
                          }
                        )}
                      </Space>
                    </>
                  }
                  title={t("exportDamagedProducts.fields.products.label")}
                  trigger="hover"
                >
                  {record.products.length}{" "}
                  {t("exportDamagedProducts.fields.products.product")}
                </Popover>
              )}
            />
            <Table.Column<IExportDamagedProduct>
              fixed="right"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      resource="exportDamagedProducts"
                      recordItemId={record._id}
                    />
                    <DeleteButton
                      hideText
                      size="small"
                      resource="exportDamagedProducts"
                      recordItemId={record._id}
                      onSuccess={(data) => {
                        data.data.productsToUpdate.forEach(
                          (element: {
                            product: any;
                            priceImport: any;
                            priceSell: any;
                            productStatus: any;
                            qtyOnOrder: any;
                            qtyOnTransport: any;
                            qtyInStocks: any;
                            stocks: any;
                          }) => {
                            mutate({
                              resource: "products",
                              id: element.product,
                              values: {
                                priceImport: element.priceImport,
                                priceSell: element.priceSell,
                                productStatus: element.productStatus,
                                qtyOnOrder: element.qtyOnOrder,
                                qtyOnTransport: element.qtyOnTransport,
                                qtyInStocks: element.qtyInStocks,
                                stocks: element.stocks,
                              },
                            });
                          }
                        );
                      }}
                    />
                  </Space>
                );
              }}
            />
            {/* <Table.Column<IProduct>
              dataIndex="products_actions"
              render={(_, record) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: (
                      <EditButton
                        size="small"
                        resource="exportDamagedProducts"
                        recordItemId={record._id}
                      />
                    ),
                  },
                  // {
                  //   key: "2",
                  //   label: (
                  //     <ShowButton size="small" recordItemId={record._id} />
                  //   ),
                  // },
                ];
                // <Dropdown
                //     overlay={moreMenu(record)}
                //     trigger={["click"]}
                // >
                //     <MoreOutlined
                //         onClick={(e) => e.stopPropagation()}
                //         style={{
                //             fontSize: 24,
                //         }}
                //     />
                // </Dropdown>
                return (
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <MoreOutlined
                      style={{
                        fontSize: 24,
                      }}
                    />
                  </Dropdown>
                );
              }}
            /> */}
          </Table>
        </List>
      </Space>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();

  const { formProps, filters } = props;

  const { options: storeSelectPropos } = useSelect<IStore>({
    resource: "stores",
    optionLabel: "name",
    optionValue: "_id",
  });

  const { RangePicker } = DatePicker;

  const createdAt = useMemo(() => {
    const start = getDefaultFilter("createdAt", filters, "gte");
    const end = getDefaultFilter("createdAt", filters, "lte");

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);

  return (
    <Form
      layout="vertical"
      {...formProps}
      initialValues={{
        store: getDefaultFilter("store", filters, "in"),
        supplier: getDefaultFilter("supplier", filters, "in"),
        createdAt,
      }}
    >
      <Space>
        <Form.Item name="createdAt">
          <RangePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            {t("products.filter.submit")}
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

const FilterSearch: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  const t = useTranslate();

  const { formProps, filters } = props;

  return (
    <Form
      {...formProps}
      onValuesChange={() => {
        formProps.form?.submit();
      }}
      initialValues={{
        name: getDefaultFilter("name", filters, "contains"),
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "8px",
          marginBottom: "16px",
        }}
      >
        <Text style={{ fontSize: "24px" }} strong>
          {t("exportDamagedProducts.exportDamagedProducts")}
        </Text>
        <Form.Item name="name" noStyle>
          <Input
            style={{
              width: "400px",
            }}
            placeholder={t("products.actions.searchFast")}
            suffix={<SearchOutlined />}
          />
        </Form.Item>
        <Space>
          <CreateButton resource="exportDamagedProducts">
            {t("buttons.create")}
          </CreateButton>
        </Space>
      </div>
    </Form>
  );
};
