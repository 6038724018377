import dayjs from "dayjs";

import {
  HttpError,
  IResourceComponentsProps,
  useCan,
  useList,
  useSelect,
  useTranslate,
  useUpdate,
} from "@refinedev/core";

import { Create, CreateButton, useDrawerForm, useForm } from "@refinedev/antd";

import {
  Alert,
  Avatar,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ProductCreateDrawer } from "components/product";
import { StoreCreateDrawer } from "components/store";
import { SupplierCreateDrawer } from "components/supplier/create";
import {
  IImpProd,
  IImportProduct,
  IProduct,
  IStore,
  ISupplier,
} from "interfaces";
import React, { useState } from "react";
const { Option } = Select;

export const ImportProductCreateV2: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { data: canAccessPrice } = useCan({
    resource: "importProducts",
    action: "field",
    params: { field: "payment" },
  });

  const [isFailedOnMutation, setIsFailedOnMutation] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const { mutate } = useUpdate();
  const { formProps, saveButtonProps, queryResult } = useForm<IImportProduct>({
    onMutationSuccess: (data, variables, context) => {
      const importProduct = data.data;
      importProduct.productsToUpdate.forEach((element) => {
        mutate({
          resource: "products",
          id: element.product,
          values: {
            priceImport: element.priceImport,
            priceSell: element.priceSell,
            productStatus: element.productStatus,
            qtyOnOrder: element.qtyOnOrder,
            qtyOnTransport: element.qtyOnTransport,
            qtyInStocks: element.qtyInStocks,
            stocks: element.stocks,
          },
        });
      });
    },
    onMutationError: (data, variables, context) => {
      const error = data.response?.data?.error;
      const errorJson = JSON.parse(error);
      const errorDetail = JSON.parse(errorJson.message);
      setIsFailedOnMutation(true);
      setErrorMessages(errorDetail.errors);
    },
  });

  const { data, isLoading } = useList<IProduct, HttpError>({
    resource: "products",
    liveMode: "auto",
  });

  const productList = data?.data ?? [];

  const { data: storeData, isLoading: storeDataIsLoading } = useList<
    IStore,
    HttpError
  >({
    resource: "stores",
  });
  const storeList = storeData?.data ?? [];

  const { options: supplierSelectProps } = useSelect<ISupplier>({
    resource: "suppliers",
    optionLabel: "name",
    optionValue: "_id",
  });

  const handleOnFinish = async (values: any) => {
    console.log(values);
    var impStatus = "OnArrived";
    const checkQty = values.products.find(
      (x: IImpProd) => x.qtyImport !== x.qtyArrived
    );

    if (checkQty !== undefined) {
      impStatus = "OnTransport";
    }
    var impPayment = values.payment;
    if (impPayment.debt < 0) {
      impPayment.status = "Refund";
    } else if (impPayment.debt > 0) {
      impPayment.status = "Debt";
    } else {
      impPayment.status = "Done";
    }
    console.log({
      ...values,
      importStatus: impStatus,
      payment: impPayment,
    });
    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        importStatus: impStatus,
        payment: impPayment,
      })
    );
  };

  const handleChange = (x: any) => {
    const total = formProps.form
      ?.getFieldValue("products")
      ?.reduce((accumulator: number, currentValue: IImpProd) => {
        if (
          currentValue !== undefined &&
          currentValue.importPrice !== undefined &&
          currentValue.transportFee !== undefined &&
          currentValue.qtyImport !== undefined
        ) {
          return (
            accumulator +
            (currentValue.importPrice + currentValue.transportFee) *
              currentValue.qtyImport
          );
        } else {
          return accumulator;
        }
      }, 0);
    const payment = formProps.form?.getFieldValue("payment");
    formProps.form?.setFieldsValue({
      payment: {
        total: total,
        debt: total - payment.paid,
      },
    });
  };

  const {
    drawerProps: createProductDrawerProps,
    formProps: createProductFormProps,
    saveButtonProps: createProductSaveButtonProps,
    show: createProductShow,
  } = useDrawerForm<IProduct>({
    action: "create",
    resource: "products",
    redirect: false,
  });
  const {
    drawerProps: createSupplierDrawerProps,
    formProps: createSupplierFormProps,
    saveButtonProps: createSupplierSaveButtonProps,
    show: createSupplierShow,
  } = useDrawerForm<ISupplier>({
    action: "create",
    resource: "suppliers",
    redirect: false,
  });

  const {
    drawerProps: createStoreDrawerProps,
    formProps: createStoreFormProps,
    saveButtonProps: createStoreSaveButtonProps,
    show: createStoreShow,
  } = useDrawerForm<IStore>({
    action: "create",
    resource: "stores",
    redirect: false,
  });
  return (
    <>
      <Create
        isLoading={queryResult?.isFetching}
        saveButtonProps={saveButtonProps}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            importDate: dayjs(new Date()),
            payment: {
              total: 0,
              paid: 0,
              debt: 0,
            },
          }}
          onFinish={handleOnFinish}
          onValuesChange={handleChange}
        >
          <Form.Item
            label={t("importProducts.fields.importDate")}
            name="importDate"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker showTime placeholder="Chọn Ngày ..." />
          </Form.Item>
          <Form.Item
            label={t("importProducts.fields.store")}
            // extra="We must make sure that your are a human."
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="store"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Chọn Kho Hàng ..."
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {!storeDataIsLoading &&
                      storeList.map((store) => {
                        return (
                          <Option
                            key={store._id}
                            value={store._id}
                            label={store.name}
                          >
                            <Space key={uuidv4()}>
                              <Descriptions size="small">
                                <Descriptions.Item
                                  label={t("stores.fields.name")}
                                  span={3}
                                >
                                  {store?.name}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={t("stores.fields.address")}
                                  span={3}
                                >
                                  {store?.address}
                                </Descriptions.Item>
                              </Descriptions>
                            </Space>
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <CreateButton onClick={() => createStoreShow()}>
                  Thêm Kho Hàng Mới
                </CreateButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={t("importProducts.fields.supplier")}
            // extra="We must make sure that your are a human."
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="supplier"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={supplierSelectProps}
                    allowClear
                    placeholder={t("suppliers.suppliers")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CreateButton onClick={() => createSupplierShow()}>
                  Thêm Nhà Cung Cấp Mới
                </CreateButton>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={t("importProducts.fields.description")}
            name="description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Divider orientation="left">Danh Sách Hàng Nhập</Divider>
          <Form.List name="products">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => {
                    // console.log(formProps.form?.getFieldsValue());
                    return (
                      <Space key={field.key} align="baseline">
                        <Form.Item style={{ width: "100%" }} shouldUpdate>
                          {() => (
                            <Form.Item
                              {...field}
                              label={t(
                                "importProducts.fields.products.product"
                              )}
                              name={[field.name, "product"]}
                              // rules={[
                              //   { required: true, message: "Missing sight" },
                              // ]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please confirm your password!",
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      getFieldValue("products").filter(
                                        (x: { product: any }) =>
                                          x.product === value
                                      ).length > 1
                                    ) {
                                      return Promise.reject(
                                        new Error("Đã tồn tại sản phẩm !")
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Select
                                showSearch
                                style={{
                                  width: "300px",
                                }}
                                allowClear
                                placeholder="Chọn Sản Phẩm Cần Nhập ..."
                                optionLabelProp="label"
                                filterOption={(input, option) =>
                                  (option?.label?.toString() ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {!isLoading &&
                                  productList.map((product) => {
                                    return (
                                      <Option
                                        key={product._id}
                                        value={product._id}
                                        label={product.name}
                                      >
                                        <Space key={uuidv4()}>
                                          <Avatar
                                            size={100}
                                            src={product?.cover?.[0].url}
                                          ></Avatar>
                                          <Descriptions size="small">
                                            <Descriptions.Item
                                              label={t("products.fields.name")}
                                              span={3}
                                            >
                                              {product?.name}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                              label={t(
                                                "products.fields.priceSell"
                                              )}
                                              span={3}
                                            >
                                              {product?.priceSell}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                              label={t(
                                                "products.fields.qtyOnOrder"
                                              )}
                                              span={3}
                                            >
                                              {product?.qtyOnOrder}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                              label={t(
                                                "products.fields.qtyOnTransport"
                                              )}
                                              span={3}
                                            >
                                              {product?.qtyOnTransport}
                                            </Descriptions.Item>
                                          </Descriptions>
                                        </Space>
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          )}
                        </Form.Item>
                        <Form.Item
                          key={uuidv4()}
                          style={{ width: "100px" }}
                          label={t("importProducts.fields.products.qtyImport")}
                          name={[field.name, "qtyImport"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing price",
                            },
                            () => ({
                              validator(_, value) {
                                if (value < 0) {
                                  return Promise.reject(
                                    new Error("Phải lớn hơn 0 !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                          initialValue={0}
                        >
                          <InputNumber
                            style={{
                              width: "100px",
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          key={uuidv4()}
                          style={{ width: "100px" }}
                          label={t("importProducts.fields.products.qtyArrived")}
                          name={[field.name, "qtyArrived"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing price",
                            },
                            () => ({
                              validator(_, value) {
                                if (value < 0) {
                                  return Promise.reject(
                                    new Error("Phải lớn hơn 0 !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            style={{
                              width: "100px",
                            }}
                            min={0}
                            defaultValue={0}
                          />
                        </Form.Item>
                        <Form.Item
                          key={uuidv4()}
                          style={{ width: "150px" }}
                          label={
                            canAccessPrice?.can &&
                            t("importProducts.fields.products.importPrice")
                          }
                          name={[field.name, "importPrice"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing price",
                            },
                            () => ({
                              validator(_, value) {
                                if (value < 0) {
                                  return Promise.reject(
                                    new Error("Phải lớn hơn 0 !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                          initialValue={0}
                        >
                          {canAccessPrice?.can && (
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              style={{
                                width: "150px",
                              }}
                              min={0}
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          key={uuidv4()}
                          style={{ width: "150px" }}
                          label={
                            canAccessPrice?.can &&
                            t("importProducts.fields.products.transportFee")
                          }
                          name={[field.name, "transportFee"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing price",
                            },
                            () => ({
                              validator(_, value) {
                                if (value < 0) {
                                  return Promise.reject(
                                    new Error("Phải lớn hơn 0 !")
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                          initialValue={0}
                        >
                          {canAccessPrice?.can && (
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              style={{
                                width: "150px",
                              }}
                            />
                          )}
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    );
                  })}
                  <Form.Item style={{ width: "300px" }}>
                    <Space direction="horizontal">
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Thêm SP vào giỏ
                      </Button>
                      <CreateButton onClick={() => createProductShow()}>
                        Sản Phẩm Mới ?
                      </CreateButton>
                    </Space>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
          {canAccessPrice?.can && (
            <Divider orientation="left">
              {t("importProducts.fields.payment.label")}
            </Divider>
          )}
          <Form.Item
            label={
              canAccessPrice?.can && t("importProducts.fields.payment.total")
            }
            name={["payment", "total"]}
            initialValue={0}
          >
            {canAccessPrice?.can && (
              <InputNumber
                readOnly
                style={{ width: "300px" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                addonAfter="VNĐ"
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              canAccessPrice?.can && t("importProducts.fields.payment.paid")
            }
            name={["payment", "paid"]}
            rules={[
              { required: true, message: "Missing price" },
              () => ({
                validator(_, value) {
                  if (value < 0) {
                    return Promise.reject(new Error("Phải lớn hơn 0 !"));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            initialValue={0}
          >
            {canAccessPrice?.can && (
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                min={0}
                addonAfter="VNĐ"
                style={{ width: "300px" }}
              />
            )}
          </Form.Item>
          <Form.Item
            label={
              canAccessPrice?.can && t("importProducts.fields.payment.debt")
            }
            name={["payment", "debt"]}
          >
            {canAccessPrice?.can && (
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                addonAfter="VNĐ"
                readOnly
                style={{ width: "300px" }}
              />
            )}
          </Form.Item>
        </Form>
        <ProductCreateDrawer
          drawerProps={createProductDrawerProps}
          formProps={createProductFormProps}
          saveButtonProps={createProductSaveButtonProps}
        />
        <SupplierCreateDrawer
          drawerProps={createSupplierDrawerProps}
          formProps={createSupplierFormProps}
          saveButtonProps={createSupplierSaveButtonProps}
        />
        <StoreCreateDrawer
          drawerProps={createStoreDrawerProps}
          formProps={createStoreFormProps}
          saveButtonProps={createStoreSaveButtonProps}
        />
        {isFailedOnMutation === true &&
          errorMessages.map((x: any) => {
            var message = `${x.message} (${
              storeList.find((store) => store._id === x.store)?.name ?? x.store
            })!`;
            return (
              <Alert
                key={uuidv4()}
                message={message}
                type="warning"
                style={{ marginBottom: 20 }}
              />
            );
          })}
      </Create>
    </>
  );
};
