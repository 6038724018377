import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import {
  BooleanField,
  CreateButton,
  EditButton,
  useTable,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useTranslate,
} from "@refinedev/core";
import {
  Dropdown,
  Form,
  Input,
  List,
  MenuProps,
  Space,
  Table,
  Typography,
} from "antd";
import { IProduct, ISupplier } from "interfaces";
import React from "react";

const { Text } = Typography;

export const SupplierList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, filters } = useTable<
    ISupplier,
    HttpError,
    { name: string }
  >({
    pagination: {
      mode: "off",
    },
    onSearch: ({ name }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "name",
        operator: "contains",
        value: name ? name : undefined,
      });

      return filters;
    },
  });

  const t = useTranslate();

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <List>
          <Form
            {...searchFormProps}
            onValuesChange={() => {
              searchFormProps.form?.submit();
            }}
            initialValues={{
              name: getDefaultFilter("name", filters, "contains"),
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "8px",
                marginBottom: "16px",
              }}
            >
              <Text style={{ fontSize: "24px" }} strong>
                {t("suppliers.suppliers")}
              </Text>
              <Form.Item name="name" noStyle>
                <Input
                  style={{
                    width: "400px",
                  }}
                  placeholder={t("suppliers.actions.searchFast")}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
              <Space>
                <CreateButton resource="suppliers">
                  {t("buttons.create")}
                </CreateButton>
              </Space>
            </div>
          </Form>

          <Table {...tableProps} rowKey="_id">
            <Table.Column
              key="name"
              dataIndex="name"
              title={t("suppliers.fields.name")}
            />
            <Table.Column
              key="phone"
              dataIndex="phone"
              title={t("suppliers.fields.phone")}
            />
            <Table.Column
              key="mail"
              dataIndex="mail"
              title={t("suppliers.fields.mail")}
            />
            <Table.Column
              dataIndex="address"
              title={t("suppliers.fields.address")}
            />
            <Table.Column
              dataIndex="description"
              title={t("suppliers.fields.description")}
            />
            <Table.Column
              key="isActive"
              dataIndex="isActive"
              title={t("suppliers.fields.isActive")}
              render={(value) => <BooleanField value={value} />}
            />
            <Table.Column
              key="createdAt"
              dataIndex="createdAt"
              title={t("suppliers.fields.createdAt")}
              sorter
            />
            <Table.Column<IProduct>
              dataIndex="products_actions"
              title={t("table.actions")}
              render={(_, record) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: (
                      <EditButton
                        size="small"
                        resource="suppliers"
                        recordItemId={record._id}
                      />
                    ),
                  },
                  // {
                  //   key: "2",
                  //   label: (
                  //     <ShowButton size="small" recordItemId={record._id} />
                  //   ),
                  // },
                ];

                return (
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <MoreOutlined
                      style={{
                        fontSize: 24,
                      }}
                    />
                  </Dropdown>
                );
              }}
            />
          </Table>
        </List>
      </Space>
    </>
  );
};
